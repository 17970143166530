<template>
  <body>
    <div id="cy" class="fade-in" role="img" aria-label="cytoscape-canvas"></div>
  </body>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ModelService from '../services/model.service'
import cytoscape from 'cytoscape'

import AsteriskImage from '@/assets/asterisk.png'
import nodeHtmlLabel from 'cytoscape-node-html-label'
nodeHtmlLabel(cytoscape)

export default {
  name: 'Cytoscape',
  components: {},
  props: {
    nodes: {
      type: Array,
      default: () => ([])
    },
    edges: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    cyBounds: null,
    cyPrevBounds: null,
    iconMandatory: AsteriskImage,
    confidenceOptionsClass: ['solidLine', 'dashedLine', 'dottedLine'],
    directionOptionsClass: ['noDirection', 'rightDirection', 'leftDirection', 'bothDirection']
  }),
  async mounted () {
    await this.loadGraph()
    this.setIconMandatory()
  },
  computed: {
    ...mapState('insight', {
      hideLinkLabel: 'hideLinkLabel'
    })
  },
  methods: {
    ...mapActions('insight', [
      'toggleHideLinkLabel'
    ]),
    async loadGraph () {
      this.cy = cytoscape({
        container: document.getElementById('cy'),
        wheelSensitivity: 0.1,
        desktopTapThreshold: 4,
        touchTapThreshold: 8,
        maxZoom: 3.0,
        minZoom: 0.3,
        layout: {
          name: 'preset',
          fit: true,
          spacingFactor: 1
        },
        style: [
          {
            selector: 'node',
            style: {
              'background-color': '#fff',
              'border-color': this.cyEntityBorderColor,
              'border-width': 2,
              color: '#666',
              label: this.cyEntityFormLabel,
              'text-valign': 'bottom',
              'text-halign': 'center',
              'text-margin-y': 5,
              'text-background-color': '#dff2f7',
              'text-background-shape': 'roundrectangle',
              'text-background-opacity': 1
            }
          },
          {
            selector: 'edge',
            style: {
              'curve-style': 'bezier',
              'control-point-step-size': 40,
              label: this.cyLinkFormLabel,
              'text-rotation': 'autorotate',
              'text-margin-y': 0,
              'text-margin-x': -10,
              'overlay-padding': '3px',
              'mid-source-arrow-fill': 'hollow',
              'mid-target-arrow-fill': 'hollow',
              'text-background-color': '#1d497c',
              'text-background-shape': 'round-rectangle',
              'text-background-opacity': 0.8,
              'text-background-padding': '5px',
              'text-border-opacity': 0.5,
              'text-border-color': '#313947',
              'text-border-style': 'solid',
              'text-border-width': '0px',
              color: '#ffffff',
              'line-color': '#2f2b2b'
            }
          },
          {
            selector: '.statusGreenSuccess',
            style: {
              'border-color': '#2ca444',
              'border-width': 4
            }
          },
          {
            selector: '.statusRedFail',
            style: {
              'border-color': '#dc3545',
              'border-width': 4
            }
          },
          {
            selector: '.statusGreenSuccessForTextBackgroundColor',
            style: {
              'text-background-color': '#2ca444',
              'line-color': '#2ca444'
            }
          },
          {
            selector: '.statusRedFailForTextBackgroundColor',
            style: {
              'text-background-color': '#dc3545',
              'line-color': '#dc3545'
            }
          },
          {
            selector: '.existingRecordLabel',
            style: {
              'font-weight': 'bold',
              label: this.cyExistingRecordLabel
            }
          },
          {
            selector: '.onSelectNode',
            style: {
              'border-width': 5,
              'border-color': '#1d497c'
            }
          },
          {
            selector: '.onSelectEdge',
            style: {
              width: 4,
              'text-border-width': '5px',
              'line-color': '#1d497c'
            }
          },
          {
            selector: '.solidLine',
            style: {
              'line-style': 'solid'
            }
          },
          {
            selector: '.dashedLine',
            style: {
              'line-style': 'dashed'
            }
          },
          {
            selector: '.dottedLine',
            style: {
              'line-style': 'dotted'
            }
          },
          {
            selector: '.noDirection',
            style: {
              'mid-source-arrow-shape': 'none',
              'mid-target-arrow-shape': 'none'
            }
          },
          {
            selector: '.leftDirection',
            style: {
              'mid-source-arrow-shape': 'triangle'
            }
          },
          {
            selector: '.rightDirection',
            style: {
              'mid-target-arrow-shape': 'triangle'
            }
          },
          {
            selector: '.bothDirection',
            style: {
              'mid-source-arrow-shape': 'triangle',
              'mid-target-arrow-shape': 'triangle'
            }
          }
        ],
        elements: {
          nodes: this.nodes,
          edges: this.edges
        }
      })
      const width = this.cy.width() / 2
      const height = this.cy.height() / 2
      this.cy.pan({ x: width, y: height })
      await this.iconsRendering(this.cy)
      this.initialiseTriggers()
      this.cy.center()
    },
    cyLinkFormLabel (edges) {
      return edges.data().title
    },
    cyEntityFormLabel (ele) {
      const elementData = ele.data()
      return elementData.title
    },
    cyExistingRecordLabel (ele) {
      return ele.data().recordLabel
    },
    cyEntityBorderColor (ele) {
      if (ele.data().isPrimary) {
        return '#F08521'
      }
      return '#313947'
    },
    async cyNodeSelected (event) {
      const nodeData = event.target.data()
      this.cy.nodes().removeClass('onSelectNode')
      this.cy.edges().removeClass('onSelectEdge')
      this.cy.$id(nodeData.id).removeClass('primaryNodeBorder')
      this.cy.$id(nodeData.id).addClass('onSelectNode')
      this.$emit('onClickNodeGetFormData', { id: nodeData.id, isEntity: true })
    },
    async cyEdgeSelected (event) {
      const edgeData = event.target.data()
      this.cy.edges().removeClass('onSelectEdge')
      this.cy.nodes().removeClass('onSelectNode')
      this.cy.$id(edgeData.id).addClass('onSelectEdge')
      this.$emit('onClickEdgeGetFormData', { id: edgeData.id, isEntity: false })
    },
    cySetPrevBounds (event) {
      this.cyPrevBounds = this.cy.elements().renderedBoundingBox()
    },
    cyCheckBounds (event) {
      const elementBounds = this.cy.elements().renderedBoundingBox()
      if (elementBounds.x2 < 0.1 * this.cyBounds.width ||
            elementBounds.x1 > 0.9 * this.cyBounds.width ||
            elementBounds.y2 < 0.1 * this.cyBounds.height ||
            elementBounds.y1 > 0.9 * this.cyBounds.height) {
        this.cy.animate(
          {
            panBy: {
              x: this.cyPrevBounds.x1 - elementBounds.x1,
              y: this.cyPrevBounds.y1 - elementBounds.y1
            }
          },
          {
            duration: 100
          }
        )
      }
    },
    initialiseTriggers () {
      // Bouncing Back
      this.cyBounds = {
        width: this.cy.width(),
        height: this.cy.height()
      }

      this.cy.on('tapstart', this.cySetPrevBounds)
      this.cy.on('tapend', this.cyCheckBounds)

      this.cy.on('tap', 'node', this.cyNodeSelected)
      this.cy.on('tap', 'edge', this.cyEdgeSelected)
    },
    async iconsRendering (cytoscape) {
      const cache = {}
      for (const node of this.nodes) {
        if (!cache[node.data.type]) {
          const response = await ModelService.getIconImage(node.data.model)
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            cytoscape.style().selector(`node[model = "${node.data.model}" ]`)
              .style({
                'background-image': url,
                'background-fit': 'cover',
                'background-opacity': 0
              }).update()
          }
          cache[node.data.model] = true
        }
      }
    },
    resetNodeBorder (nodeId) {
      for (const node of this.nodes) {
        const cytoscapeNodeId = node.data.id.toString()
        const givenNodeId = nodeId.toString()
        const isSame = cytoscapeNodeId === givenNodeId
        if (isSame) {
          this.cy.nodes().removeClass('onSelectNode')
          this.cy.edges().removeClass('onSelectEdge')
          this.cy.$id(node.data.id).removeClass('statusRedFail')
          this.cy.$id(node.data.id).removeClass('statusGreenSuccess')
        }
      }
    },
    nodeSubmissionStatusChangeBorderToGreenOrRed (nodeId, submitStatus) {
      for (const node of this.nodes) {
        const cytoscapeNodeId = node.data.id.toString()
        const givenNodeId = nodeId.toString()
        const isSame = cytoscapeNodeId === givenNodeId
        if (isSame) {
          if (submitStatus) {
            this.cy.nodes().removeClass('onSelectNode')
            this.cy.edges().removeClass('onSelectEdge')
            this.cy.$id(node.data.id).removeClass('statusRedFail')
            this.cy.$id(node.data.id).addClass('statusGreenSuccess')
          } else {
            this.cy.nodes().removeClass('onSelectNode')
            this.cy.edges().removeClass('onSelectEdge')
            this.cy.$id(node.data.id).removeClass('statusGreenSuccess')
            this.cy.$id(node.data.id).addClass('statusRedFail')
          }
        }
      }
    },
    edgeSubmissionStatusChangeBorderToGreenOrRed (edgeId, submitStatus) {
      for (const edge of this.edges) {
        const cytoscapeNodeId = edge.data.id.toString()
        const givenNodeId = edgeId.toString()
        const isSame = cytoscapeNodeId === givenNodeId
        if (isSame) {
          if (submitStatus) {
            this.cy.nodes().removeClass('onSelectNode')
            this.cy.edges().removeClass('onSelectEdge')
            this.cy.$id(edge.data.id).removeClass('statusRedFail')
            this.cy.$id(edge.data.id).removeClass('statusRedFailForTextBackgroundColor')
            this.cy.$id(edge.data.id).addClass('statusGreenSuccess')
            this.cy.$id(edge.data.id).addClass('statusGreenSuccessForTextBackgroundColor')
          } else {
            this.cy.nodes().removeClass('onSelectNode')
            this.cy.edges().removeClass('onSelectEdge')
            this.cy.$id(edge.data.id).addClass('statusRedFail')
            this.cy.$id(edge.data.id).addClass('statusRedFailForTextBackgroundColor')
          }
        }
      }
    },
    setEdgeConfidence (edgeId, confidenceValue) {
      const currentConfidenceClass = this.confidenceOptionsClass[confidenceValue]
      this.cy.$id(edgeId).removeClass(this.confidenceOptionsClass)
      this.cy.$id(edgeId).addClass(currentConfidenceClass)
    },
    setEdgeDirection (edgeId, directionValue) {
      const currentDirectionClass = this.directionOptionsClass[directionValue]
      this.cy.$id(edgeId).removeClass(this.directionOptionsClass)
      this.cy.$id(edgeId).addClass(currentDirectionClass)
    },
    useExistingRecordLabel (nodeId) {
      this.cy.$id(nodeId).addClass('existingRecordLabel')
      this.cy.$id(nodeId).addClass('statusGreenSuccess')
    },
    unuseExistingRecordLabel (nodeId) {
      this.cy.$id(nodeId).removeClass('existingRecordLabel')
      this.cy.$id(nodeId).removeClass('statusGreenSuccess')
    },
    iconMandatoryTemplate () {
      const iconWidth = this.$FORM('SETTINGS').COMPOSITE_FORM.CYTOSCAPE_ICON_MANDATORY.WIDTH
      const marginLeft = this.$FORM('SETTINGS').COMPOSITE_FORM.CYTOSCAPE_ICON_MANDATORY.MARGIN_LEFT
      const htmlTemplate = `
        <img style="
          width:${iconWidth};
          margin-left:${marginLeft};"
          src="${this.iconMandatory}"
        />`
      return htmlTemplate
    },
    setIconMandatory () {
      // DOCS for nodeHtmlLabel => https://github.com/kaluginserg/cytoscape-node-html-label
      this.cy.nodeHtmlLabel([{
        query: '.isMandatory',
        valign: 'top',
        tpl: this.iconMandatoryTemplate // we refer to the function, not to the returned value
      }])

      for (const node of this.nodes) {
        const isMandatory = node.data.isMandatory
        if (isMandatory) {
          this.cy.$id(node.data.id).addClass('isMandatory')
        }
      }
    }
  },
  watch: {
    // Fill this space up with implementations
  }
}
</script>

<style lang="scss" scoped>
  #cy {
    background: #dff2f7;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1; // this is the magic property, make it 1 to be always on back
    // fix the wrong mouse shadow position
    canvas {
      top: 0;
      left: 0;
    }
  }
</style>
