// import Vue from 'vue'
import { mapActions } from 'vuex'
import { find, isEmpty } from 'lodash'

export default {
  data: () => ({
    // Default Data
    dynamicFormList: false,
    dynamicFormData: false,
    formLoading: true,
    genericFields: false,
    // formModelType: 'entity',
    // mode: 'viewModel'
    isViewModel: false,
    isCreatePage: false
  }),
  methods: {
    ...mapActions('insight', [
      'getDynamicForms'
    ]),
    ...mapActions('forms', [
      'getForm'
    ]),
    async fetchDynamicFormList (model) {
      const formList = await this.getDynamicForms({ model: model })
      if (!isEmpty(formList)) {
        this.dynamicFormList = formList
      } else {
        return false
      }
    },
    async fetchDynamicForm (formId) {
      /*
        This method will handle retrieving the dynamic form in CRU records use-cases and the switching between various dynamic forms.
        If there exist a current dynamic form [this.dynamicFormData], it will execute [this.assignDynamicFormData()]
        to pass-over the value to the newly selected dynamic form.
       */
      this.formLoading = true
      this.dynamicFormLoading = true
      const newlySwitchedDynamicFormData = await this.getForm({ id: formId })

      // The follow If statement is catering for dynamic form to dynamic form switching in CRU records use-cases
      if (newlySwitchedDynamicFormData) {
        if (this.dynamicFormData) {
          // If switching from dynamic form to dynamic form, execute this method to pass-over the value to GenericFormFields first.
          await this.assignDynamicFormData()
        }

        // execute [this.assignDynamicFormData] again to pass-over the value from GenericFormFields to the newly selected dynamic form.
        await this.assignDynamicFormData(newlySwitchedDynamicFormData, true)
        this.dynamicFormData = newlySwitchedDynamicFormData
        this.formLoading = false
      }
    },
    async loadGenericForm () {
      const fieldListChoices = this.$FORM('REFS').FIELD_LIST_CHOICES
      await this.assignDynamicFormData()
      this.resetDynamicFormData()
      this.$refs[fieldListChoices].currentView = this.$FORM('TEXT').CREATE_EDIT_MODEL.GENERIC_VIEW_NAME
    },
    resetDynamicFormData () {
      this.dynamicFormData = false
      this.formLoading = false
    },
    assignDynamicFormData (formData = this.dynamicFormData, isAssignGenericFormDataToDynamicForm = false, isCreateEditCompositeForm = false) {
      /*
        This method is to assign a value to each field, to be used when switching between Generic Form and Dynamic Form in CRU use-cases.
        If [isAssignGenericFormDataToDynamicForm = true]: Assign the value of each field from Generic Form to Dynamic Form
        If [isAssignGenericFormDataToDynamicForm = false]: Assign the value of each field in Dynamic Form to Generic Form
        Note: Before using this method, please set [this.genericFields] using [setDynamicFormSettings()] first
       */

      if (this.genericFields) {
        formData.groups.forEach(group => {
          group.model_fields.forEach((modelField, index) => {
            const fieldName = modelField.model_field || modelField.name
            const dfModelField = find(formData.model_fields, ['name', fieldName])
            const genericModelField = find(this.genericFields, ['name', fieldName]) || {}
            if (this.isViewModel) {
              const genericAndDyanmicFormFieldExists = !isEmpty(genericModelField) && !isEmpty(dfModelField)
              const logic = modelField.logic
              genericModelField.logic = logic
              if (genericAndDyanmicFormFieldExists) {
                Object.assign(dfModelField, genericModelField)
              }
              group.model_fields.splice(index, 1, genericModelField) // need to do this because <FieldList /> Require genericModelField-like object structure
            } else {
              // if !isViewModel: it means [CREATE & UPDATE] of records scenarios
              if (isAssignGenericFormDataToDynamicForm) {
                // Assigning the value of each field from Generic Form to Dynamic Form
                if (this.isCreatePage) {
                  if (!dfModelField.value && [undefined, null, ''].indexOf(dfModelField.default_value) === -1) {
                    dfModelField.value = dfModelField.default_value
                  }
                }

                modelField.options = Object.assign(dfModelField, genericModelField)
                if (isCreateEditCompositeForm) {
                  this.assignDynamicFormDataForCompositeForm(modelField)
                }
              } else {
                // Assigning the value of each field in Dynamic Form to Generic Form
                genericModelField.value = modelField.options.value
              }
            }
          })
        })
        return true
      } else {
        return false
      }
    },
    setDynamicFormSettings (isViewModel, genericFields, isCreatePage = false) {
      this.isViewModel = isViewModel
      this.genericFields = genericFields
      this.isCreatePage = isCreatePage
    },
    assignDynamicFormDataForCompositeForm (modelField) {
      modelField.name = modelField.options.name
      modelField.label = modelField.options.label
      modelField.type = modelField.options.type
      modelField.value = modelField.options.value
      modelField.meta = modelField.options.meta
    },
    getDynamicFormDataWithoutSettings (formData, fields) {
      formData.groups.forEach(group => {
        group.model_fields.forEach((modelField, index) => {
          const fieldName = modelField.model_field || modelField.name
          const genericModelField = find(fields, ['name', fieldName])
          if (!isEmpty(genericModelField)) {
            genericModelField.value = modelField.options?.value
          }
        })
      })
      return fields
    },
    setDynamicFormDataForViewModeWithoutSettings (formData, fields) {
      formData.groups.forEach(group => {
        group.model_fields.forEach((modelField, index) => {
          const fieldName = modelField.model_field || modelField.name
          const genericModelField = find(fields, ['name', fieldName])
          const logic = modelField.logic
          genericModelField.logic = logic
          group.model_fields.splice(index, 1, genericModelField)
        })
      })
    },
    setDynamicFormDataFromExistingData (formData, existingData) {
      formData.groups.forEach(group => {
        group.model_fields.forEach((modelField, index) => {
          const fieldName = modelField.model_field || modelField.name
          const value = existingData[fieldName]
          modelField.value = value
        })
      })
    },
    setDynamicFormDataOptionsWithoutSettings (formData) {
      formData.groups.forEach(group => {
        group.model_fields.forEach((modelField, index) => {
          const fieldName = modelField.model_field || modelField.name
          const dfModelField = find(formData.model_fields, ['name', fieldName])
          modelField.options = dfModelField
        })
      })
    },
    async fetchDFList (model) {
      /*
        fetchDFList => Fetch Dynamic Form List but it will return a value, so it independent
        TODO: make fetching DF list to use this method instead in the future
      */
      const formList = await this.getDynamicForms({ model: model })
      if (!isEmpty(formList)) {
        return formList
      } else {
        return false
      }
    },
    async fetchDFData (formId) {
      /*
        This Method will retrieve the dynamic form data but it doesnt rely on any method to assign or automatically
        assign the data.
        TODO: Make fetching DF should use this instead in the future
      */
      this.formLoading = true
      const dynamicFormData = await this.getForm({ id: formId })
      return dynamicFormData
    },
    async assignDFData (formData, genericFields, isAssignGenericFormDataToDynamicForm = false, isCreateEditCompositeForm = false) {
      /*
        This Method will assign DF Data based on what genericFields arg. give
        Best used for listing a data to field-list
        TODO: Make assigning DF based on genericFields should use this instead in the future
      */
      if (!isEmpty(genericFields)) {
        formData.groups.forEach(group => {
          group.model_fields.forEach((modelField, index) => {
            const fieldName = modelField.model_field || modelField.name
            const dfModelField = find(formData.model_fields, ['name', fieldName])
            const genericModelField = find(genericFields, ['name', fieldName]) || {}
            if (this.isViewModel) {
              const genericAndDyanmicFormFieldExists = !isEmpty(genericModelField) && !isEmpty(dfModelField)
              const logic = modelField.logic
              genericModelField.logic = logic
              if (genericAndDyanmicFormFieldExists) {
                Object.assign(dfModelField, genericModelField)
              }
              group.model_fields.splice(index, 1, genericModelField) // need to do this because <FieldList /> Require genericModelField-like object structure
            } else {
              // if !isViewModel: it means [CREATE & UPDATE] of records scenarios
              if (isAssignGenericFormDataToDynamicForm) {
                // Assigning the value of each field from Generic Form to Dynamic Form
                modelField.options = Object.assign(dfModelField, genericModelField)
                if (isCreateEditCompositeForm) {
                  this.assignDynamicFormDataForCompositeForm(modelField)
                }
              } else {
                // Assigning the value of each field in Dynamic Form to Generic Form
                genericModelField.value = modelField.options.value
              }
            }
          })
        })
        return true
      } else {
        return false
      }
    }
  }
}
