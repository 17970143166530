export default {
  data () {
    return {
      headIdentifier: '{',
      tailIdentifier: '}'
    }
  },
  methods: {
    // parameters are record and labelFormat. record contain the data of the record.
    // labelFormat is the preformatted header label from items.meta.header_field
    // If labelFormat is not defined, it will return the unique id.
    // {(.*?)}, the regex pattern for searching for string encapsulated with a { & }.
    // labelArray will store all the matched results.
    // if matched results are found, getOutputString methods is invoked to replace the matched result.
    labelFormatterOutput (record, labelFormat) {
      if (this.isUndefinedOrIsNullOrIsBlank(labelFormat)) {
        labelFormat = record.unique_id
      } else {
        const regexPattern = this.headIdentifier + '(.*?)' + this.tailIdentifier
        const regex = new RegExp(regexPattern, 'g')
        let labelArray = labelFormat.match(regex)
        if (!this.isUndefinedOrIsNullOrIsBlank(labelArray)) {
          // Using map function to remove the curly bracket from the matched result.
          labelArray = labelArray.map(label => this.removeEnclosingCurlyBracket(label))
          labelFormat = this.getOutputString(record, labelFormat, labelArray)
        }
      }
      return labelFormat
    },
    // method is responsible for replacing the matched result with actual value.
    // if the actual value is undefined, null or blank, it will be replaced with a blank.
    getOutputString (record, labelFormat, labelArray) {
      labelArray.forEach((element, index) => {
        const valueToReplace = this.isUndefinedOrIsNullOrIsBlank(record[labelArray[index]]) ? '' : record[labelArray[index]]
        labelFormat = labelFormat.replace(this.headIdentifier + labelArray[index] + this.tailIdentifier, valueToReplace)
      })
      return labelFormat
    },
    isUndefinedOrIsNullOrIsBlank (value) {
      return value === undefined || value === null || value === ''
    },
    removeEnclosingCurlyBracket (label) {
      // substring method to remove the 1st and last character of the input. e.g {unique_id} will return unique_id.
      return label.substring(this.$LOCAL('LABEL_FORMATTER').SUBSTRING.START_POS, label.length - this.$LOCAL('LABEL_FORMATTER').SUBSTRING.END_POS)
    }
  }
}
