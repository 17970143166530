<template>
  <div class="root">
    <portal to="top-bar"><div class="title"><h1>{{ title }}</h1></div></portal>

    <div class="loader" v-if="loading"><b-spinner variant="secondary" large label="Loading..."/></div>

    <item-cards v-if="hasInitialised"
      ref="itemCards"
      :key="itemType"
      :itemType="itemType"
      :items="items()"
    />

    <div class="no-results" v-if="hasInitialised && !itemsExist">
      {{$INSIGHT('ERROR').DONT_HAVE_ANYTHING_HERE}}
    </div>

    <error-handler
      v-if="error !== false && !loading"
      :error="displayError"
      :showError="showError"
      :dismissible="false"
      variant="danger"
    />
  </div>
</template>

<script>
/*
  Items are the Entities and Links parent pages
  that list the individual ItemCards.
*/
import { mapActions, mapState } from 'vuex'

import ItemCards from '@/modules/insight/components/ItemCards'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'

export default {
  name: 'items',
  components: {
    ItemCards, ErrorHandler
  },
  props: {
    itemType: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    previous: null,
    displayError: {
      code: -1,
      message: ''
    },
    showError: false,
    hasInitialised: false
  }),
  computed: {
    ...mapState('insight', {
      entities: 'entities',
      links: 'links',
      error: 'error'
    }),
    title () {
      return this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY ? 'Entities' : 'Links'
    },
    itemsExist () {
      if (!this.hasInitialised) { return false }
      return Object.keys(this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY ? this.entities : this.links).length !== 0
    }
  },
  mounted () {
    this.loading = true
    this.fetch()
    this.setSearchTerm(false)
  },
  beforeDestroy () {
    this.resetFields()
  },
  methods: {
    ...mapActions('insight', [
      'getEntities',
      'getLinks',
      'resetFields',
      'setSearchTerm'
    ]),
    items () {
      return this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY ? this.entities : this.links
    },
    setError (code, message) {
      this.showError = true
      this.displayError = {
        code: code,
        message: message
      }
    },
    async fetch () {
      if (this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY) {
        await this.getEntities()
      } else {
        await this.getLinks()
      }
      this.loading = false
      this.hasInitialised = true

      if (this.error) {
        this.hasInitialised = false
      }
    }
  },
  watch: {
    error: {
      handler: function (newError) {
        if (newError !== false) {
          this.setError(newError.code, newError.message)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .root {
    position: relative;
  }
</style>
