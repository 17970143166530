<template>
  <div>
    <b-form-group v-if="confidenceOptions.length !== 0" :disabled="disabled">
      <b-form-radio-group
        v-model="confidenceValueModel"
        id="confidence-radio"
        name="linkendConfidence"
        class="btn-radios-1"
        size="sm"
        buttons
        button-variant="outline-secondary"
        :options="confidenceOptions"
        @change.native="verifyLink"
        aria-label="Strength of the Link"
      />
    </b-form-group>

    <b-form-group class="mb-0" v-if="directionOptions.length !== 0" :disabled="disabled">
      <b-form-radio-group
        v-model="directionValueModel"
        id="direction-radio"
        name="linkendDirection"
        class="btn-radios-1"
        size="sm"
        buttons
        button-variant="outline-secondary"
        :options="directionOptions"
        @change.native="verifyLink"
        aria-label="Direction of the Link"
      />
    </b-form-group>
  </div>
</template>

<script>

export default {
  name: 'link-connection-option-form',
  props: {
    confidenceValue: {
      default: () => (0)
    },
    directionValue: {
      default: () => (0)
    },
    confidenceOptions: {
      type: Array,
      default: () => ([])
    },
    directionOptions: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({}),
  computed: {
    confidenceValueModel: {
      get () {
        return this.confidenceValue
      },
      set (value) {
        this.$emit('setConfidenceValue', value)
      }
    },
    directionValueModel: {
      get () {
        return this.directionValue
      },
      set (value) {
        this.$emit('setDirectionValue', value)
      }
    }
  },
  methods: {
    verifyLink () {
      this.$emit('verifyLink')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
