<template>
  <div class="d-flex flex-column">
    <div class="d-flex mb-2 border-bottom">
        <span class="label-for-aggregate">
          <entity-icon :model="source_entity_type" :title="entityTypeLabel" :key="source_entity_type"></entity-icon>
          {{sourceTypeDisplayLabel}}
        </span>
        <svg class="flex-grow-1 header-link-svg">
            <text x="50%" y="33%" text-anchor="middle" font-size="normal" font-weight="bold">{{display_link_label_plural}}</text>
            <line class="link-line" x1="0" y1="50%" x2="100%" y2="50%"/>
          </svg>
        <span class="label-for-aggregate">
          <entity-icon :model="entityType" :key="entityType"></entity-icon>
          {{display_entity_label_plural}}
        </span>
    </div>
    <div class="d-flex flex-column mr-3 mb-1 border-bottom" v-for="entity of entityList" :key="entity.entity_id" :id="entity.entity_id">
      <div class="d-flex">
          <LaRoundedArrow class="la rotate-rounded-arrow" />
            <div class="flex-fill m-2">
              <span>{{ entity.entity_display_label }}</span>
              <b-link v-b-toggle="`link-panel-${entity.entity_id}`" class="justify-content-between custom-link"
                    @click="toggleShowLinkList(entity)" role="tab">
                <span v-if="hasReadPermission"><Arrow class="arrow-up"/></span>
                <span v-if="hasReadPermission"><Arrow class="arrow-down"/></span>
              </b-link>
            </div>
          <div class="text-center no-padding-right">
            <b-button v-if="hasReadPermission" variant="primary" size="sm"
                @click="fetchChildNodeFields(entityType, entity.entity_id)">
                  <LaEye class="la"/>
            </b-button>
            <b-button diabled v-else size="sm">
                  <span :id="`${entity.entity_id}-span`" tabindex="0"><LaEye class="la"/></span>
                  <b-tooltip v-if="!hasReadPermission" :target="`${entity.entity_id}-span`"
                             :title="$INSIGHT('ERROR').PERMISSION_WARNING.CANNOT_VIEW"/>
            </b-button>
          </div>
      </div>
      <div class="d-flex ml-5 mr-5 pb-1">
        <b-collapse :id="`link-panel-${entity.entity_id}`" accordion="my-accordion" role="tabpanel" class="flex-grow-1">
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner variant="secondary" small label="Loading..." />
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between m-1" v-for="link of linkData" :key="link.id" :id="link.id">
              <span>
                <LaTriangle v-bind:style="toggleLeftTriangle(link)" class="la triangle-left" />
                <svg class="list-link-svg">
                  <line v-bind:style="getLineStyle(link)" class="link-line" x1="0" y1="0" x2="100%" y2="0"/>
                </svg>
                <span class="pl-2 pr-2">{{link.label}}</span>
                <svg class="list-link-svg">
                  <line v-bind:style="getLineStyle(link)" class="link-line" x1="0" y1="0" x2="100%" y2="0"/>
                </svg>
                <LaTriangle v-bind:style="toggleRightTriangle(link)" class="la triangle-right" />
              </span>
              <span>
                <b-button v-if="hasReadLinkPermission" variant="primary" size="sm"
                  @click="fetchChildNodeFields(link.type, link.id)">
                    <LaEye class="la"/>
                </b-button>
                <b-button v-else  disabled size="sm">
                   <span :id="`${link.id}-span`" tabindex="0"><LaEye class="la"/></span>
                </b-button>
                <b-tooltip v-if="!hasReadPermission" :target="`${link.id}-span`"
                           :title="$INSIGHT('ERROR').PERMISSION_WARNING.CANNOT_VIEW"/>
              </span>
            </div>
            <div class="col-12 text-center padding-top-15px" v-if="loadMore">
              <b-button @click="showAllLinksModal(entity)" size="sm" pill variant="outline-secondary">
                <LaEyeGrey class="view-link-icon"/>
                  View all {{linkCount}} {{linkTypeDescription}}
              </b-button>
            </div>
            <div class="col-12 text-center padding-top-15px" v-if="loadMoreLoading">
              <b-spinner variant="secondary" small label="Loading..." />
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="col-12 text-center" v-if="hasNextPageEntity && !entityListLoading">
        <b-button  variant="outline" @click="loadMoreEntities()">
          <b-badge pill variant="secondary">{{$LOCAL('LoadMore')}}</b-badge>
        </b-button>
    </div>
    <div class="col-12 text-center" v-if="entityListLoading">
        <b-spinner small  label="Loading"/>
    </div>
    <div class="row col-12">
         <b-button @click="switchOffSegregatedEntitiesView" size="sm" pill variant="outline-secondary">
          <Arrow class="arrow-left" />
          {{ $LOCAL('COMMON_WORD').BACK }}
        </b-button>
    </div>
    <link-list-modal :linkList="linkListForModal"
       :showLinkListModal="trigger_showLinkListModal"
       :hasMoreLinkList="hasMoreLinkList"
       :linkTypeDescription="linkTypeDescription"
       :linkCount="linkCountForSegregatedLinksModal"
       :hasReadLinkPermission="hasReadLinkPermission"
       :sourceEntityType="source_entity_type"
       :entityTypeLabel="entityTypeLabel"
       :sourceTypeDisplayLabel="sourceTypeDisplayLabel"
       :targetEntityType="targetEntityType"
       :targetEntityId="targetEntityId"
       :targetDisplayLabel="targetDisplayLabel"
       @hideLinkListModal="closeLinkListModal"
       @triggerLoadMoreLinks="loadMoreLinks"></link-list-modal>
  </div>
</template>

<script>
import EntityIcon from '@/modules/insight/components/EntityIcon'
import LaEye from '@/assets/la-eye.svg'
import LaEyeGrey from '@/assets/la-eye-grey.svg'
import LaArrow from '@/assets/angle-up-solid.svg'
import LaRoundedArrow from '@/assets/la-rounded-arrow.svg'
import LaTriangle from '@/assets/la-triangle-left-solid.svg'
import LinkListModal from '@/modules/insight/components/LinkListModal'
import ModelService from '../services/model.service'

export default {
  name: 'SegregatedEntitiesList',
  components: {
    EntityIcon, LaRoundedArrow, Arrow: LaArrow, LaEye, LaTriangle, LaEyeGrey, LinkListModal
  },
  props: {
    segregatedEntities: {
      type: Object,
      required: true
    },
    targetTypeId: {
      type: Number,
      required: true
    },
    targetLinkTypeId: {
      type: Number,
      required: true
    },
    sourceTypeId: {
      type: String,
      required: true
    },
    sourceTypeDisplayLabel: {
      type: String,
      required: true
    },
    aggregatedLinkTypeLabel: {
      type: String,
      required: true
    },
    totalAggregatedLinkCount: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    entityType: false,
    entityList: [],
    entityCount: 0,
    entityTypeLabel: '',
    entityListLoading: false,
    entityToLoadForMoreLinks: '',
    hasReadPermission: false,
    hasNextPageEntity: false,
    hasReadLinkPermission: false,
    hasMoreLinkList: '',
    linkList: [],
    linkListForModal: [],
    loading: false,
    loadMore: false,
    pageNoForEntity: 1,
    linkCount: 0,
    loadMoreLoading: false,
    linkTypeDescription: '',
    linkData: [],
    linkCountForSegregatedLinksModal: 0,
    show: false,
    source_entity_id: '',
    source_entity_type: false,
    trigger_showLinkListModal: false,
    targetEntityType: '',
    targetEntityId: '',
    targetDisplayLabel: '',
    target_type_id: '',
    target_link_type_id: ''
  }),
  computed: {
    display_link_label_plural () {
      return `${this.totalAggregatedLinkCount} ${this.aggregatedLinkTypeLabel}s`
    },
    display_entity_label_plural () {
      return `${this.entityCount} ${this.entityTypeLabel}s`
    }
  },
  mounted () {
    this.tabulateEntities()
  },
  methods: {
    tabulateEntities () {
      this.entityType = this.segregatedEntities.results.entity_type
      this.entityCount = this.segregatedEntities.results.entity_count
      this.entityList = this.segregatedEntities.results.entity_list
      this.entityTypeLabel = this.segregatedEntities.results.entity_type_label
      this.hasReadPermission = this.segregatedEntities.results.has_read_permission
      this.source_entity_id = this.segregatedEntities.results.source_entity_id
      this.source_entity_type = this.segregatedEntities.results.source_entity_type
      this.target_type_id = this.targetTypeId
      this.target_link_type_id = this.targetLinkTypeId
      this.toggleHasNextPageForEntity(this.segregatedEntities.next)
    },
    toggleHasNextPageForEntity (hasNextPageValue) {
      if (String(hasNextPageValue) === 'null') {
        this.hasNextPageEntity = false
      } else {
        this.hasNextPageEntity = true
      }
    },
    switchOffSegregatedEntitiesView () {
      this.$emit('clicked_switchOffSegregatedEntitiesView', false)
    },
    async toggleShowLinkList (entity) {
      this.tabulateEntities() // Always refresh the data
      let linkDetails = null
      this.loadMore = false
      this.linkCount = 0
      this.linkData = []

      // To find the cached link data by the target entity ID
      const cachedLinkData = this.linkList.find(link => link.target_id === entity.entity_id)
      if (cachedLinkData === undefined) {
        this.loading = true
        linkDetails = await ModelService.getSegregatedLinks(this.source_entity_type, this.source_entity_id,
          entity.entity_id, this.target_link_type_id, 'false')
        // To achieve the reduction in API calling, the link list will be cached.
        this.linkList.push({ target_id: linkDetails.results.link_list[0].target, link_data: linkDetails })
      } else {
        linkDetails = cachedLinkData.link_data
      }
      this.hasReadLinkPermission = linkDetails.results.has_read_permission
      this.linkData = linkDetails.results.link_list
      if (linkDetails.results.link_count > this.$INSIGHT('COMPOUND_ENTITIES').DISPLAY_LINKS_THRESHOLD) {
        this.loadMore = true
        this.linkCount = linkDetails.results.link_count
        this.linkTypeDescription = linkDetails.results.link_type_description
      }
      this.loading = false
    },
    async showAllLinksModal (entity) {
      this.loadMore = false
      this.loadMoreLoading = true
      const getLinkList = await ModelService.getSegregatedLinks(this.source_entity_type, this.source_entity_id,
        entity.entity_id, this.target_link_type_id, 'true')
      this.linkListForModal = getLinkList.results.link_list
      this.entityToLoadForMoreLinks = entity.entity_id
      this.hasMoreLinkList = String(getLinkList.next)
      this.hasReadLinkPermission = getLinkList.results.has_read_permission
      this.linkCountForSegregatedLinksModal = getLinkList.results.link_count
      this.targetEntityType = getLinkList.results.link_list[0].target_type
      this.targetEntityId = getLinkList.results.link_list[0].target
      this.targetDisplayLabel = entity.entity_display_label
      this.loadMore = true
      this.loadMoreLoading = false
      this.trigger_showLinkListModal = true
    },
    closeLinkListModal () {
      this.trigger_showLinkListModal = false
    },
    async loadMoreLinks (pageNoForLinks) {
      const getLinkList = await ModelService.getSegregatedLinks(this.source_entity_type, this.source_entity_id,
        this.entityToLoadForMoreLinks, this.target_link_type_id, 'true', pageNoForLinks)
      this.linkListForModal = getLinkList.results.link_list
      this.hasMoreLinkList = String(getLinkList.next)
    },
    async loadMoreEntities () {
      this.pageNoForEntity += 1
      this.entityListLoading = true
      const getEntityList = await ModelService.getSegregatedEntities(this.source_entity_type, this.source_entity_id,
        this.target_link_type_id, this.target_type_id, this.pageNoForEntity)
      this.entityList = this.entityList.concat(getEntityList.results.entity_list)
      this.entityListLoading = false
      this.toggleHasNextPageForEntity(getEntityList.next)
    },
    getLineStyle (linkendData) {
      let dasharray
      switch (linkendData[this.$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE]) {
        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.CONFIRMED:
          dasharray = '0%'
          break

        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.UNCONFIRMED:
          dasharray = '10%'
          break

        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.TENTATIVE:
          dasharray = '3%'
          break

        default:
          dasharray = '0%'
          break
      }
      return { 'stroke-dasharray': dasharray, stroke: linkendData.colour }
    },
    toggleLeftTriangle (linkEndData) {
      if (linkEndData.direction === 2 || linkEndData.direction === 3) {
        return { fill: 'black', 'fill-opacity': 1 }
      }
    },
    toggleRightTriangle (linkEndData) {
      if (linkEndData.direction === 1 || linkEndData.direction === 3) {
        return { fill: 'black', 'fill-opacity': 1 }
      }
    },
    fetchChildNodeFields (type, id) {
      const data = () => ({ type, id })
      const event = {
        target: {
          data
        }
      }
      this.$emit('fetchChildNodeFields', event)
    }
  },
  watch: {
    segregatedEntities: {
      handler: function (segregatedEntities) {
        this.entityType = segregatedEntities.results.entity_type
        this.entityCount = segregatedEntities.results.entity_count
        this.entityList = segregatedEntities.results.entity_list
        this.entityTypeLabel = segregatedEntities.results.entity_type_label
        this.hasReadPermission = segregatedEntities.results.has_read_permission
        this.target_type_id = this.targetTypeId
        this.target_link_type_id = this.targetLinkTypeId
        this.toggleHasNextPageForEntity(this.segregatedEntities.next)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-left {
  fill: grey;
  height: 15px;
  transform: rotate(-90deg);
  margin-top: -2px;
}

.custom-link {
  color: #313947;
}

.la-arrow {
  fill: #313947;
}

.arrow-up {
  height: 20px;
  float: right;
  padding-bottom: 0.3em;
  margin-bottom: -5px;
  transition: all 0.2s;
  color: #007bff;
}

.arrow-down {
  height: 20px;
  float: right;
  padding-top: 0.3em;
  margin-bottom: -5px;
  transform: rotate(180deg);
  transition: all 0.2s;
}

.collapsed > span> .arrow-up {
  display: none;
}

:not(.collapsed) > span > .arrow-down {
  display: none;
}

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

.line-confidence {
  height: 35px;
  float: right;
  margin-bottom: -5px;
}

.view-link-icon {
  width: 15px;
  height: 15px;
}

.header-link-svg {
  height: 60px;
  width: 100%;
  display: block;
}

.list-link-svg {
  height: 10px;
  width: 40px;
  vertical-align: baseline;
  padding-top: 5px
}

.link-line {
  stroke:black;
  stroke-width: 3;
  stroke-dasharray: 0%;
}

.label-for-aggregate {
  font-size: small;
  font-weight: 400;
  display: inline-block;
  text-align: center;
}

.tooltip { top: 0; }

.rotate-rounded-arrow {
  height: 36px;
  width: 36px;
  display: inline-block;
  text-align: center;
  transform: rotate(180deg);
  fill:grey;
}

.right-rounded-arrow-margin {
  margin-right: 16px
}

.no-padding-right {
  padding-right: 0px;
}

.triangle-right {
  transform: rotate(180deg);
  fill:white;
  fill-opacity: 0;
  padding-bottom:2px
  }

.triangle-left {
  fill:white;
  fill-opacity: 0;
  padding-top:2px
}
</style>
