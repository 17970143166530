var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[_c('portal',{attrs:{"to":"top-bar"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.title))])])]),(_vm.error !== false && _vm.loading === false && _vm.error.code !== 404)?_c('error-handler',{attrs:{"error":_vm.displayError,"dismissible":false,"variant":"danger","showError":_vm.showError}}):_vm._e(),(!_vm.modelList)?_c('div',{staticClass:"loader"},[_c('b-spinner',{attrs:{"variant":"secondary","large":"","label":((_vm.$LOCAL('COMMON_WORD').LOADING) + "...")}})],1):_vm._e(),(_vm.modelList && _vm.permissions.read)?_c('search-sort-controls',{staticClass:"search-controls",attrs:{"loading":_vm.loading,"showSortAndRefresh":_vm.hasInitialised,"hasClearSearchCross":false,"currentSentSearch":_vm.searchForm,"hasAdvancedSearch":true,"isAdvancedOpened":_vm.advanced,"advancedSearchFields":_vm.advancedSearchFields,"advancedSearch":_vm.advancedSearch,"hasModelSelection":true,"initialModel":_vm.modelName,"models":_vm.modelList,"sortFields":_vm.items.fields ? _vm.items.fields : {},"isForEntityOrLink":true,"searchCount":_vm.items.count ? _vm.items.count : 0,"searchNoun":_vm.$LOCAL('COMMON_WORD').RESULTS},on:{"sort":_vm.sort,"changeModel":_vm.changeModel,"toggleAdvanced":_vm.toggleAdvanced,"search":_vm.fetch,"refresh":_vm.refresh}}):_vm._e(),(_vm.hasInitialised)?_c('div',{staticClass:"results-area",attrs:{"id":"result-area"}},[_c('index-cards',{attrs:{"items":_vm.items.results,"searchQuery":_vm.searchValue,"sortField":_vm.sortBy,"loading":_vm.loading,"fields":_vm.items.fields},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.headerField(item))+" ")]}},{key:"view",fn:function(ref){
var item = ref.item;
return [(_vm.permissions.read)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:result-area.hover",arg:"result-area",modifiers:{"hover":true}}],attrs:{"variant":"primary","title":_vm.$LOCAL('ToolTipLabelView'),"to":{ name: _vm.isEntity ? 'entity' : 'link', params: { id: item.unique_id, model: _vm.$route.params.model } },"aria-label":((_vm.$LOCAL('AriaLabelView')) + " " + (_vm.headerField(item)))}},[_c('LaEye',{staticClass:"la"})],1):_vm._e()]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [(_vm.permissions.edit)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:result-area.hover",arg:"result-area",modifiers:{"hover":true}}],attrs:{"variant":"success","title":_vm.$LOCAL('ToolTipLabelEdit'),"to":{ name: _vm.isEntity ? 'edit-entity' : 'edit-link', params: { model: _vm.$route.params.model, id: item.unique_id }},"aria-label":((_vm.$LOCAL('AriaLabelEdit')) + " " + (_vm.headerField(item)))}},[_c('LaEdit',{staticClass:"la"})],1):_vm._e()]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [(_vm.permissions.delete)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:result-area.hover",arg:"result-area",modifiers:{"hover":true}}],attrs:{"variant":"danger","title":_vm.$LOCAL('ToolTipLabelDelete'),"aria-label":((_vm.$LOCAL('AriaLabelDelete')) + " " + (_vm.headerField(item)))},on:{"click":function($event){return _vm.deleteThisRecord(item)}}},[_c('LaTrash',{staticClass:"la"})],1):_vm._e()]}}],null,false,759794840)})],1):_vm._e(),(_vm.hasInitialised && _vm.items.results.length < _vm.items.count)?_c('div',{staticClass:"load-more"},[_c('b-button',{staticClass:"load-more-button",attrs:{"variant":"outline"},on:{"click":_vm.next}},[(_vm.loading === false)?_c('b-badge',{attrs:{"pill":"","variant":"secondary"}},[_vm._v(_vm._s(_vm.$LOCAL('LoadMore')))]):_vm._e(),(_vm.loading === true)?_c('b-spinner',{attrs:{"small":"","label":"Loading"}}):_vm._e()],1)],1):_vm._e(),_c('delete-modal',{ref:"deleteModal",attrs:{"item":_vm.modelMeta.label,"itemId":_vm.itemNameToDelete,"modalShow":_vm.showDeleteModal},on:{"ok":_vm.confirmDelete,"hide":_vm.cancelDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }