<template>
  <div>
      <span v-if="operatorType === 'single' && (columnType === 'datalist' || columnType === 'select')">
        <b-input :id="'field-condition-' + fieldIndex" size="sm" :list="`datalist-${fieldIndex}`" v-model="inputValue"/>
        <b-form-datalist :id="`datalist-${fieldIndex}`" :options="fieldChoices"/>
      </span>
      <span v-else-if="operatorType === 'single' && columnType === 'radio'">
        <b-select :id="'field-condition-' + fieldIndex" size="sm" v-if="!checkboxField[fieldIndex] && inputValue !== '@'" :options="getRadioFieldOptions(fieldChoices)" v-model="inputValue" :state="isRadioFieldValid(inputValue)"/>
        <b-input :id="'field-condition-' + fieldIndex" size="sm" v-else v-model="inputValue" disabled></b-input>
      </span>
      <dual-input :id="'field-condition-' + fieldIndex" size="sm" v-else-if="operatorType === 'dual'" :value1="inputValue" @input1="value => { inputValue = value }" :value2="field.value_2" @input2="value => { field.value_2 = value }" @input="counter++" :type="fieldType(columnType, inputValue, field.value_2)"/>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'year'" v-model="inputValue" :placeholder="$INSIGHT('MISC').COMMON_PHRASE.TYPE_A_YEAR" :type="getInputFieldTypeNumber(inputValue)"/>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'single' && columnType === 'calculated_number'" v-model="inputValue" :type="getInputFieldTypeNumber(inputValue)"/>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'single' && columnType === 'calculated_date'" v-model="inputValue" :type="getInputFieldTypeDate(inputValue)"/>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'month' && checkboxField[fieldIndex]" v-model="inputValue" type="text"/>
      <b-select :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'month'" v-model="inputValue" :options="listOfMonth"></b-select>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'day' && checkboxField[fieldIndex]" v-model="inputValue" type="text"/>
      <b-select :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'day'" v-model="inputValue" :options="listOfDay"></b-select>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'single' && columnType === 'datetime'" v-model="inputValue" :type="getInputFieldTypeDate(inputValue)"/>
      <b-input :id="'field-condition-' + fieldIndex" size="sm"  v-else-if="operatorType === 'single'" v-model="inputValue" :type="fieldType(columnType, inputValue, '')" />
      <input-list-form :id="'field-condition-' + fieldIndex"
                       v-else-if="operatorType === 'list' && !checkboxField[fieldIndex] && inputValue !== '@' && page !== 'generatePage'"
                       v-model="inputValue"
                       :fieldIndex="fieldIndex"
                       :options="parseOptions(columnOptions[field.field_name])"
      />
      <input-list-form :id="'field-condition-' + fieldIndex"
                       v-else-if="operatorType === 'list' && !checkboxField[fieldIndex] && inputValue !== '@' && page === 'generatePage'"
                       v-model="inputValue"
                       :fieldIndex="fieldIndex"
                       :options="fieldChoices"
      />
      <b-input :id="'field-condition-' + fieldIndex" size="sm" v-else-if="operatorType === 'list'" v-model="inputValue" disabled></b-input>
  </div>
</template>

<script>
import DualInput from './DualInput'
import InputListForm from './InputListForm'

export default {
  name: 'input-field-form',
  components: {
    DualInput,
    InputListForm
  },
  props: {
    operatorType: {
      type: [String, Array, Boolean],
      default: () => ('')
    },
    columnOptions: {
      default: () => ([])
    },
    fieldIndex: {
      default: () => (0)
    },
    value: {
      default: () => ([])
    },
    checkboxField: {
      default: () => ([])
    },
    field: {
      default: () => ({})
    },
    columnType: {
      default: () => ('')
    },
    fieldChoices: {
      default: () => ([])
    },
    page: {
      default: () => ('')
    },
    listOfMonth: {
      default: () => ([])
    },
    listOfDay: {
      default: () => ([])
    }
  },
  data: () => ({
    counter: 0
  }),
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    parseOptions (field) {
      if (field.meta?.choices) {
        return field.meta?.choices
      } else {
        return []
      }
    },
    getRadioFieldOptions (metaOptions) {
      return metaOptions.map((choice) => ({ text: choice.text, value: choice.text }))
    },
    getInputFieldTypeNumber (value) {
      return value.toString() === '@' || value.toString().startsWith('@#') ? 'text' : 'number'
    },
    getInputFieldTypeDate (value) {
      return value.toString() === '@' || value.toString().startsWith('@#') ? 'text' : 'date'
    },
    fieldType (type, value1, value2) {
      if (value1?.toString() === '@' || value1?.toString().startsWith('@#')) return 'text'
      if (value2?.toString() === '@' || value2?.toString().startsWith('@#')) return 'text'
      if (['date', 'time', 'datetime'].includes(type)) return type
      if (type === 'calculated_number') return 'number'
      if (type === 'calculated_date') return 'date'
      return 'text'
    },
    isRadioFieldValid (value) {
      if (value === 'Yes' || value === 'No' || value === '@' || value === '') {
        return null
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
