<template>
  <div class="root">
    <portal to="top-bar"><div class="title"><h1>{{ title }}</h1></div></portal>

    <error-handler
      v-if="error !== false && loading === false && error.code !== 404"
      :error="displayError"
      :dismissible="false" variant="danger"
      :showError="showError"
    />

    <div v-if="!modelList" class="loader">
      <b-spinner variant="secondary" large :label="`${$LOCAL('COMMON_WORD').LOADING}...`"/>
    </div>

    <search-sort-controls v-if="modelList && permissions.read"
      class="search-controls"
      :loading="loading"
      :showSortAndRefresh="hasInitialised"
      :hasClearSearchCross="false"
      :currentSentSearch="searchForm"
      :hasAdvancedSearch="true"
      :isAdvancedOpened="advanced"
      :advancedSearchFields="advancedSearchFields"
      :advancedSearch="advancedSearch"
      :hasModelSelection="true"
      :initialModel="modelName"
      :models="modelList"
      :sortFields="items.fields ? items.fields : {}"
      :isForEntityOrLink="true"
      :searchCount="items.count ? items.count : 0"
      :searchNoun="$LOCAL('COMMON_WORD').RESULTS"
      @sort="sort"
      @changeModel="changeModel"
      @toggleAdvanced="toggleAdvanced"
      @search="fetch"
      @refresh="refresh"
    />

    <div class="results-area" v-if="hasInitialised" id="result-area">
      <!-- TODO JSON Routes -->
      <index-cards :items="items.results" :searchQuery="searchValue" :sortField="sortBy" :loading="loading" :fields="items.fields">
        <template v-slot:name="{ item }">
          {{ headerField(item) }}
        </template>
        <template v-slot:view="{ item }">
          <b-btn variant="primary" v-if="permissions.read"
                 v-b-tooltip:result-area.hover :title="$LOCAL('ToolTipLabelView')"
                 :to="{ name: isEntity ? 'entity' : 'link', params: { id: item.unique_id, model: $route.params.model } }"
                 :aria-label="`${$LOCAL('AriaLabelView')} ${headerField(item)}`">
            <LaEye class="la"/>
          </b-btn>
        </template>
        <template v-slot:edit="{ item }">
          <b-btn variant="success" v-if="permissions.edit"
                 v-b-tooltip:result-area.hover :title="$LOCAL('ToolTipLabelEdit')"
                  :to="{ name: isEntity ? 'edit-entity' : 'edit-link', params: { model: $route.params.model, id: item.unique_id }}"
                  :aria-label="`${$LOCAL('AriaLabelEdit')} ${headerField(item)}`">
            <LaEdit class="la"/>
          </b-btn>
        </template>
        <template v-slot:delete="{ item }">
          <b-btn @click="deleteThisRecord(item)" variant="danger" v-if="permissions.delete"
                 v-b-tooltip:result-area.hover :title="$LOCAL('ToolTipLabelDelete')"
                 :aria-label="`${$LOCAL('AriaLabelDelete')} ${headerField(item)}`">
            <LaTrash class="la"/>
          </b-btn>
        </template>
      </index-cards>
    </div>

    <div v-if="hasInitialised && items.results.length < items.count" class="load-more">
      <b-button class="load-more-button" variant="outline" @click="next">
        <b-badge pill variant="secondary" v-if="loading === false">{{$LOCAL('LoadMore')}}</b-badge>
        <b-spinner small v-if="loading === true" label="Loading"/>
      </b-button>
    </div>

    <delete-modal
      ref="deleteModal"
      :item="modelMeta.label"
      :itemId="itemNameToDelete"
      :modalShow="showDeleteModal"
      @ok="confirmDelete"
      @hide="cancelDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LaEye from '@/assets/la-eye.svg'
import LaEdit from '@/assets/la-edit.svg'
import LaTrash from '@/assets/la-trash.svg'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import IndexCards from '@/modules/insight/components/IndexCards'
import DeleteModal from '@/modules/insight/components/DeleteModal'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import HeaderLabelFormatterMixin from '@/modules/insight/mixins/HeaderLabelFormatterMixin'

export default {
  mixins: [HeaderLabelFormatterMixin],
  name: 'entities-links-index',
  components: {
    IndexCards, ErrorHandler, LaEye, LaEdit, LaTrash, DeleteModal, SearchSortControls
  },
  props: {
    itemType: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    page: 1,
    perPage: 25,
    sortBy: ['-create_date', 'unique_id'],
    sortText: 'Sort',
    asc: ' - ascending',
    desc: ' - descending',
    idToDelete: '',
    itemNameToDelete: '',
    showDeleteModal: false,
    item: false,
    modelName: '',
    searchForm: '',
    searchValue: '',
    termNotFound: '',
    advanced: false,
    // TODO JSON CHECK Maybe this should also be set somehow?
    // TODO Minimally need to abstract somewhere as it is called again below
    advancedSearch: {
      type: 'and',
      groups: [
        {
          type: 'and',
          conditions: [
            {
              field: '',
              field_type: '',
              operator_type: null,
              value: ''
            }
          ]
        }
      ]
    },
    permissions: {
      read: true,
      edit: true,
      delete: true
    },
    displayError: {
      code: -1,
      message: ''
    },
    showError: false
  }),
  computed: {
    ...mapState('insight', {
      items: 'records',
      error: 'error',
      searchError: 'searchError',
      entities: 'entities',
      links: 'links',
      fields: 'fields',
      advancedSearchFields: 'advancedSearchFields',
      searchTerm: 'searchTerm'
    }),
    modelMeta () {
      return (this.modelList && this.modelList[this.modelType]) ? this.modelList[this.modelType] : false
    },
    hasInitialised () {
      return typeof this.items.results === 'object'
    },
    title () {
      if (this.modelMeta.label) return `Browse ${this.modelMeta.label}`
      else return ''
    },
    isEntity () {
      return this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY
    },
    modelList () {
      return this.isEntity ? this.entities : this.links
    },
    modelType () {
      return this.$route.params.model
    },
    pathPrefix () {
      return this.isEntity ? '/entities/' : '/links/'
    },
    entityOrLink () {
      return this.isEntity ? 'Entity' : 'Link'
    }
  },
  mounted () {
    this.getAdvancedSearchFields(this.modelType)
    if (this.$route.meta && this.$route.meta.advanced) {
      this.advancedSearch = this.$route.meta.advanced
    }
    this.fetchList()
    this.checkSearch()
    this.checkPermissions()
  },
  beforeDestroy () {
    this.resetFields()
  },
  methods: {
    ...mapActions('insight', [
      'getLinks',
      'getEntities',
      'getRecords',
      'deleteRecord',
      'resetFields',
      'getAdvancedSearchFields',
      'setSearchTerm'
    ]),
    setError (code, message) {
      this.showError = true
      this.displayError = {
        code: code,
        message: message
      }
    },
    headerField (item) {
      let labelString = this.items.meta.header_field
      labelString = this.labelFormatterOutput(item, labelString)
      return labelString
    },
    deleteThisRecord (item) {
      if (item) {
        this.idToDelete = item.unique_id
        this.itemNameToDelete = this.headerField(item)
        this.showDeleteModal = true
      }
    },
    async confirmDelete () {
      const deleteProcess = await this.deleteRecord({ model: this.modelType, id: this.idToDelete })
      this.$emit('resetSearch', this.searchForm)
      this.fetch(this.searchForm)
      this.$refs.deleteModal.recordDeleted(deleteProcess)
    },
    cancelDelete () {
      this.showDeleteModal = false
    },
    async checkSearch () {
      if (this.searchTerm) {
        this.searchForm = this.searchTerm.search
        if (this.searchTerm.advancedSearch) {
          this.advanced = true
          this.advancedSearch.groups = this.searchTerm.advancedSearch.groups // maybe later this need to be change according to query saving
        }
        this.$emit('resetSearch', this.searchForm)
        this.fetch(this.searchForm)
      } else {
        this.setSearchTerm(false)
      }
    },
    async fetch (searchInput, append = false, refresh = false) {
      if (this.loading === true) {
        return
      }

      if ((!refresh && searchInput) || (refresh && this.searchValue) || (this.advanced && this.addAdvancedSearch() !== null)) {
        this.loading = true
        this.searchForm = searchInput

        if (!append) {
          this.page = 1
        }

        const params = {
          page: this.page,
          search: refresh ? this.searchValue : this.searchForm,
          perPage: this.perPage,
          ordering: this.sortBy.join(','),
          types: this.isEntity ? 'entities' : 'links'
        }

        if (this.advanced === true) {
          params.advancedSearch = this.addAdvancedSearch()
        }

        await this.getRecords({
          model: this.modelType,
          params,
          append
        })

        this.setSearchTerm({ searchTerm: params })
        this.searchValue = this.searchForm // for highlighting
      } else {
        this.$bvToast.toast(this.$INSIGHT('ERROR').SEARCH_TERM_REQUIRED, {
          title: 'Search',
          autoHideDelay: 5000,
          variant: 'warning',
          appendToast: false
        })
      }
      this.loading = false
    },
    addAdvancedSearch () {
      let i = 0
      for (const group of this.advancedSearch.groups) {
        for (const field of group.conditions) {
          const column = this.advancedSearchFields[field.field]
          if (column === undefined) {
            return null
          }
          const operator = column.operators.find(o => o.value === field.operator_type)
          if (operator === undefined) {
            return null
          }

          if (
            (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.SINGLE || operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.MONTH) &&
            ['', null].indexOf(field.value) === -1
          ) {
            i++
          } else if (
            operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.DUAL &&
            typeof field.value === 'object' &&
            ['', null].indexOf(field.value[0]) === -1 &&
            ['', null].indexOf(field.value[1]) === -1
          ) {
            i++
          } else if (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.BLANK) {
            i++
          } else if (operator.type === this.$INSIGHT('SEARCH').OPERATOR_TYPES.YEAR && field.value > 999 && field.value < 10000) {
            i++
          } else {
            return false
          }
        }
      }

      return (i > 0) ? this.advancedSearch : null
    },
    fetchList () {
      if (this.modelMeta === false) {
        this.checkPermissions() // on checkPermissions Entities or Links are fetched
      }
      this.modelName = this.modelType || ''
    },
    next () {
      if (this.loading === true) {
        return
      }
      this.page++
      this.fetch(this.searchForm, true)
    },
    changeModel (selectedModel) {
      this.modelName = selectedModel
      this.page = 1
      this.searchForm = ''
      this.loading = false
      this.advanced = false
      this.advancedSearch = {
        type: 'and',
        groups: [
          {
            type: 'and',
            conditions: [
              {
                field: '',
                field_type: '',
                operator_type: null,
                value: ''
              }
            ]
          }
        ]
      }
      this.sortBy = ['-create_date', 'unique_id']
      this.sortText = 'Sort'
      this.$router.push({
        path: this.pathPrefix + this.modelName
      })
      this.getAdvancedSearchFields(this.modelType)
      this.resetFields()
      this.setSearchTerm(false)
    },
    refresh () {
      this.$emit('resetSearch', this.searchForm)
      this.fetch(this.searchForm, false, true)
    },
    sort (sortBy) {
      this.sortBy = sortBy
      this.$emit('resetSearch', this.searchForm)
      this.fetch(this.searchForm, false, true)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    async checkPermissions () {
      const response = this.isEntity ? await this.getEntities() : await this.getLinks()

      if (response === true && (this.modelList[this.modelType] && this.modelList[this.modelType].permissions)) {
        const permissions = this.modelList[this.modelType].permissions
        if (permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ))) {
          this.permissions.read = false
          this.resetFields()
          this.setSearchTerm(false)
        } else {
          this.permissions.read = true
        }
        this.permissions.edit = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').UPDATE))
        this.permissions.delete = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DELETE))
      }
    }
  },
  watch: {
    items: function () {
      if (this.items.count === 0) {
        this.termNotFound = this.searchForm
      }
    },
    $route (to) {
      if (['entities-index', 'links-index'].indexOf(to.name) !== -1 && to.params && to.params.model) {
        this.modelName = to.params.model
      }
      this.checkPermissions()
    },
    error: {
      handler: function (newError) {
        if (newError !== false) {
          this.setError(newError.code, newError.message)
        }
      },
      deep: true
    },
    searchError: {
      handler: function (newSearchError) {
        if (newSearchError !== false) {
          this.setError(newSearchError.code, newSearchError.message)
        }
      },
      deep: true
    },
    permissions: {
      handler: function (newPermissions) {
        if (!newPermissions.read) {
          this.$router.push({ path: this.pathPrefix })
          this.$root.$bvToast.toast(`${this.$INSIGHT('READ').TOAST.PERMISSION_RESTRICTED.TEXT} ${this.entityOrLink}`, {
            title: `${this.$INSIGHT('READ').TOAST.PERMISSION_RESTRICTED.TITLE}`,
            autoHideDelay: 5000,
            variant: 'danger',
            opacity: 1
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .root {
    position: relative;
  }

  .search-info {
    font-size: 1.2em;
  }

  .results-area {
    .results {
      margin: -14px;
    }
  }

  .load-more {
    margin-top: 7px;
  }

  .delete-button {
    position: relative;
    margin: 7px 7px 7px -31px;

    svg {
      margin: 0 auto;
      display: block;
    }
  }

  .search-note {
    font-style: italic;
    vertical-align: baseline;
    font-size: 1.3rem;
    margin-bottom: 0;
    font-weight: normal;
  }

</style>
