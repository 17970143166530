<template>
  <div class="root">
    <portal to="top-bar">
      <div class="title">
        <h1>
          <router-link :to="{ name: 'links-index', params: { model: $route.params.model } }" :aria-label="`${linkName} ${$LOCAL('LINK')}`">
            {{ linkName }}
          </router-link>
          <span role="text" v-if="$route.params.id" class="mr-2">{{ $route.params.id }}</span>
        </h1>
      </div>

      <span class="actions">
        <b-button-group v-if="this.$route.meta.action === 'edit'" size="sm">
          <b-btn
            v-if="permissions.read"
            variant="outline-success"
            :to="{ name: 'link', params: { model: $route.params.model, id: $route.params.id } }"
          >
            {{$LOCAL('COMMON_WORD').VIEW}}
          </b-btn>
          <b-btn
            v-if="permissions.delete"
            variant="outline-danger"
            @click="showDeleteModal(fieldName = $route.params.model)"
          >
            {{$LOCAL('COMMON_WORD').DELETE}}
          </b-btn>
        </b-button-group>
      </span>
    </portal>

    <div class="loader" v-if="loading">
      <b-spinner variant="secondary" large label="Loading..."/>
    </div>

    <link-form-field
      ref="LinkFormField"
      :permissions="permissions"
      :presetEntity="presetEntity"
      :typeOfForm="$route.meta.action"
      @loading = 'checkLoadingStatus'
      @setNavigationGuard="setNavigationGuard"
      @setCanLeavePage="setCanLeavePage"
      @goBack="goBack"
      :blacklistedFileExt="blacklistedFileExt"
      :maxFileSize="maxFileSize"
    />

    <delete-modal
      v-if="$route.meta.action === 'edit'"
      ref="deleteModal"
      :item="$route.params.model"
      :itemId="this.$route.params.id"
      :modalShow="isShowDeleteModal"
      @ok="deleteThisRecord"
      @hide="isShowDeleteModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LinkFormField from '@/modules/insight/components/LinkFormField'
import DeleteModal from '@/modules/insight/components/DeleteModal'
import NavigationGuardMixin from '@/modules/insight/mixins/NavigationGuardMixin'

export default {
  name: 'CreateEditLink',
  mixins: [NavigationGuardMixin],
  components: {
    LinkFormField, DeleteModal
  },
  props: ['presetEntity'],
  data: () => ({
    loading: false,
    deleteModalMessage: '',
    isShowDeleteModal: false,
    permissions: {
      read: false,
      create: false,
      edit: false,
      delete: false,
      accessGenericForm: true
    }
  }),
  computed: {
    ...mapState('insight', [
      'record', 'fields', 'links', 'error'
    ]),
    linkName () {
      if (this.record && this.record.model.type === 'link') {
        return this.record.model.name
      }
      if (this.fields) {
        return this.fields.model.name
      }
      return this.$route.params.model
    }
  },
  mounted () {
    this.checkPermissions()
    this.fetchBlacklistedFileExt()
    this.fetchMaxFileSize()
  },
  methods: {
    ...mapActions('insight', ['deleteRecord', 'getLinks', 'getBlacklistedFileExt', 'getMaxFileSize']),
    checkLoadingStatus (theStatus) {
      this.loading = theStatus
    },
    showDeleteModal (fieldName, action) {
      this.fieldNameToDelete = fieldName
      if (this.permissions.delete) this.isShowDeleteModal = true
      this.deleteModalMessage = action === 'deleteAttachment' ? this.$route.params.id + `'s ${this.$LOCAL('COMMON_WORD').ATTACHMENT}` : this.$route.params.id
      this.deleteAction = action
    },
    async deleteThisRecord () {
      this.setCanLeavePage(true)
      const response = await this.deleteRecord({
        model: this.$route.params.model,
        id: this.$route.params.id
      })
      this.$refs.deleteModal.recordDeleted(response)
      this.$router.push({ name: 'links-index' })
    },

    async checkPermissions () {
      const response = await this.getLinks()
      if (response === true && (this.links[this.$route.params.model] && this.links[this.$route.params.model].permissions)) {
        const permissions = this.links[this.$route.params.model].permissions
        this.permissions.read = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ))
        this.permissions.create = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').CREATE))
        this.permissions.edit = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').UPDATE))
        this.permissions.delete = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DELETE))
        this.permissions.accessGenericForm = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').ACCESS_GENERIC_FORM))
        if (
          ((!this.permissions.read || !this.permissions.edit) && (this.$route.meta.action === 'edit')) ||
          ((!this.permissions.create) && (this.$route.meta.action === 'create'))
        ) {
          this.reRouteWithToastError()
        }
      }
    },
    reRouteWithToastError () {
      const action = this.$route.meta.action.toUpperCase()
      const field = `${action}_PERMISSION_RESTRICTED` // Get the corresponding field in $INSIGHT JSON file
      this.$router.push({ name: 'links' })
      this.$root.$bvToast.toast(`${this.$INSIGHT('CREATE_EDIT').TOAST[field].TEXT} ${this.$LOCAL('Link')}`, {
        title: this.$INSIGHT('CREATE_EDIT').TOAST[field].TITLE,
        autoHideDelay: 5000,
        variant: 'danger',
        opacity: 1
      })
    },
    setNavigationGuard () {
      // To set data in NavigationGuardMixin
      const comparedData = {
        fields: this.fields,
        record: this.record,
        dynamicFormData: this.$refs.LinkFormField.dynamicFormData
      }
      this.canLeavePage = false
      this.comparedData = comparedData
      this.initialData = JSON.stringify(this.comparedData)
    },
    async fetchBlacklistedFileExt () {
      const response = await this.getBlacklistedFileExt()
      this.blacklistedFileExt = response.BLACKLISTED_FILE_EXT
    },
    async fetchMaxFileSize () {
      this.maxFileSize = await this.getMaxFileSize()
    }
  },
  watch: {
    error (error) {
      if (error.code === 404) {
        this.$router.push({ name: 'links-index' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
