<template>
  <body>
    <div class="position-absolute d-flex flex-row roboto-font ">
      <div class="pl-2 pt-2 bd-highlight" v-if="nodes.length > 1">
        <!-- @mousedown.stop to suppress a Vue Warning: [TypeError: t.className.match is not a function]-->
        <b-button :pressed.sync="isHiddenLinkLabel" id='tooltip-toggleLinkLabel' size="sm" variant="outline-secondary" @mousedown.stop>
          <LaEyeSlash v-if="isHiddenLinkLabel" class="la"/>
          <LaEye v-else class="la"/>
          <b-tooltip target="tooltip-toggleLinkLabel" triggers="hover">{{$INSIGHT('MISC').COMMON_PHRASE.TOOLTIP_TOGGLE_LINK_LABEL}}</b-tooltip>
        </b-button>
      </div>
      <div class="pl-2 pt-2 bd-highlight" v-if="nodes.length > 2">
        <b-dropdown variant="outline-secondary" size="sm" :text="layoutBtnText">
          <b-dropdown-item v-for="layout in layouts" :key="layout.name" @click="applyLayout(layout)">{{layout.text}}</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="pl-2 pt-2 bd-highlight" v-if="nodes.length > 0">
        <b-dropdown id='dropdown-subscription' :variant="dropdownSubscriptionVariant" size="sm" v-if="isEntity">
          <template #button-content>
            <b-spinner small v-if="isSubscriptionLoading" :label="`${$LOCAL('COMMON_WORD').LOADING}...`"></b-spinner>
            <span v-else>{{ subscriptionLabelText }}</span>
          </template>
          <b-dropdown-item v-for="option in this.$INSIGHT('NOTIFICATION').ENTITY_SUBSCRIPTION_OPTIONS" :key="option.name" :id="option.name"
                           @click="subscription(option.name)">
            {{option.text}} <LaCheck v-if="isSubscribe[option.name]" class="subscription-status-icon"/>
            <b-tooltip :target="option.name" triggers="hover" placement="right">
              {{ isSubscribe[option.name] ? option.unsubscribeTooltipText : option.subscribeTooltipText }}
            </b-tooltip>
          </b-dropdown-item>
        </b-dropdown>
        <b-button :pressed="isSubscribe.is_data_change_subscription" id='tooltip-subscription' size="sm" variant="outline-secondary"
                  @click="subscription($INSIGHT('NOTIFICATION').SUBSCRIPTION_TYPES.IS_DATA_CHANGE_SUBSCRIPTION)" v-else>
          <b-spinner small v-if="isSubscriptionLoading" :label="`${$LOCAL('COMMON_WORD').LOADING}...`"></b-spinner>
          <span v-else>{{ subscriptionLabelText }}</span>
          <b-tooltip target="tooltip-subscription" triggers="hover">{{ subscriptionTooltipText }}</b-tooltip>
        </b-button>
      </div>
    </div>
    <div :class="{ 'cytoscape-overlay cursor-progress d-flex flex-column justify-content-center align-items-center': loading }">
      <b-spinner type="grow" variant="light" v-if="loading" />
    </div>
    <div id="cy" class="fade-in" role="img" aria-label="cytoscape-canvas"></div>
    <cytoscape-context-menu-modal
        :recordLabel="contextMenuModalRecordLabel"
        :showModal="showContextMenuModal"
        :contextMenuItems="contextMenuModalItems"
        @hide="hideContextMenuModalHandler"
        @menu-item-on-click="contextMenuModalItemOnClickHandler"
        >
    </cytoscape-context-menu-modal>
  </body>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isEmpty, cloneDeep } from 'lodash'
import ModelService from '../services/model.service'
import ToastMessage from '@/utils/toast_message'

import '@/modules/insight/components/cytoscape-context-menus.css'

import cytoscape from 'cytoscape'
import contextMenus from 'cytoscape-context-menus'
import fcose from 'cytoscape-fcose'
import klay from 'cytoscape-klay'
import dagre from 'cytoscape-dagre'
import LaEye from '@/assets/la-eye-grey.svg'
import LaEyeSlash from '@/assets/la-eye-slash-solid.svg'
import LaCheck from '@/assets/la-check.svg'
import CytoscapeContextMenuModal from '@/modules/insight/components/CytoscapeContextMenuModal'

cytoscape.use(fcose)
cytoscape.use(klay)
cytoscape.use(dagre)
cytoscape.use(contextMenus)

export default {
  name: 'Cytoscape',
  components: { LaEyeSlash, LaEye, LaCheck, CytoscapeContextMenuModal },
  props: {
    nodes: {
      type: Array,
      default: () => ([])
    },
    edges: {
      type: Array,
      default: () => ([])
    },
    currentRecordId: {
      default: false
    },
    expandedNodes: {
      default: () => ({})
    },
    isEntity: {
      default: true
    },
    primaryItemId: {
      default: false
    }
  },
  data () {
    var self = this
    return {
      loading: false,
      cyBounds: null,
      cyPrevBounds: null,
      layoutBtnText: 'Layout: Default',
      segregatedEntities: [],
      isHiddenLinkLabel: false,
      layouts: [
        { name: 'fcose', text: 'Default' },
        { name: 'circle', text: 'Peacock', customConfig: { spacingFactor: 2 } },
        { name: 'dagre', text: 'Hierarchical', customConfig: { spacingFactor: 2, rankDir: 'TB', nodeSep: false, edgeSep: false, ranker: 'longest-path', grid: true } }
        // { name: 'circle', text: 'Circular' },
        // { name: 'concentric', text: 'Concentric' },
        // { name: 'klay', text: 'Klay' },
        // { name: 'grid', text: 'Grid' }
      ],
      isSubscriptionLoading: false,
      currentSelectedElement: false,
      isSubscribe: {
        is_data_change_subscription: false,
        is_connection_update_subscription: false
      },
      defaultLayoutConfig: {
        name: 'fcose',
        fit: true,
        animate: true,
        padding: 30,
        randomize: true,
        nodeDimensionsIncludeLabels: true,
        nodeRepulsion: 4500,
        idealEdgeLength: 400,
        edgeElasticity: 5,
        nestingFactor: 0.1,
        gravity: 0.25,
        numIter: 2500,
        tile: true,
        tilingPaddingVertical: 10,
        tilingPaddingHorizontal: 10,
        gravityRangeCompound: 1.5,
        gravityCompound: 1.0,
        gravityRange: 3.8,
        initialEnergyOnIncremental: 0.3,
        avoidOverlap: true
      },
      customLayout: { name: 'fcose', text: 'Default' },
      contextMenuItems: [
        {
          id: 'collapseNode',
          content: 'Collapse',
          selector: 'node',
          onClickFunction: self.collapseNode,
          hasTrailingDivider: true
        },
        {
          id: 'expandNode',
          content: 'Expand',
          selector: 'node',
          onClickFunction: self.expandNode,
          hasTrailingDivider: true
        },
        {
          id: 'viewNode',
          content: 'View Details',
          onClickFunction: self.nodeViewButton,
          selector: 'node',
          hasTrailingDivider: true
        },
        {
          id: 'goToNodePage',
          content: 'Go To Entity',
          selector: 'node',
          hasTrailingDivider: true,
          onClickFunction: self.cyNodeRedirect
        },
        {
          id: 'viewEdge',
          content: 'View Details',
          tooltipText: 'View',
          selector: 'edge',
          hasTrailingDivider: true,
          onClickFunction: self.edgeViewButton
        },
        {
          id: 'goToEdgePage',
          content: 'Go To Link',
          tooltipText: 'Go To',
          selector: 'edge',
          hasTrailingDivider: true,
          onClickFunction: self.cyEdgeRedirect
        }
      ],
      showContextMenuModal: false,
      showContextMenuModalItems: {},
      contextMenuModalTriggerEvent: null,
      contextMenuModalItems: [],
      contextMenuModalRecordLabel: ''
    }
  },
  async mounted () {
    this.loadGraph()
    await this.loadSubscriptionStatus()
  },
  computed: {
    ...mapState('insight', {
      hideLinkLabel: 'hideLinkLabel'
    }),
    hasSubscription () {
      return this.isSubscribe.is_data_change_subscription || this.isSubscribe.is_connection_update_subscription
    },
    dropdownSubscriptionVariant () {
      return this.hasSubscription ? 'secondary' : 'outline-secondary'
    },
    subscriptionLabelText () {
      if (this.hasSubscription) {
        return this.$INSIGHT('NOTIFICATION').LABEL.WATCHING
      } else {
        return this.$INSIGHT('NOTIFICATION').LABEL.WATCH
      }
    },
    subscriptionTooltipText () {
      if (this.hasSubscription) {
        return this.$INSIGHT('NOTIFICATION').TOOLTIP.WATCHING
      } else {
        return this.$INSIGHT('NOTIFICATION').TOOLTIP.START_WATCHING
      }
    }
  },
  methods: {
    ...mapActions('insight', [
      'toggleHideLinkLabel'
    ]),
    ...mapActions('insight/notification', [
      'getSubscriptionStatus',
      'subscribe',
      'unsubscribe'
    ]),
    async loadSubscriptionStatus () {
      this.isSubscriptionLoading = true
      const responseData = await this.getSubscriptionStatus({ objectId: this.currentRecordId })
      this.isSubscribe.is_data_change_subscription = responseData.is_data_change_subscription
      this.isSubscribe.is_connection_update_subscription = responseData.is_connection_update_subscription
      this.isSubscriptionLoading = false
    },
    loadGraph (layout = 'fcose') {
      this.cy = cytoscape({
        container: document.getElementById('cy'),
        wheelSensitivity: 0.1,
        desktopTapThreshold: 4,
        touchTapThreshold: 8,
        maxZoom: 3.0,
        minZoom: 0.3,
        elements: {
          nodes: this.nodes,
          edges: this.edges
        },
        layout: {
          name: layout,
          fit: true,
          randomize: true,
          nodeDimensionsIncludeLabels: true,
          nodeRepulsion: 4500,
          idealEdgeLength: 400,
          edgeElasticity: 5,
          nestingFactor: 0.1,
          gravity: 0.25,
          numIter: 2500,
          tile: true,
          tilingPaddingVertical: 10,
          tilingPaddingHorizontal: 10,
          gravityRangeCompound: 1.5,
          gravityCompound: 1.0,
          gravityRange: 3.8,
          initialEnergyOnIncremental: 0.3
        },
        style: [
          {
            selector: 'node',
            style: {
              width: 'mapData(target_count,1,60,50,150)',
              height: 'mapData(target_count,1,60,50,150)',
              'background-color': '#fff',
              'border-color': '#313947',
              'border-width': 2,
              color: '#666',
              label: this.cyEntityLabel,
              'text-valign': 'bottom',
              'text-halign': 'center',
              'text-margin-y': 5,
              'text-background-color': '#f9f9f9',
              'text-background-shape': 'roundrectangle',
              'text-background-opacity': 1,
              'background-fit': 'cover'
            }
          },
          {
            selector: 'edge',
            style: {
              width: 'mapData(link_count,5,50,3,10)',
              'curve-style': 'bezier',
              'control-point-step-size': 40,
              // label: this.cyEdgeLabel,
              'text-rotation': 'autorotate',
              'text-margin-y': 0,
              'text-margin-x': -10,
              'line-color': 'data(colour)',
              'line-style': this.cyLineStyle,
              'overlay-padding': '3px',
              'mid-source-arrow-shape': this.cySourceArrow,
              'mid-source-arrow-color': 'data(colour)',
              'mid-source-arrow-fill': 'hollow',
              'mid-target-arrow-shape': this.cyTargetArrow,
              'mid-target-arrow-color': 'data(colour)',
              'mid-target-arrow-fill': 'hollow',
              'text-background-color': '#f9f9f9',
              'text-background-shape': 'round-rectangle',
              'text-background-opacity': 0.9,
              'text-background-padding': '5px',
              'text-border-opacity': 0.5,
              'text-border-color': '#313947',
              'text-border-style': 'solid',
              'text-border-width': this.cyEdgeLabelBorder
            }
          },
          {
            selector: ':parent',
            style: {
              'background-color': 'data(colour)',
              label: this.cyParentEntityLabel,
              'background-opacity': 0.4,
              'border-color': 'data(colour)',
              shape: 'roundrectangle'
            }
          },
          {
            selector: '.compoundNode',
            style: {
              shape: 'roundrectangle',
              width: 'mapData(target_count,1,60,50,150)',
              height: 'mapData(target_count,1,60,50,150)',
              'background-color': 'data(colour)',
              'background-opacity': 0.4,
              'border-color': 'data(colour)',
              'border-width': 2,
              color: '#666',
              label: this.cyEntityLabel,
              'text-valign': 'bottom',
              'text-halign': 'center',
              'text-margin-y': 5,
              'text-background-color': '#f9f9f9',
              'text-background-shape': 'roundrectangle',
              'text-background-opacity': 1,
              background: 'radial-gradient(ellipse at center,  #f73134 0%,#ff0000 47%,#ff0000 47%,#23bc2b 47%,#23bc2b 48%)'
            }
            // style: {
            //   'border-color': '#B0B0B0',
            //   'border-width': '1px',
            //   'border-style': 'solid',
            //   'background-color': '#FFF',
            //   'background-fit': 'none',
            //   'font-size': '12px',
            //   'text-max-width': '120px',
            //   'text-wrap': 'ellipsis',
            //   'text-events': 'yes',
            //   'text-margin-y': '5px',
            //   'min-zoomed-font-size': '7px',
            //   'text-halign': 'center',
            //   'text-valign': 'bottom',
            //   'background-image': ['https://js.cytoscape.org/img/cytoscape-logo.svg', 'https://upload.wikimedia.org/wikipedia/commons/6/6d/Windows_Settings_app_icon.png', 'https://www.flaticon.com/premium-icon/icons/svg/1688/1688124.svg'],
            //   'background-width': ['24px', '20px', '20px'],
            //   'background-height': ['24px', '20px', '20px'],
            //   'background-position-x': ['12px', '-15px', '40px'],
            //   'background-position-y': ['12px', '14px', '14px'],
            //   // 'background-clip': ['none', 'none', 'none'],
            //   'background-clip': 'none',
            //   width: '48px',
            //   height: '48px',
            //   padding: '0px'
            // }
          }
        ]
      })
      const width = this.cy.width() / 2
      const height = this.cy.height() / 2
      this.cy.pan({ x: width, y: height })
      this.iconsRendering(this.cy)
      this.cyEdgeLabel(this.edges)
      this.initialiseTriggers()
      this.isHiddenLinkLabel = this.hideLinkLabel
      this.initialiseContextMenus()
    },
    initialiseContextMenus () {
      this.contextMenus = this.cy.contextMenus({
        evtType: 'cxttap tap',
        menuItems: this.contextMenuItems
        /*
            In case we need submenu in the future,
            as default cytoscape-context-menu will embed submenu indicator icon file in src like this => src="assets/icon.svg"
            and it doesn't work unless we created a reverse proxy for it.
            so we need to manually assign a custom icon to set as an indicator icon. Need a more elegant svg importing tho.
        */
        // submenuIndicator: { src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>', width: 12, height: 12, x: 6, y: 4 }
      })

      // Explicitly listen to touchstart event on menu items to manually trigger click event
      // because cytoscape context menu only listen to click events but click events are not recognised by tablets
      const cxtMenuItems = document.getElementsByClassName('cy-context-menus-cxt-menuitem')
      for (const cxtMenuItem of cxtMenuItems) {
        cxtMenuItem.addEventListener('touchstart', cxtMenuItem.click)
      }
    },
    getEventDetailsToEmitFromEvent (event) {
      // This is used to pass around event details in a dictionary between components
      // because a javascript Event object cannot be saved into a variable for use later on.
      const eventObj = {
        data: event.target.data(),
        position: {
          x: event.position.x,
          y: event.position.y
        }
      }
      return eventObj
    },
    expandNode (event) {
      /*
      event parameter can either be a
      (1) cytoscape event via cytoscape-context-menus library or,
      (2) dictionary of details of cytoscape event via cytoscape-context-menu-modal
      The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
      does not support cytoscape-context-menus library
      */
      const eventDetails = this.contextMenus ? this.getEventDetailsToEmitFromEvent(event) : this.contextMenuModalTriggerEvent
      this.$emit('expandNode', eventDetails)
    },
    collapseNode (event) {
      /*
      event parameter can either be a
      (1) cytoscape event via cytoscape-context-menus library or,
      (2) dictionary of details of cytoscape event via cytoscape-context-menu-modal
      The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
      does not support cytoscape-context-menus library
      */
      const eventDetails = this.contextMenus ? this.getEventDetailsToEmitFromEvent(event) : this.contextMenuModalTriggerEvent
      this.$emit('collapseNode', eventDetails)
    },
    async addConnection (node1, node2, edge) {
      await this.cy.add([
        { group: 'nodes', data: node1, position: node1.position, classes: node1.classes },
        { group: 'nodes', data: node2, position: node2.position, classes: node2.classes },
        { group: 'edges', data: edge }
      ])
      if (this.customLayout.name !== 'fcose') this.applyLayout()
      this.cyEdgeLabel(this.edges) // Must call this so it will be same with another edge label
      this.iconsRendering(this.cy)
    },
    removeNode (nodeId) {
      const theId = this.cy.getElementById(nodeId)
      this.cy.remove(theId)
    },
    cyLineStyle (ele) {
      return ['solid', 'dashed', 'dotted'][ele.data('confidence')]
    },
    cySourceArrow (ele) {
      // If direction is 2 or 3
      return ([2, 3].indexOf(ele.data('direction')) !== -1) ? 'triangle' : 'none'
    },
    cyTargetArrow (ele) {
      // If direction is 1 or 3
      return ([1, 3].indexOf(ele.data('direction')) !== -1) ? 'triangle' : 'none'
    },
    cyEdgeLabelBorder (ele) {
      const edgeData = ele.data()
      if (edgeData.is_aggregated_edges) {
        return '2px'
      }
      return '0px'
    },
    cyEdgeLabel (edges) {
      for (const edge of edges) {
        if (this.hideLinkLabel) {
          if (typeof edge.data.is_aggregated_edges === 'undefined') {
            this.cy.$id(edge.data.id).style({ label: '' })
          } else {
            this.cy.$id(edge.data.id).style({ label: `${edge.data.link_count} ${edge.data.link_label}s` })
          }
        } else {
          if (typeof edge.data.is_aggregated_edges === 'undefined') {
            this.cy.$id(edge.data.id).style({ label: edge.data.label })
          } else {
            this.cy.$id(edge.data.id).style({ label: `${edge.data.link_count} ${edge.data.link_label}s` })
          }
        }
      }
    },
    cyEntityLabel (ele) {
      const entityData = ele.data()
      return entityData.label
    },
    cyParentEntityLabel (ele) {
      const data = ele.data()
      if (data.is_parent) {
        const label = ele.data()?.label || ele.data()?.id
        return `${label}s`
      }
    },
    async nodeViewButton (event) {
      /*
      event parameter can either be a
      (1) cytoscape event via cytoscape-context-menus library or,
      (2) dictionary of details of cytoscape event via cytoscape-context-menu-modal
      The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
      does not support cytoscape-context-menus library
      */
      const eventDetails = this.contextMenus ? this.getEventDetailsToEmitFromEvent(event) : this.contextMenuModalTriggerEvent

      const nodeData = eventDetails.data
      const isAggregatedNode = nodeData.is_aggregated_nodes || nodeData.is_parent
      const isPrimaryItem = nodeData.id === this.primaryItemId
      const emitAggregatedNodeAction = async () => {
        this.segregatedEntities = await ModelService.getSegregatedEntities(nodeData.source_type, nodeData.source, nodeData.link_id, nodeData.target_type_id)
        this.$emit('clicked_getSegregatedEntities', {
          segregated_entity_list: this.segregatedEntities,
          link_type_id: nodeData.link_id,
          target_type_id: nodeData.target_type_id,
          link_type_label: nodeData.link_label,
          link_count: nodeData.link_count
        })
        this.$emit('showPanelHeaderForSegregatedEntities', true)
      }

      if (isPrimaryItem) { // if is a primary item, view the primary item details
        this.$emit('viewPrimaryItemDetails', eventDetails)
        this.setNodeBolder(nodeData.id)
      } else if (isAggregatedNode) { // if is a aggregated node, view the segregated entities
        emitAggregatedNodeAction()
      } else {
        this.$emit('fetchChildNodeFields', eventDetails)
      }
    },
    edgeViewButton (event) {
      /*
      event parameter can either be a
      (1) cytoscape event via cytoscape-context-menus library or,
      (2) dictionary of details of cytoscape event via cytoscape-context-menu-modal
      The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
      does not support cytoscape-context-menus library
      */
      const eventDetails = this.contextMenus ? this.getEventDetailsToEmitFromEvent(event) : this.contextMenuModalTriggerEvent
      const edgeData = eventDetails.data
      const id = edgeData.id
      const isPrimaryItem = id === this.primaryItemId
      const isAggregatedEdges = edgeData.is_aggregated_edges
      this.setElementAsSelected(id, true)

      if (isAggregatedEdges) {
      } else if (isPrimaryItem) { // to view primary item detail
        this.$emit('viewPrimaryItemDetails', eventDetails)
      } else {
        this.$emit('fetchChildNodeFields', eventDetails)
      }
    },
    async cyNodeRedirect (event) {
      /*
      event parameter can either be a
      (1) cytoscape event via cytoscape-context-menus library or,
      (2) dictionary of details of cytoscape event via cytoscape-context-menu-modal
      The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
      does not support cytoscape-context-menus library
      */
      const eventDetails = this.contextMenus ? this.getEventDetailsToEmitFromEvent(event) : this.contextMenuModalTriggerEvent
      const nodeData = eventDetails.data
      const hasReadPermission = nodeData.has_read_permission
      if (hasReadPermission) {
        this.$router.push({ path: `/entities/${nodeData.type}/${nodeData.id}` })
      } else {
        this.restrictedItemToast('entity')
      }
    },
    async cyEdgeRedirect (event) {
      /*
      event parameter can either be a
      (1) cytoscape event via cytoscape-context-menus library or,
      (2) dictionary of details of cytoscape event via cytoscape-context-menu-modal
      The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
      does not support cytoscape-context-menus library
      */
      const eventDetails = this.contextMenus ? this.getEventDetailsToEmitFromEvent(event) : this.contextMenuModalTriggerEvent
      const edgeData = eventDetails.data
      const hasReadPermission = edgeData.has_read_permission
      if (hasReadPermission) {
        this.$router.push({ path: `/links/${edgeData.type}/${edgeData.id}` })
      } else {
        this.restrictedItemToast('link')
      }
    },
    restrictedItemToast (itemName) {
      this.$root.$bvToast.toast(`${this.$INSIGHT('READ').TOAST.PERMISSION_RESTRICTED.TEXT} ${itemName}`, {
        title: `${this.$INSIGHT('READ').TOAST.PERMISSION_RESTRICTED.TITLE}`,
        autoHideDelay: 5000,
        variant: 'danger',
        opacity: 1
      })
    },
    cySetPrevBounds (event) {
      this.cyPrevBounds = this.cy.elements().renderedBoundingBox()
    },
    cyCheckBounds (event) {
      const elementBounds = this.cy.elements().renderedBoundingBox()
      if (elementBounds.x2 < 0.1 * this.cyBounds.width ||
            elementBounds.x1 > 0.9 * this.cyBounds.width ||
            elementBounds.y2 < 0.1 * this.cyBounds.height ||
            elementBounds.y1 > 0.9 * this.cyBounds.height) {
        this.cy.animate(
          {
            panBy: {
              x: this.cyPrevBounds.x1 - elementBounds.x1,
              y: this.cyPrevBounds.y1 - elementBounds.y1
            }
          },
          {
            duration: 100
          }
        )
      }
    },
    initialiseTriggers () {
      // Bouncing Back
      this.cyBounds = {
        width: this.cy.width(),
        height: this.cy.height()
      }

      // Refer input events to this docs => https://js.cytoscape.org/#events/user-input-device-events
      this.cy.on('tapstart', this.cySetPrevBounds)
      this.cy.on('tapend', this.cyCheckBounds)
      // tap is needed for tablet device
      // cxttap : normalised right-click or two-finger tap
      // tap or vclick : normalised tap event (either click, or touchstart followed by touchend without touchmove)
      this.cy.on('cxttap tap', 'edge', this.inspectContextMenus)
      this.cy.on('cxttap tap', 'node', this.inspectContextMenus)
    },
    showContextMenuModalHandler (event) {
      // This function will trigger the context menu modal to be displayed
      this.contextMenuModalItems = this.contextMenuItems.filter(contextMenuItem => this.showContextMenuModalItems[contextMenuItem.id] === true)
      if (this.contextMenuModalItems.length > 0) {
        const eventDetails = this.getEventDetailsToEmitFromEvent(event)
        this.contextMenuModalTriggerEvent = eventDetails
        this.contextMenuModalRecordLabel = eventDetails.data.label ? eventDetails.data.label : eventDetails.data.id
        this.showContextMenuModal = true
      }
    },
    hideContextMenuModalHandler () {
      // This function will hide the context menu modal
      this.showContextMenuModal = false
      this.contextMenuModalTriggerEvent = null
      this.contextMenuModalRecordLabel = ''
    },
    contextMenuModalItemOnClickHandler (menuItem) {
      // This function will be called when an item in the modal has been clicked
      // The onClickFunction defined in the menuItem will be dynamically called to handle the action
      // that the user has clicked.
      menuItem.onClickFunction(this.contextMenuModalTriggerEvent)
      this.hideContextMenuModalHandler()
    },
    resetContextMenuModalItemsDisplayToggle () {
      // This function resets every key in this.showContextMenuModalItems to false
      // The keys in this.showContextMenuModalItems follows the id of this.contextMenuItems
      // Resetting to false means that there will be no items in the modal being displayed
      this.showContextMenuModalItems = {
        collapseNode: false,
        expandNode: false,
        viewNode: false,
        goToNodePage: false,
        viewEdge: false,
        goToEdgePage: false
      }
    },
    inspectContextMenus (event) {
      // this reset is to support cytoscape-context-menu-modal
      this.resetContextMenuModalItemsDisplayToggle()

      const data = event.target.data()

      const id = data.id
      const isCurrentRecordId = id === this.currentRecordId
      const isParent = data.is_parent
      const isItemPermitted = data.has_read_permission
      const isPrimaryItem = id === this.primaryItemId

      const showOrHide = (showMenu) => {
        return showMenu ? 'showMenuItem' : 'hideMenuItem'
      }
      const showViewAndGoToNode = (view, goTo) => {
        if (this.contextMenus) {
          // to support cytoscape-context-menus library
          this.contextMenus[showOrHide(view)]('viewNode')
          this.contextMenus[showOrHide(goTo)]('goToNodePage')
        } else {
          // to support cytoscape-context-menu-modal
          this.showContextMenuModalItems.viewNode = view
          this.showContextMenuModalItems.goToNodePage = goTo
        }
      }
      const showViewAndGoToEdge = (view, goTo) => {
        if (this.contextMenus) {
          // to support cytoscape-context-menus library
          this.contextMenus[showOrHide(view)]('viewEdge')
          this.contextMenus[showOrHide(goTo)]('goToEdgePage')
        } else {
          // to support cytoscape-context-menu-modal
          this.showContextMenuModalItems.viewEdge = view
          this.showContextMenuModalItems.goToEdgePage = goTo
        }
      }
      const showExpandAndCollapse = (showCollapse, showExpand) => {
        if (this.contextMenus) {
          // to support cytoscape-context-menus library
          this.contextMenus[showOrHide(showCollapse)]('expandNode')
          this.contextMenus[showOrHide(showExpand)]('collapseNode')
        } else {
          // to support cytoscape-context-menu-modal
          this.showContextMenuModalItems.expandNode = showCollapse
          this.showContextMenuModalItems.collapseNode = showExpand
        }
      }

      if (data.is_aggregated_edges) {
        /* if item is aggregated edge */
        showViewAndGoToEdge(false, false)
      } else if (data.is_aggregated_nodes) {
        /* if item is aggregated node (compound nodes) */
        showViewAndGoToNode(true, false)
        showExpandAndCollapse(false, false)
      } else if (isCurrentRecordId || isParent) {
        /* if item is a current record id */
        if (data.link_count === undefined) {
          // is node
          showViewAndGoToNode(true, false)
        } else {
          // is link
          showViewAndGoToEdge(true, false)
        }
        showExpandAndCollapse(false, false)
      } else if (!isItemPermitted && !isPrimaryItem) {
        /* if item is restricted */
        if (data.link_count === undefined) {
          // is node
          showViewAndGoToNode(true, false)
        } else {
          // is link
          showViewAndGoToEdge(true, false)
        }
        showExpandAndCollapse(false, false)
      } else if (data.link_count) {
        /* if item is an usual edge */
        showViewAndGoToEdge(true, true)
      } else {
        /* if item is an usual node
         Disable/Enable "Collapse" and "Expand" Menu */
        const nodeIsExpanded = !isEmpty(this.expandedNodes[id])
        showExpandAndCollapse(!nodeIsExpanded, nodeIsExpanded)
        showViewAndGoToNode(true, true)
      }

      if (!this.contextMenus) {
        /*
        The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
        does not support cytoscape-context-menus library (this.contextMenus will be undefined if
        it is not supported)
        */
        this.showContextMenuModalHandler(event)
      }
    },
    async iconsRendering (cytoscape) {
      const cache = {}
      for (const node of this.nodes) {
        if (!cache[node.data.type] && !node.data.is_parent) {
          const response = await ModelService.getIconImage(node.data.type)
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            cytoscape.style().selector(`node[type = "${node.data.type}" ]`)
              .style({
                'background-image': url,
                'background-fit': 'cover',
                'background-opacity': 0
              }).update()
          }
          cache[node.data.type] = true
        }
      }
    },
    // Should implement as mixin/independent
    async renderGraphReport (width) {
      // Strip points, but only works for 32 bit Ints (toInt)
      // Assumed to be correct
      const height = (width * this.cy.height() / this.cy.width()) | 0
      const density = 1.5
      const graphImage = await this.cy.jpg({
        // Dependent on current viewport
        full: false,
        // Prevent thread blocking
        output: 'blob-promise',
        // Better resolution
        scale: density
      })
      this.$emit('graphReportImage', graphImage, height)
    },
    async applyLayout (layout) {
      const savedLayout = layout || this.customLayout
      const clonedLayout = cloneDeep(this.defaultLayoutConfig)
      clonedLayout.name = savedLayout.name
      const appliedLayout = { ...clonedLayout, ...savedLayout.customConfig }
      this.cy.layout(appliedLayout).run()
      this.customLayout = savedLayout
      this.layoutBtnText = `Layout: ${savedLayout.text}`
      this.cy.center() // make the graph to be centered
    },
    async subscription (subscriptionType) {
      this.isSubscriptionLoading = true
      let response
      const newSubscription = !this.isSubscribe[subscriptionType]
      const requestData = { object_id: this.currentRecordId, [subscriptionType]: newSubscription }
      if (newSubscription) {
        response = await this.subscribe(requestData)
      } else {
        response = await this.unsubscribe(requestData)
      }

      // subscription response handler
      if (response.status === 200) {
        this.isSubscribe.is_data_change_subscription = response.data.is_data_change_subscription
        this.isSubscribe.is_connection_update_subscription = response.data.is_connection_update_subscription
      } else {
        ToastMessage.showErrorDefault({
          vueInstance: this,
          textMessage: this.$INSIGHT('NOTIFICATION').ERROR.GENERAL
        })
      }

      // add setTimeout for UX purpose only
      setTimeout(() => {
        this.isSubscriptionLoading = false
      }, 500)
    },
    setOverlayLoading (set) {
      const cyElement = document.getElementById('cy')
      if (set) {
        this.loading = true
        cyElement.classList.add('cursor-progress')
      } else {
        this.loading = false
        cyElement.classList.remove('cursor-progress')
      }
    },
    setNodeBolder (id, borderWidth = 5) {
      this.cy.$id(id).style({ 'border-width': borderWidth })
    },
    setElementAsSelected (id, isEdge = false) {
      const setStyle = (set) => {
        return {
          'background-color': set ? '#F5AC30' : null,
          'background-opacity': set ? 0.4 : null,
          'border-width': set ? 5 : null,
          'border-color': set ? '#F08521' : null,
          width: set && isEdge ? 7 : null
        }
      }
      this.cy.$id(this.currentSelectedElement).style(setStyle(false))
      this.cy.$id(id).style(setStyle(true))
      this.currentSelectedElement = id
    }
  },
  watch: {
    isHiddenLinkLabel: {
      handler: async function () {
        this.toggleHideLinkLabel({ isHidden: this.isHiddenLinkLabel })
        if (this.isHiddenLinkLabel) {
          for (const edge of this.edges) {
            if (typeof edge.data.is_aggregated_edges === 'undefined') {
              this.cy.$id(edge.data.id).style({ label: '' })
            }
          }
        } else {
          for (const edge of this.edges) {
            this.cy.$id(edge.data.id).style({ label: edge.data.label })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #cy {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1; // this is the magic property, make it 1 to be always on back
    // fix the wrong mouse shadow position
    canvas {
      top: 0;
      left: 0;
    }
  }

  .cytoscape-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999999;
  }

  .position-absolute {
    position: absolute;
    z-index: 999999;
  }

  .roboto-font {
    font-family: Roboto, sans-serif;
  }

  .view-link-icon {
    width: 21px;
    height: 21px;
  }

  .subscription-status-icon {
    fill: #28a745;
    margin-bottom: 5px;
  }
</style>
