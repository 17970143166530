<template>
  <div>
    <search-sort-controls
      class="search-and-sort-controls"
      :loading="loading"
      :showSortAndRefresh="recordsFetched"
      :hasClearSearchCross="false"
      :currentSentSearch="searchedText"
      :hasAdvancedSearch="false"
      :hasModelSelection="true"
      :initialModel="modelName"
      :models="filteredModels"
      :sortFields="records.fields ? records.fields : {}"
      :isForEntityOrLink="true"
      :searchCount="records.count ? records.count : 0"
      :searchNoun="$LOCAL('COMMON_WORD').RESULTS"
      @sort="sort"
      @changeModel="changeModel"
      @search="search"
      @refresh="sort"
    />
    <div class="results-area">
      <div v-if="loading && !recordsFetched" class="loader d-flex justify-content-center">
        <b-spinner variant="secondary" large label="Loading..."></b-spinner>
      </div>

      <div v-else class="results-body overflow-auto no-overflow-anchor">
        <index-cards
          :fields="records.fields"
          :items="records.results"
          :sortField="sortBy"
          :searchQuery="searchedText"
        >
          <template v-slot:name="{ item }">
            <router-link :to="{ name: 'entity', params: { id: item.unique_id, model: modelName } }">
              {{ displayName(item) }}
            </router-link>
          </template>
          <template v-slot:delete="{ item }">
            <b-button variant="success" @click="selectEntity(item)">Select</b-button>
          </template>
        </index-cards>

        <div
          v-if="recordsFetched && records.results.length < records.count"
          class="load-more d-flex justify-content-center"
        >
          <b-button class="load-more-button" variant="outline" @click="fetchRecords(true)">
            <b-badge pill variant="secondary" v-if="loading === false">{{$LOCAL('LoadMore')}}</b-badge>
            <b-spinner small v-if="loading === true" label="Loading"/>
          </b-button>
        </div>
      </div>

      <error-handler
        :error="displayError"
        :dismissible="false"
        :showError="showError"
        variant="danger"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import IndexCards from '@/modules/insight/components/IndexCards'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import HeaderLabelFormatterMixin from '@/modules/insight/mixins/HeaderLabelFormatterMixin'

export default {
  mixins: [HeaderLabelFormatterMixin],
  name: 'search-modal',
  components: {
    SearchSortControls,
    IndexCards,
    ErrorHandler
  },
  props: {
    modelChoices: {
      type: Object,
      default: () => ({})
    },
    restrictedItems: {
      type: Array,
      default: () => ([])
    },
    linkFormFieldError: { // Error from LinkFormField
      type: [Object, Boolean]
    }
  },
  data: () => ({
    loading: false,
    modelName: null,
    searchedText: '',
    page: 1,
    sortBy: ['-create_date', 'unique_id'],
    getRecordsError: {
      code: -1,
      message: ''
    }
  }),
  beforeMount () {
    this.modelName = Object.keys(this.modelChoices)[0]
  },
  computed: {
    ...mapState('insight', ['records', 'searchError']), // Need another error state in Vuex store to prevent error conflict
    recordsFetched () {
      return (typeof this.records === 'object')
    },
    showError () {
      if (!this.linkFormFieldError) return false
      return this.linkFormFieldError.message !== '' || this.getRecordsError.message !== ''
    },
    displayError () {
      if (this.getRecordsError.message !== '') { // Give precedence to searchError
        return this.getRecordsError
      } else if (this.linkFormFieldError.message !== '') {
        return this.linkFormFieldError
      } else {
        return {}
      }
    },
    filteredModels () {
      const filteredModels = {}
      for (const modelKey in this.modelChoices) {
        if (!this.restrictedItems.includes(modelKey)) {
          // Add in empty permissions to pass grantedModels check in SearchSortControls
          filteredModels[modelKey] = { ...this.modelChoices[modelKey], permissions: [] }
        }
      }
      return filteredModels
    }
  },
  watch: {
    searchError: {
      handler: function (newError) {
        if (newError !== false) {
          this.getRecordsError = {
            code: newError.code,
            message: newError.message
          }
        } else {
          this.getRecordsError = {
            code: -1,
            message: ''
          }
        }
      }
    },
    deep: true
  },
  methods: {
    ...mapActions('insight', ['getRecords', 'resetRecords']),
    displayName (record) {
      let labelString = this.records.meta.header_field
      labelString = this.labelFormatterOutput(record, labelString)
      return labelString
    },
    async changeModel (modelName) {
      this.modelName = modelName
      // Actually kind of redundant
      this.page = 1
      this.sortBy = ['-create_date', 'unique_id']
      this.searchedText = ''
      await this.resetRecords()
    },
    search (value) {
      this.searchedText = value
      this.fetchRecords(false)
    },
    async sort (sortBy) {
      // TODO Should refresh reset sortBy?
      if (sortBy instanceof Array) {
        this.sortBy = sortBy
      } else {
        await this.resetRecords()
      }
      // this.$refs.searchForm.resetSearch(this.searchedText)
      this.fetchRecords(false)
    },
    async fetchRecords (append) {
      if (this.loading) {
        return
      }

      if (!this.searchedText) {
        this.$bvToast.toast('Search term is required', {
          title: 'Search',
          autoHideDelay: 5000,
          variant: 'warning',
          appendToast: false
        })
        return
      }

      this.loading = true
      this.page = (append) ? this.page + 1 : 1

      // TODO Test if errors are written to the state
      await this.getRecords({
        model: this.modelName,
        params: {
          page: this.page,
          search: this.searchedText,
          perPage: 25,
          ordering: this.sortBy.join(',')
        },
        append
      })
      this.loading = false
    },
    async selectEntity (entity) {
      this.$emit('selectedEntity', entity, this.displayName(entity), this.modelName)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-and-sort-controls {
  padding: 0 14px;
}

.header {
  display: flex;
  margin: 10px 0px 10px 15px;
  justify-content: space-between;

  .results-summary {
    text-align: left;
    align-self: center;
    line-height: 1;
    font-size: 1.2em;
    font-weight: 400;
  }
}

.results-body {
  height: 55vh;
}
</style>
