<template>
  <div class="root">
    <portal to="top-bar">
      <div class="title" v-if="!error && hasInitialised">
        <!-- TODO JSON What about FE only consts? Such as path names -->
        <h1>
          <router-link :to="{ name: isEntity ? 'entities-index' : 'links-index', params: { model: $route.params.model } }" :aria-label="`${modelName} entity`">
            {{ modelName }}
          </router-link>
          {{ id }}
        </h1>
      </div>
      <div v-if="hasInitialised">
        <b-button-group size="sm">
          <b-btn v-if="!error && permissions.edit" variant="outline-success"
                 :to="{ name: isEntity? 'edit-entity': 'edit-link', params: { model: $route.params.model, id: $route.params.id } }">
            {{$LOCAL('COMMON_WORD').EDIT}}
          </b-btn>
          <b-btn class="create-link-dropdown" variant="outline-secondary" size="sm" v-if="(!error && graphImage === '') && permissions.download" @click="openReportGenerationModal">
            {{$INSIGHT('MISC').COMMON_PHRASE.EXPORT_REPORT}}
          </b-btn>
          <b-dropdown :disabled="loading" class="create-link-dropdown" variant="outline-primary" size="sm" v-if="isEntity && !error" text="Create Link">
            <b-dropdown-item
              v-if="!(Object.keys(allowedRelevantLinks).length)"
              disabled
              >{{$INSIGHT('MISC').COMMON_PHRASE.NO_AVAILABLE_LINKS}}</b-dropdown-item>
            <b-dropdown-item
              v-for="(value, link) in allowedRelevantLinks"
              :key="link"
              :value="link"
              :to="{ name: 'create-link', params: { model: link, presetEntity: record.model, entityType: $route.params.model } }">{{ value.label }}</b-dropdown-item>
          </b-dropdown>
          <b-btn v-if="!error && permissions.delete" @click="deleteThisRecord" variant="outline-danger">
            {{$LOCAL('COMMON_WORD').DELETE}}
          </b-btn>
          <b-btn v-if="error" class="ml-2" variant="outline-primary" @click="$router.go(-1)">
            {{$LOCAL('COMMON_WORD').BACK}}
          </b-btn>
        </b-button-group>
      </div>
    </portal>

    <error-handler
      v-if="error !== false && loading === false"
      :error="displayError"
      :dismissible="false"
      :showError="showError"
      variant="danger"
    />

    <div class="panes fade-in-fast" v-else-if="hasInitialised">
      <div class="loader mt-3" v-if="hasInitialised === false || graphLoaded === false && error === false">
        <b-spinner variant="secondary" large label="Loading..."></b-spinner>
      </div>
      <multipane class="custom-resizer" layout="vertical">
        <div v-bind:class="[showpanel ? pane : '', paneDefault]" :style="[showpanel ? {'width' : inactivePanel} : {'width' : activePanel}]">
          <cytoscape-area
            :key="graphElements.id"
            :currentRecordId="graphElements.id"
            :nodes="graphElements.nodes"
            :edges="graphElements.edges"
            :expandedNodes="expandedNodes"
            :isEntity="isEntity"
            :primaryItemId="id"
            @expandNode="expandNode"
            @collapseNode="collapseNode"
            @graphReportImage="setGraphImage"
            @clicked_getSegregatedEntities="onClickGetSegregatedEntities"
            @showPanelHeaderForSegregatedEntities="showPanelHeaderForSegregatedEntities"
            @fetchChildNodeFields="fetchChildNodeFields"
            @viewPrimaryItemDetails="backToPrimaryFields"
            ref="cytoscape"/>
          <delete-modal :item="modelName" :itemId="idToDelete" :modalShow="showDeleteModal"
                        @ok="deleteEntity" @hide="cancelDelete" ref="deleteModal">
          </delete-modal>
        </div>
        <multipane-resizer class="angleLeftIcon">
              <AngleLeft v-if="!showpanel" @click="showPanelWhenButtonIsToggled" class="angleContainer" @mousedown.stop/>
              <AngleRight v-else @click="showPanelWhenButtonIsToggled" class="angleContainer" @mousedown.stop/>
        </multipane-resizer>
        <div v-bind:class="[showpanel ? sidebar : '', sidebarHidden]" :style="[showpanel ? {'width' : activeWidth} : {'width' : inactiveWidth}]" @mousedown.stop>
          <b-card class="va-card overflow-auto">
            <template v-slot:header>
              <FieldListChoices
                :ref="$FORM('REFS').FIELD_LIST_CHOICES"
                :modelName="modelName"
                :modelId="id"
                :dynamicFormList="dynamicFormList"
                :showSegregatedEntitiesHeader="displayHeaderForSegregatedEntities"
                :childNodeFieldsHeader="childNodeFieldsHeader"
                :childNodeDFList="childNodeDFList"
                :mode="$FORM('MODE').VIEW_MODEL"
                :canChooseGenericForm="canAccessGenericForm"
                :initialFormName="initialFormName"
                :formLoading="formLoading"
                :disableFieldListChoices="disableFieldListChoices"
                @fetchDynamicForm="fetchAndSetDynamicForm"
                @loadGenericForm="fetchGenericForm"
                @switchOffSegregatedEntitiesView="onClickSwitchOffSegregatedEntitiesView"/>
            </template>
              <span v-if="formLoading" class="d-flex justify-content-center">
                <b-spinner size="sm" />
              </span>
              <span v-if="childNodeFields && !formLoading">
                <!-- THIS FIELD IS CONTAIN THE CHILD NODE INFORMATION -->
                <field-list
                v-if="childNodeIsGeneric"
                :fields="childNodeFields"
                @download="childNodeDownloadAttachment"
                :imagePreview="childNodeImagePreview"
                :imageLoading="childNodeFieldImageLoading"
                :attachmentError="attachmentError"
                :downloadAttachmentLoading="downloadAttachmentLoading" />
                <form-field
                  v-if="!childNodeIsGeneric"
                  :mode="$FORM('MODE').VIEW_MODEL"
                  :loading="loading"
                  :formData="childNodeDynamicFormData"
                  :imagePreview="childNodeImagePreview"
                  :imageLoading="childNodeFieldImageLoading"
                  :attachmentError="attachmentError"
                  :downloadAttachmentLoading="downloadAttachmentLoading"
                  :permissions="childNodePermissions.accessGenericForm"
                  :showBackToGenericViewButton="true"
                  @download="childNodeDownloadAttachment"
                  @toGenericView="childNodeSetToGenericView"/>
                <div class="mt-3 mb-3">
                  <b-button v-if="childNodeIsGeneric" @click="backToPrimaryFields" size="sm" pill variant="outline-secondary">
                    <Arrow class="arrow-left" />
                    {{$LOCAL('COMMON_WORD').BACK}}
                  </b-button>
                </div>
                <!-- END OF FIELD THAT CONTAIN THE CHILD NODE INFORMATION -->
              </span>
              <span v-else-if="!formLoading">
                <!-- ALL OF THIS FIELD IS USED FOR LOADING THE FORM AND GENERIC FORM OF THE PRIMARY RECORD -->
                <field-list
                  v-if="!dynamicFormData && !showSegregatedEntities && !formLoading"
                  :fields="fields"
                  @download="downloadAttachment"
                  :imagePreview="imagePreview"
                  :imageLoading="imageLoading"
                  :attachmentError="attachmentError"
                  :downloadAttachmentLoading="downloadAttachmentLoading" />
                <segregated-entities-list
                  v-else-if="showSegregatedEntities"
                  :segregatedEntities="segregatedEntitiesList"
                  :targetTypeId="targetTypeId"
                  :targetLinkTypeId="targetLinkTypeId"
                  :sourceTypeId="modelName"
                  :sourceTypeDisplayLabel="sourceNodeLabel"
                  :aggregatedLinkTypeLabel = "targetLinkTypeLabel"
                  :totalAggregatedLinkCount = "totalLinkCount"
                  @clicked_switchOffSegregatedEntitiesView="onClickSwitchOffSegregatedEntitiesView"
                  @fetchChildNodeFields="fetchChildNodeFields"
                />
                <form-field
                  v-else-if="dynamicFormData && !formLoading"
                  :mode="$FORM('MODE').VIEW_MODEL"
                  :loading="loading"
                  :formData="dynamicFormData"
                  :imagePreview="imagePreview"
                  :imageLoading="imageLoading"
                  :attachmentError="attachmentError"
                  :downloadAttachmentLoading="downloadAttachmentLoading"
                  :permissions="permissions"
                  :showBackToGenericViewButton="canAccessGenericForm"
                  @download="downloadAttachment"
                  @toGenericView="fetchGenericForm"/>
                </span>
          </b-card>
          <generate-report-modal :fields="fields"
                                 :modelShowReportGeneration="showGenerateReportModal"
                                 @hide="closeReportGenerationModal"
                                 @clickedPDF="downloadPdf"
                                 @clickedCSV="downloadCSV"></generate-report-modal>
        </div>
      </multipane>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Multipane, MultipaneResizer } from 'vue-multipane'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import DeleteModal from '@/modules/insight/components/DeleteModal'
import FieldList from '@/modules/insight/components/FieldList'
import GenerateReportModal from '@/modules/insight/components/GenerateReportModal'
import DownloadReportMixin from '@/modules/insight/mixins/DownloadReportMixin'
import DynamicFormMixin from '@/modules/insight/mixins/DynamicFormMixin'
import GraphMixin from '@/modules/insight/mixins/GraphMixin'
import FieldListChoices from '@/modules/insight/components/FieldListChoices'
import FormField from '@/modules/forms/components/FormField'
import SegregatedEntitiesList from '@/modules/insight/components/SegregatedEntitiesList'
import AngleLeft from '@/assets/angle-left-solid.svg'
import AngleRight from '@/assets/angle-right-solid.svg'
import CytoscapeArea from '@/modules/insight/components/CytoscapeArea'
import { isEmpty, remove, find } from 'lodash'

import ModelService from '@/modules/insight/services/model.service'
import { setValues } from '@/modules/insight/utils'
import ToastMessage from '@/utils/toast_message'
import Arrow from '@/assets/angle-up-solid.svg'

export default {
  name: 'entity-link-show',
  mixins: [DownloadReportMixin, DynamicFormMixin, GraphMixin],
  components: {
    ErrorHandler, DeleteModal, CytoscapeArea, Multipane, MultipaneResizer, FieldList, GenerateReportModal, FieldListChoices, FormField, SegregatedEntitiesList, AngleLeft, AngleRight, Arrow
  },
  props: {
    itemType: {
      type: String
    }
  },
  data: () => ({
    resizingPanel: {
      inactivePanelWidth: '70%',
      activePanelWidth: '99.9%',
      inactiveWidthBar: '0.1%',
      activeWidthBar: '30%'
    },
    pane: 'pane',
    paneDefault: 'paneDefault',
    sidebarHidden: 'sidebarHidden',
    sidebar: 'sidebar',
    showpanel: true,
    show: true,
    loading: false,
    relevantLinks: false,
    allowedRelevantLinks: {},
    idToDelete: '',
    showDeleteModal: false,
    showGenerateReportModal: false,
    recordDeleted: false,
    graphElements: {
      id: '',
      nodes: [],
      edges: []
    },
    expandedNodes: {},
    graphImage: '',
    graphImageBounds: {
      // Defaults
      width: 182,
      height: 150
    },
    permissions: {
      read: false,
      edit: false,
      delete: false,
      download: false,
      accessGenericForm: true
    },
    imagePreview: {},
    imageLoading: false,
    attachmentError: false,
    downloadAttachmentLoading: false,
    displayError: {
      code: -1,
      message: ''
    },
    showError: false,
    segregatedEntitiesList: {},
    showSegregatedEntities: false,
    displayHeaderForSegregatedEntities: false,
    targetLinkTypeId: '',
    targetTypeId: '',
    targetLinkTypeLabel: '',
    sourceNodeLabel: '',
    totalLinkCount: 0,
    defaultFormId: false,
    formIdOrderIsAscending: true,
    canAccessGenericForm: true,
    initialFormName: false,
    childNodeCurrentItemData: false,
    childNodeCurrentRecordId: false,
    childNodeCurrentModel: false,
    childNodeFields: false,
    childNodeFieldsHeader: false,
    childNodeImagePreview: {},
    childNodeFieldImageLoading: false,
    childNodeDFList: false,
    childNodeIsGeneric: true,
    childNodePermissions: {
      read: false,
      edit: false,
      delete: false,
      download: false,
      accessGenericForm: true
    },
    disableFieldListChoices: false
  }),
  computed: {
    ...mapState('insight', {
      entities: 'entities',
      links: 'links',
      record: 'record',
      error: 'error'
    }),
    modelName () {
      const listOfModels = this.isEntity ? this.entities : this.links
      const paramModel = this.$route?.params?.model
      const modelName = listOfModels?.[paramModel]?.label
      return (this.record === false) ? modelName : this.record.model.name
    },
    hasInitialised () {
      for (const index in this.record) {
        return true
      }
      return false
    },
    inactivePanel () {
      return this.resizingPanel.inactivePanelWidth
    },
    activePanel () {
      return this.resizingPanel.activePanelWidth
    },
    activeWidth () {
      return this.resizingPanel.activeWidthBar
    },
    inactiveWidth () {
      return this.resizingPanel.inactiveWidthBar
    },
    graphLoaded () {
      return this.graphElements.id !== ''
    },
    id () {
      return (this.record === false) ? null : this.record.model.id
    },
    fields () {
      return (this.record === false) ? [] : this.record.fields
    },
    isEntity () {
      return this.itemType === this.$INSIGHT('MODEL_TYPES').ENTITY
    },
    modelList () {
      return this.isEntity ? this.entities : this.links
    },
    modelType () {
      return this.$route.params.model
    },
    pathPrefix () {
      return this.isEntity ? '/entities/' : '/links/'
    },
    entityOrLink () {
      return this.isEntity ? 'Entity' : 'Link'
    }
  },
  async mounted () {
    await this.checkPermissions()
    await this.checkGenericFormAndDefaultForm()
  },
  methods: {
    ...mapActions('insight', [
      'deleteRecord',
      'getLinks',
      'getEntities',
      'getRecord',
      'getRecordForDynamicForm',
      'getAttachment',
      'getRecordConnections',
      'getRelevantLinks',
      'setSearchTerm',
      'resetFields'
    ]),
    setError (code, message) {
      this.showError = true
      this.displayError = {
        code: code,
        message: message
      }
    },
    async fetch () {
      if (this.loading === true) return
      this.loading = true

      const isSuccessful = this.defaultFormId ? await this.getRecordForDynamicForm({ modelId: this.$route.params.id, formId: this.defaultFormId }) : await this.getRecord({ model: this.modelType, id: this.$route.params.id })
      // const isSuccessful = await this.getRecord({ model: this.modelType, id: this.$route.params.id })
      if (this.record && isSuccessful) {
        if (this.isEntity && this.modelType) {
          const recordConnections = await this.getRecordConnections({ model: this.modelType, id: this.$route.params.id })
          this.relevantLinks = await this.getRelevantLinks({ model: this.modelType })
          for (const index in this.relevantLinks) {
            const permissions = this.relevantLinks[index].permissions
            if (permissions && !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').CREATE))) {
              this.allowedRelevantLinks[index] = this.relevantLinks[index]
            }
          }
          this.graphElements.id = recordConnections.model.id
          // Hijack map to set label

          this.graphElements.nodes = recordConnections.entities.map(node => {
            if (node.id === this.record.model.id) {
              this.record.model.label = node.label
              this.sourceNodeLabel = node.label
            }
            // Adding a target_count to control the size of the node.
            node.target_count = 1
            return { data: node }
          })
          this.graphElements.edges = recordConnections.links.map(edge => {
            edge.link_count = 1
            return { data: edge }
          })

          const aggregatedEdges = recordConnections.aggregated
          for (const aggregatedEdge of aggregatedEdges) {
            // this.graphElements.nodes.push({
            //   // the following dictionary contains the data needed to generate the aggregated nodes and edges on Cytoscape
            //   // id: unique node id required by Cytoscape
            //   // label, target_count: label to display on Cytoscape
            //   // source, source_type, target_type_id, link_id: required by service getSegregatedEntities
            //   // is_aggregated_nodes: variable to differentiate between a single node and aggregated nodes.
            //   // parent: this is to display the aggregated nodes as a compound node (the box) on the graph. Required by Cytoscape
            //   data: {
            //     id: aggregatedEdge.target_type,
            //     label: aggregatedEdge.target_type,
            //     type: aggregatedEdge.target_type,
            //     source: aggregatedEdge.source_id,
            //     source_type: aggregatedEdge.source_type,
            //     target_type_id: aggregatedEdge.target_type_id,
            //     target_count: aggregatedEdge.aggregated_entity_count,
            //     is_aggregated_nodes: true,
            //     link_id: aggregatedEdge.link_type,
            //     link_label: aggregatedEdge.link_label,
            //     link_count: aggregatedEdge.aggregated_link_count,
            //     parent: `${aggregatedEdge.aggregated_entity_count} ${aggregatedEdge.target_label}`
            //   }
            // })

            // // This is for the parent node that group the aggregated nodes into The Box.
            // this.graphElements.nodes.push({
            //   data: {
            //     id: `${aggregatedEdge.aggregated_entity_count} ${aggregatedEdge.target_label}`,
            //     colour: aggregatedEdge.link_colour,
            //     is_parent: true,
            //     target_count: aggregatedEdge.aggregated_entity_count,
            //     source_type: aggregatedEdge.source_type,
            //     source: aggregatedEdge.source_id,
            //     link_id: aggregatedEdge.link_type,
            //     target_type_id: aggregatedEdge.target_type_id,
            //     link_label: aggregatedEdge.link_label,
            //     link_count: aggregatedEdge.aggregated_link_count
            //   }
            // })
            // this.graphElements.edges.push({
            //   data: {
            //     id: aggregatedEdge.link_type,
            //     link_label: aggregatedEdge.link_label,
            //     link_count: aggregatedEdge.aggregated_link_count,
            //     source: aggregatedEdge.source_id,
            //     target: `${aggregatedEdge.aggregated_entity_count} ${aggregatedEdge.target_label}`,
            //     is_aggregated_edges: true,
            //     colour: aggregatedEdge.link_colour,
            //     confidence: 0
            //   }
            // })

            const aggregatedData = this.getAggregatedNodeEdgeFormatForCytoscape(aggregatedEdge)
            // compoundNode contains a number of child nodes
            aggregatedData.node.classes = 'compoundNode'
            this.graphElements.nodes.push(aggregatedData.node)
            this.graphElements.edges.push(aggregatedData.edge)
          }
        } else {
          this.graphElements.id = this.record.model.id
          this.graphElements.nodes = this.record.linkend.entity_details.map(node => {
            node.weight = 50
            return { data: node }
          })
          this.record.linkend.fields.forEach(item => {
            this.record.linkend.meta[item.name] = item.value
            this.record.linkend.meta.link_count = 1
          })
          this.graphElements.edges = [{ data: this.record.linkend.meta }]
        }
        for (const item of this.record.fields) {
          if (item.type === this.$INSIGHT('INPUT_TYPES').IMAGE) {
            this.imageLoading = true
            const response = await this.getAttachment({ model: this.modelType, id: this.$route.params.id, field: item.name })
            this.imageLoading = false
            if (response.status) {
              // status 204 to indicate No-content return.
              if (response.status !== 204) {
                const imageBlob = new Blob([response.data], { type: response.data.type })
                const reader = new FileReader()
                // Defined for "this" variable inner function reference
                const thisRef = this
                // Read the file as data and convert it into base64 for image display in another tab
                await new Promise((resolve, reject) => {
                  reader.readAsDataURL(imageBlob)
                  reader.onload = () => resolve(thisRef.$set(thisRef.imagePreview, item.name, reader.result))
                  reader.onerror = reject
                })

                // For download
                this.embeddedImages[item.label] = imageBlob.slice(0, imageBlob.size, response.data.type)
              }
            } else {
              this.attachmentError = true
            }
          }
        }
        await this.checkDynamicForm()
      }
      this.setPrimaryNodeBolder()
      this.loading = false
      this.formLoading = false
    },
    async fetchChildNodeFields (event, onlyFetchGenericForm) {
      await this.fetchEntitiesOrLinks() // call this to fetch entities or links list (if entities/links are not fetched yet)

      /*
      * Notes:
      * (1) event.data is triggered when user clicks View Detail on a segregated node/link on the tactical visualization graph.
      * This event is emitted from cytoscape-area
      * (2) event.target.data() is triggered when user clicks View Detail of a node/link in the sidebar of the tactical visualization graph.
      * This event is emitted from segregated-entities-list
      */
      const data = onlyFetchGenericForm ? this.childNodeCurrentData : (event.data || event.target.data())

      const model = data?.type
      const id = data?.id
      const modelPermissions = await this.checkModelPermissions(model)

      const modelInformation = this.entities[model] || this.links[model]
      const canReadPermission = modelPermissions?.read
      const canAccessGenericForm = modelPermissions?.accessGenericForm
      const isEntity = !isEmpty(this.entities[model])
      const isEntityOrLink = isEntity ? 'entity' : 'link'

      this.canAccessGenericForm = canAccessGenericForm
      this.$refs[this.$FORM('REFS').FIELD_LIST_CHOICES].show = false // in case it still opened

      const restrictedItem = () => {
        this.formLoading = false
        this.callNoReadPermissionToast(isEntityOrLink)
      }

      const noAssignedForm = () => {
        this.formLoading = false
        this.noAssignedFormToast(`${modelInformation.label} ${id}`)
      }

      if (canReadPermission) {
        // store current record id and model name
        const modelLabel = this.entities[model]?.label || this.links[model]?.label

        // getting child node's dynamic form list
        const dfList = await this.fetchDFList(model)
        const defaultFormId = modelInformation?.default_form_id
        const priorityFormId = defaultFormId || Object.keys(dfList)[0] // priority form id means the first form id that can be fetched, prioritise gettin it from default_form_id
        let rawChildNodeFields

        // if fetching the childNodeFields Success, then set all the value, called in validation below after this function
        const successFetching = async (fields, isGenericForm) => {
          await this.clearChildNodeFields()
          this.childNodeCurrentData = data
          this.childNodePermissions = modelPermissions
          this.setElementAsSelected(id, !isEntity)
          this.childNodeFields = setValues(fields, isGenericForm)
          this.childNodeIsGeneric = isGenericForm
          this.childNodeCurrentRecordId = id
          this.childNodeCurrentModel = model
          this.childNodeDFList = dfList
          this.childNodeFieldsHeader = `${this.$INSIGHT('VISUALISATION').CHILD_EXTENSION.INFORMATION_OF} : ${modelLabel} ${id}`
          this.disableFieldListChoices = isEmpty(this.childNodeDFList) // disable if dfList is Empty
          const imagePreviewField = find(this.childNodeFields, ['type', 'image'])
          this.setImagePreviewField(model, id, 'childNodeImagePreview', imagePreviewField)
          if (!isGenericForm) {
            await this.fetchDFChildNode(priorityFormId)
          }
        }

        // The condition validation
        const isHaveAssignedForm = (!canAccessGenericForm && priorityFormId)
        if ((defaultFormId || isHaveAssignedForm) && !onlyFetchGenericForm) {
          this.formLoading = true
          rawChildNodeFields = await ModelService.getRecordForDynamicForm(id, priorityFormId)
          successFetching(rawChildNodeFields.model_fields, false)
        } else if (canAccessGenericForm) {
          this.formLoading = true
          rawChildNodeFields = await ModelService.getRecord(model, id)
          successFetching(rawChildNodeFields.fields, true)
        } else {
          noAssignedForm()
        }
      } else {
        restrictedItem()
      }
      this.formLoading = false
    },
    async checkModelPermissions (model) {
      const modelInformation = this.entities[model] || this.links[model]
      const permissions = {
        read: false,
        edit: false,
        delete: false,
        download: false,
        accessGenericForm: false
      }
      if (modelInformation) {
        const modelPermissions = modelInformation.permissions
        permissions.read = !modelPermissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ))
        permissions.edit = !modelPermissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').UPDATE))
        permissions.delete = !modelPermissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DELETE))
        permissions.download = !modelPermissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DOWNLOAD))
        permissions.accessGenericForm = !modelPermissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').ACCESS_GENERIC_FORM))
      }
      return permissions
    },
    clearChildNodeFields () {
      this.childNodeCurrentModel = false
      this.childNodeCurrentRecordId = false
      this.childNodeFields = false
      this.childNodeFieldsHeader = false
      this.disableFieldListChoices = false
      this.childNodeDFList = false
    },
    deleteThisRecord () {
      if (this.id) {
        this.idToDelete = this.id
        this.showDeleteModal = true
      }
    },
    async deleteEntity () {
      const deleteProcess = await this.deleteRecord({ model: this.modelType, id: this.idToDelete })
      this.$refs.deleteModal.recordDeleted(deleteProcess)
      deleteProcess ? this.$router.push({ path: this.pathPrefix + this.modelType }) : this.fetch()
    },
    cancelDelete () {
      this.showDeleteModal = false
    },
    closeReportGenerationModal () {
      this.showGenerateReportModal = false
    },
    openReportGenerationModal () {
      this.showGenerateReportModal = true
    },
    childNodeDownloadAttachment (field) {
      const modelType = this.childNodeCurrentModel
      const id = this.childNodeCurrentRecordId
      this.downloadAttachment(field, modelType, id)
    },
    async downloadAttachment (field, modelType, id) {
      this.downloadAttachmentLoading = true
      const theModelType = modelType || this.modelType
      const theId = id || this.id
      const response = await this.getAttachment({ model: theModelType, id: theId, field: field.name })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const filename = response.headers['content-disposition'].match(/filename="(.*?)"/)
      link.setAttribute('download', filename[1])
      this.downloadAttachmentLoading = false
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url)
      }, 100)
      link.remove()
    },
    async downloadPdf (withChart = true, selectedFields = []) {
      if (this.permissions.download) {
        this.graphImage = 'loading'
        if (withChart) {
          await this.$refs.cytoscape.renderGraphReport(this.graphImageBounds.width)
        }
        // TODO Add try/catch, failure send toast
        try {
          await this.generatePdf(
            withChart,
            this.graphImageBounds.width,
            this.graphImageBounds.height,
            `${this.modelType} : ${this.id}`,
            `${this.modelType}_${this.id}`, selectedFields
          )
          this.graphImage = ''
        } catch (e) {
          this.$root.$bvToast.toast('Unable to download report.', {
            title: 'Error',
            autoHideDelay: 5000,
            variant: 'danger',
            opacity: 1
          })
        }
      }
    },
    async downloadCSV (selectedFields = []) {
      await this.generateCSVFile(selectedFields, `${this.modelType}_${this.id}`)
    },
    setGraphImage (blob, height) {
      this.graphImage = blob
      this.graphImageBounds.height = height
    },
    async checkPermissions () {
      const response = this.isEntity ? await this.getEntities() : await this.getLinks()
      if (response === true && (this.modelList[this.modelType] && this.modelList[this.modelType].permissions)) {
        const permissions = this.modelList[this.modelType].permissions
        this.permissions.read = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ))
        this.permissions.edit = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').UPDATE))
        this.permissions.delete = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DELETE))
        this.permissions.download = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DOWNLOAD))
        this.permissions.accessGenericForm = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').ACCESS_GENERIC_FORM))
      }
    },
    onClickGetSegregatedEntities (getSegregatedEntitiesResponse) {
      this.segregatedEntitiesList = getSegregatedEntitiesResponse.segregated_entity_list
      this.targetLinkTypeId = getSegregatedEntitiesResponse.link_type_id
      this.targetTypeId = getSegregatedEntitiesResponse.target_type_id
      this.targetLinkTypeLabel = getSegregatedEntitiesResponse.link_type_label
      this.totalLinkCount = getSegregatedEntitiesResponse.link_count
      this.showSegregatedEntities = true
    },
    onClickSwitchOffSegregatedEntitiesView (switchOff) {
      this.showSegregatedEntities = switchOff
      this.displayHeaderForSegregatedEntities = false
      this.disableFieldListChoices = false
    },
    showPanelHeaderForSegregatedEntities () {
      this.clearChildNodeFields()
      this.displayHeaderForSegregatedEntities = true
      this.disableFieldListChoices = true
    },
    showPanelWhenButtonIsToggled () {
      this.showpanel = !this.showpanel
    },
    async checkGenericFormAndDefaultForm () {
      const items = this.isEntity ? this.entities : this.links
      const model = this.$route.params.model
      const defaultFormId = items[model]?.default_form_id
      const routeIsEntityGeneric = this.$route.name === 'entity'
      const routeIsLinkGeneric = this.$route.name === 'link'
      const routeIsGeneric = routeIsEntityGeneric || routeIsLinkGeneric
      await this.fetchDynamicFormList(model)
      await this.setCanAccessGenericForm()

      const canAccessGenericForm = this.permissions.accessGenericForm
      const noFormAssigned = isEmpty(this.dynamicFormList)

      const hasDefaultForm = defaultFormId && routeIsGeneric
      const cannotAccessGenericFormAndNoFormAssigned = !canAccessGenericForm && noFormAssigned
      const cannotAccessGenericFormAndNoDefaultForm = !canAccessGenericForm && !defaultFormId

      if (hasDefaultForm) {
        this.defaultFormId = defaultFormId
      } else if (cannotAccessGenericFormAndNoFormAssigned) {
        const html = this.$createElement
        const modelName = this.modelName
        const modelNameHTML = `<strong>${modelName}</strong>`
        const modalTitle = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.TITLE
        const rawText = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.TEXT
        const replaceParam = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.REPLACE_PARAM
        const okButtonVariant = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.OKBUTTON_VARIANT
        const textMessage = rawText.replace(replaceParam, modelNameHTML)
        const messageHtml = html('div', {
          domProps: { innerHTML: textMessage }
        })
        this.$root.$bvModal.msgBoxOk([messageHtml], {
          title: modalTitle,
          okVariant: okButtonVariant
        })
        this.$router.back()
        return
      } else if (cannotAccessGenericFormAndNoDefaultForm) {
        const ascendingOrder = this.formIdOrderIsAscending
        const formListLength = Object.keys(this.dynamicFormList).length
        const firstFormId = Object.keys(this.dynamicFormList)[0]
        const lastFormId = Object.keys(this.dynamicFormList)[formListLength - 1]
        this.defaultFormId = ascendingOrder ? firstFormId : lastFormId
      }
      this.fetch()
    },
    async checkDynamicForm () {
      await this.setDynamicFormSettings(true, this.fields, this.permissions.accessGenericForm)
      if (this.defaultFormId) {
        const formId = this.$route.params.formId || this.defaultFormId
        await this.fetchDynamicForm(formId)
        this.initialFormName = `Form | ${this.dynamicFormList[formId].name}`
      }
    },
    /*
      This Method is used for set the PRIMARY Node Permission
      of Generic Form Access
    */
    setCanAccessGenericForm () {
      const canAccessGenericForm = this.permissions.accessGenericForm
      this.canAccessGenericForm = canAccessGenericForm
    },
    async fetchGenericForm () {
      if (this.childNodeFields) {
        this.childNodeSetToGenericView()
      } else {
        this.defaultFormId = false
        this.formLoading = true
        await this.fetch()
        await this.loadGenericForm()
        this.formLoading = false
      }
    },
    fetchAndSetDynamicForm (formId) {
      if (this.childNodeFields) {
        this.fetchDFChildNode(formId)
      } else {
        this.formLoading = true
        this.defaultFormId = formId // TODO: in the future change defaultFormId to formId
        this.fetch() // TODO: in the future, Fetching Dynamic Form record Data should use only fetch() method.
      }
    },
    async fetchEntitiesOrLinks () {
      // This method is used if entities or links are not fetched yet
      const linksAreEmpty = isEmpty(this.links)
      const entitiesAreEmpty = isEmpty(this.entities)
      if (linksAreEmpty || entitiesAreEmpty) {
        linksAreEmpty ? await this.getLinks() : await this.getEntities()
      }
    },
    getAggregatedNodeEdgeFormatForCytoscape (aggregatedInfo) {
      /*
      This method is for tabulating the aggregated data properties for Cytoscape.
      The id will have to be unique.
      The node id uses target_type and source_id.
      The edge id uses link_type, source_id and target_type.
      */
      const nodeEdgeData = {}
      const node = {
        data: {
          id: `${aggregatedInfo.target_type} - ${aggregatedInfo.source_id}`,
          label: `${aggregatedInfo.aggregated_entity_count} ${aggregatedInfo.target_label}`,
          type: aggregatedInfo.target_type,
          source: aggregatedInfo.source_id,
          source_type: aggregatedInfo.source_type,
          target_type_id: aggregatedInfo.target_type_id,
          target_count: aggregatedInfo.aggregated_entity_count,
          link_id: aggregatedInfo.link_type,
          link_label: aggregatedInfo.link_label,
          link_count: aggregatedInfo.aggregated_link_count,
          is_aggregated_nodes: true,
          colour: aggregatedInfo.link_colour
        }
      }

      const edge = {
        data: {
          id: `${aggregatedInfo.link_type} - ${aggregatedInfo.source_id} - ${aggregatedInfo.target_type}`,
          link_label: aggregatedInfo.link_label,
          label: `${aggregatedInfo.link_label}`,
          link_count: aggregatedInfo.aggregated_link_count,
          source: aggregatedInfo.source_id,
          target: `${aggregatedInfo.target_type} - ${aggregatedInfo.source_id}`,
          colour: aggregatedInfo.link_colour,
          is_aggregated_edges: true,
          confidence: 0
        }
      }
      nodeEdgeData.node = node
      nodeEdgeData.edge = edge
      return nodeEdgeData
    },
    async expandNode (event) {
      // make the cytoscape area disabled
      this.$refs.cytoscape.setOverlayLoading(true)

      // data variable
      const data = event.data
      const recordType = data.type
      const recordTypeLabel = this.entities[recordType]?.label || this.links[recordType]?.label
      const recordId = data.id
      this.expandedNodes[recordId] = []

      // get node position
      const positionX = event.position.x
      const positionY = event.position.y

      // calculate random position
      const randomPosition = () => {
        const xIsNegative = Math.sign(positionX) < 0
        const yIsNegative = Math.sign(positionY) < 0
        const randomNumber = () => {
          const max = 500
          const min = 250
          return (Math.random() * (max - min) + min)
        }
        const x = positionX + (xIsNegative ? (-randomNumber(800, 50)) : randomNumber(800, 50))
        const y = positionY + (yIsNegative ? (-randomNumber(50, 50)) : randomNumber(50, 50))
        return { x, y }
      }

      // getting record connections
      const recordConnections = await this.getRecordConnections({ model: recordType, id: recordId })
      const isHaveMoreExtensions = await this.isHaveMoreExtensions(recordConnections)

      if (!isHaveMoreExtensions) {
        ToastMessage.showWarningDefault({ vueInstance: this, name: `${recordTypeLabel} ${recordId}`, textMessage: this.$INSIGHT('ERROR').VISUALISATION.DONT_HAVE_EXTENSIONS })
      } else {
        // Input aggregatedNodes (the parent box) to this.graphElements and this.expandedNodes
        const aggregatedConnections = recordConnections.aggregated
        aggregatedConnections.forEach(connection => {
          // compoundNode contains a number of child nodes
          const aggregatedData = this.getAggregatedNodeEdgeFormatForCytoscape(connection)
          aggregatedData.node.data.classes = 'compoundNode'
          recordConnections.entities.push(aggregatedData.node.data)
          recordConnections.links.push(aggregatedData.edge.data)
        })

        // Input Nodes to this.graphElements and this.expandedNodes, so we know which one is the initial data and expanded data
        const nodeConnections = recordConnections.entities.map(node => {
          node.target_count = node?.target_count || 1
          return { data: node }
        })
        nodeConnections.forEach(newNode => {
          const existsInGraphElements = this.graphElements.nodes.find(node => {
            return node.data.id === newNode.data.id
          })
          if (!existsInGraphElements) {
            this.expandedNodes[recordId].push(newNode)
            this.graphElements.nodes.push(newNode)
          }
        })

        // create a dictionary of nodes before input it to addConnection
        const expandNodes = {}
        recordConnections.entities.forEach(entity => {
          entity.position = randomPosition()
          expandNodes[entity.id] = entity
        })

        // Add node connection
        const expandEdges = recordConnections.links.map(edge => {
          edge.link_count = edge?.link_count || 1
          return { data: edge }
        })
        expandEdges.forEach(edge => {
          this.graphElements.edges.push(edge)
          const source = expandNodes[edge.data.source]
          const target = expandNodes[edge.data.target]
          const link = edge.data

          this.$refs.cytoscape.addConnection(
            source,
            target,
            link
          )
        })
      }
      this.$refs.cytoscape.setOverlayLoading(false)
    },
    /* To create Graph Item to used in GraphMixin.js */
    async createGraphItems (triggerCollapseEventNodeId) {
      // This function is only used when user triggers action to collapse a node.
      // This function creates graph items to calculate the shortest path to collapse the node.
      this.usedForVisualisation = true
      const graphItems = []
      let primaryId
      if (this.isEntity) {
        primaryId = this.graphElements.id
      } else {
        // Tactical visualization for view link page, need to set primary entity manually for BFS so that there
        // is a source vertex used as an anchor for the calculation.
        // (1) To always use one of the linkends of the link record being viewed so that the link does not disappear.
        // (2) When one of the linkend is selected to collapse, the other linkend should be selected as the primary instead.
        // For example: (N1) - L1 - (N2)
        // Node N1 has a relationship with node N2 represented by link L1
        // If N1 is selected to collapse, N2 should be the source vertex instead so that L1 and N1 remains.
        // If N1 is selected to collapse and N1 is the source vertex, N2 and L1 will also be collapsed.
        const end1 = this.graphElements.edges[0].data.source
        const end2 = this.graphElements.edges[0].data.target
        primaryId = end1 === triggerCollapseEventNodeId ? end2 : end1
      }
      await this.graphElements.edges.forEach(edge => {
        const end1 = edge.data.source
        const end2 = edge.data.target
        const id = edge.data.id
        const pushedItem = { end1, end2, id }
        graphItems.push(pushedItem)
      })

      await this.graphElements.nodes.forEach(node => {
        const id = node.data.id
        const name = id
        const primary = id === primaryId
        const pushedItem = { id, primary, name }
        graphItems.push(pushedItem)
      })
      this.graphItems = graphItems
    },
    async collapseNode (event) {
      await this.createGraphItems(event.data.id)

      // a function to remove a node from the this.graphElements
      const removeNode = (nodeId) => {
        remove(this.graphElements.nodes, function (thisNode) {
          const thisNodeId = thisNode.data.id
          const thisParentId = thisNode.data?.parent // if have a parent usually is an aggregated node item
          const sameAsThisNode = thisNodeId === nodeId
          const sameAsTheParent = thisParentId === nodeId
          return sameAsThisNode || sameAsTheParent
        })
      }

      // a function to remove edge based on what given nodeId
      const removeEdge = (nodeId) => {
        remove(this.graphElements.edges, function (thisEdge) {
          const edgeTarget = thisEdge.data.target
          const edgeSource = thisEdge.data.source
          return (edgeTarget === nodeId) || (edgeSource === nodeId)
        })
      }

      // shorthand for removing node and edge
      const removeConnection = (nodeId) => {
        removeNode(nodeId)
        removeEdge(nodeId)
        this.$refs.cytoscape.removeNode(nodeId)
      }

      // Data and RecordId Var
      const data = event.data
      const recordId = data.id
      const listOfDeletedNodes = []

      const structuredGraph = await this.createStructuredGraph()
      const distance = structuredGraph.paths.distanceFromSource

      // list all of node that want to be deleted
      for (const id in distance) {
        const shortestPath = await this.findShortestPathFrom(id)
        const partOfCollapsedNode = shortestPath.includes(id)
        if (partOfCollapsedNode) {
          const indexOfRecordId = shortestPath.indexOf(recordId)
          const splicedArray = shortestPath.splice(0, indexOfRecordId)
          splicedArray.forEach(nodeId => listOfDeletedNodes.push(nodeId))
        }
      }

      // delete
      listOfDeletedNodes.forEach(nodeId => {
        removeConnection(nodeId)
        if (!isEmpty(this.expandedNodes[nodeId])) {
          delete this.expandedNodes[nodeId]
        }
      })
      delete this.expandedNodes[recordId]
    },
    /*
    * isHaveMoreExtensions is used for checking whether a node is
    * still have more extensions or not
    */
    isHaveMoreExtensions (connections) {
      let stillHaveExtensions = false
      const entities = connections?.entities || []
      const aggregated = connections?.aggregated || []
      const allItems = entities.concat(aggregated)
      allItems.forEach(entity => {
        const graphElementsNodes = this.graphElements?.nodes || []
        const isExistsInGraphElements = find(graphElementsNodes, function (node) { return node.data.id === entity.id })
        if (!isExistsInGraphElements) {
          stillHaveExtensions = true
        }
      })
      return stillHaveExtensions
    },
    /*
    * fetchDFChildNode => To fetch DF for Child Node
    */
    async fetchDFChildNode (formId) {
      this.formLoading = true
      const genericData = this.childNodeFields
      const rawDFData = await this.fetchDFData(formId)
      await this.assignDFData(rawDFData, genericData)
      this.childNodeDynamicFormData = rawDFData
      this.childNodeIsGeneric = false

      // Change Title name
      const modelLabel = rawDFData?.model_label
      const formTitle = rawDFData?.title
      const id = this.childNodeCurrentRecordId
      this.childNodeFieldsHeader = `${modelLabel} ${id} | ${formTitle}`
      this.formLoading = false
    },
    childNodeSetToGenericView () {
      if (this.childNodePermissions.accessGenericForm) {
        this.fetchChildNodeFields(false, true)
      } else {
        // if dont have the permission, just make it back to primary item information fields
        this.backToPrimaryFields()
      }
    },
    backToPrimaryFields () {
      this.setElementAsSelected(false)
      this.onClickSwitchOffSegregatedEntitiesView(false)
      this.clearChildNodeFields()
      this.setCanAccessGenericForm()
      this.childNodeCurrentData = false
    },
    /*
     To set Primary Node Bolder in Visualisation (Cytoscape)
    */
    setPrimaryNodeBolder () {
      this.$refs.cytoscape?.setNodeBolder(this.$route.params.id)
    },
    /*
     To set an Element (Node or Edge) to appear selected in Visualisation (Cytoscape)
    */
    setElementAsSelected (id, isEdge) {
      this.$refs.cytoscape?.setElementAsSelected(id, isEdge)
    },
    callNoReadPermissionToast (itemType) {
      const itemTypeText = itemType || this.entityOrLink
      this.$root.$bvToast.toast(`${this.$INSIGHT('READ').TOAST.PERMISSION_RESTRICTED.TEXT} ${itemTypeText}`, {
        title: `${this.$INSIGHT('READ').TOAST.PERMISSION_RESTRICTED.TITLE}`,
        autoHideDelay: 5000,
        variant: 'danger',
        opacity: 1
      })
    },
    noAssignedFormToast (itemName) {
      const title = this.$INSIGHT('READ').TOAST.NO_ASSIGNED_FORM.TITLE
      const prefixName = this.$INSIGHT('READ').TOAST.NO_ASSIGNED_FORM.PREFIX_NAME
      const postfixText = this.$INSIGHT('READ').TOAST.NO_ASSIGNED_FORM.POSTFIX_TEXT
      ToastMessage.showNoAssignedForm({
        vueInstance: this,
        name: itemName,
        title,
        prefixName,
        postfixText
      })
    },
    /*
      setImagePreviewField:
        to fetch and set image preview on certain field provided by the caller in param
    */
    async setImagePreviewField (model, id, whereToAssign, field) {
      const response = await this.getAttachment({ model, id, field: field?.name })
      if (response.status) {
        // status 204 to indicate No-content return.
        if (response.status !== 204) {
          const imageBlob = new Blob([response.data], { type: response.data.type })
          const reader = new FileReader()
          // Defined for "this" variable inner function reference
          const thisRef = this
          // Read the file as data and convert it into base64 for image display in another tab
          await new Promise((resolve, reject) => {
            reader.readAsDataURL(imageBlob)
            reader.onload = () => resolve(thisRef.$set(thisRef[whereToAssign], field.name, reader.result))
            reader.onerror = reject
          })
        }
      }
    }
  },
  watch: {
    $route: {
      handler (routeDetails) {
        this.graphElements = {
          id: '',
          nodes: [],
          edges: []
        }
      },
      immediate: true
    },
    error: {
      handler: function (newError) {
        if (newError !== false) {
          this.setError(newError.code, newError.message)
        }

        if (newError.code === 404) {
          this.setSearchTerm(false)
          this.$router.push({ path: this.pathPrefix + this.modelType })
        }
      },
      deep: true
    },
    permissions: {
      handler: function (newPermissions) {
        const noReadPermissions = !newPermissions.read && newPermissions.accessGenericForm
        if (noReadPermissions) {
          this.$router.back()
          this.callNoReadPermissionToast()
        }
      },
      deep: true
    }
  },
  beforeDestroy () {
    this.resetFields()
  }
}
</script>

<style lang="scss" scoped>
  .root {
    position: relative;
    margin: -28px;
  }

  .panes {
    display: flex;
    height: 95vh;

    .sidebar.sidebarHidden {
      text-align: left;
      background: #FFF;
      padding: 0;
      border-left: 1px solid #e0e0e0;
      overflow: auto;
      flex-grow: 1;
      justify-content: center;
      min-width : 0.1%;
      width: 0.1%;

    }
     .sidebarHidden {
      text-align: left;
      background: #FFF;
      padding: 0;
      border-left: 1px solid #e0e0e0;
      overflow: auto;
      flex-grow: 1;
      justify-content: center;
      min-width : 0.1%;
      width: 0.1%;

    }
  }

  .loader {
    position: fixed;
    z-index: 9999;
    left: 50%;
  }

  .custom-resizer {
    width: 100%;
  }

  .custom-resizer > .pane.paneDefault {
    text-align: left;
    overflow: hidden;
    width: 70%;
    max-width: 99.9%;
    min-width : 50%;
  }

   .custom-resizer > .paneDefault {
    text-align: left;
    overflow: hidden;
    min-width: 99.9%;
    width: 99.9%;
    max-width: 99.9%;
  }

  .custom-resizer > .multipane-resizer {
    margin: 0;
    left: 0vw;
    position: relative;
    width : 5px;

    &:before {
      display: block;
      width: 3px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -1.5px;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    &:hover {
      background-color : $orange;
      &:before {
      }
    }

  }

  .va-card.overflow-auto {
    margin-bottom: 100px;
  }

  .angleContainer {
    width: 20px;
    height: 20px;
    position: relative;
    right: 2vw;
    top: 50%;
    cursor: pointer;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    padding: 2px;

    &:hover {
      background-color : $orange;
      fill: white;
      border-color: white;
    }
  }

  .arrow-left {
    fill: grey;
    height: 15px;
    transform: rotate(-90deg);
    margin-top: -2px;
  }

</style>
