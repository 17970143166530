<template>
  <b-form-tags size="sm" v-model="listValue" no-outer-focus>
    <template v-slot="{ tags, disabled, addTag, removeTag }">
      <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
        <li v-for="tag in tags" :key="tag" class="list-inline-item">
          <b-form-tag
            @remove="removeTag(tag)"
            :title="tag"
            :disabled="disabled"
            variant="info"
          >{{ tag }}</b-form-tag>
        </li>
      </ul>
      <b-input-group size="sm">
        <b-form-input
          v-model="inListSearch"
          :id="`tag-search-input-${fieldIndex}`"
          type="search"
          autocomplete="off"
          v-on:keyup.enter="onOptionClick({ option: inListSearch, addTag })"
          placeholder="Enter new item"
          :list="`suggestions-${fieldIndex}`"
         >
        </b-form-input>
        <b-input-group-append>
          <b-btn :id="`add-button-${fieldIndex}`" variant="info"
                 @click="onOptionClick({ option: inListSearch, addTag })"
                 v-b-tooltip.hover.top="$INSIGHT('INPUT_LIST_FORM').ADD_ITEM.TOOLTIP">
              <la-plus />
          </b-btn>
        </b-input-group-append>
      </b-input-group>
      <b-form-datalist :id="`suggestions-${fieldIndex}`" :options="options"></b-form-datalist>
    </template>
  </b-form-tags>
</template>

<script>
import LaPlus from '@/assets/la-plus.svg'

export default {
  name: 'input-list-form',
  components: {
    LaPlus
  },
  props: {
    options: {
      type: Array,
      default: () => ([])
    },
    value: {
      default: () => ([])
    },
    fieldIndex: {
      default: () => (0)
    }
  },
  data: () => ({
    inListSearch: ''
  }),
  computed: {
    listValue: {
      get () {
        if (this.value === '') {
          return []
        } else {
          return this.value
        }
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onOptionClick ({ option, addTag }) {
      addTag(option)
      this.inListSearch = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  svg {
    width: 1em;
    height: auto;

    path {
      fill: white;
    }
  }
</style>
