<template>
  <b-card class="advanced-search" id="advanced-search">
    <div class="summary">
      {{ summary }}
    </div>
    <div class="groups">
      <div class="group">
        <div class="conditions">
          <br />
          <div class="row field" v-for="(field, fieldIndex) in conditions" :key="'field-' + fieldIndex">
            <b-btn class="delete" variant="danger" size="sm" v-b-tooltip:advanced-search.html="'Delete condition'" @click="deleteCondition(fieldIndex)" style="margin-top: auto;">
              <la-trash />
            </b-btn>
            <div class="is-and col-1 px-1" style="margin-top: auto;">
              <b-select v-model="field.is_and" :options="getAndOperatorOptions(fieldIndex)" size="sm" :disabled="fieldIndex === 0"></b-select>
            </div>
            <div class="left-parenthesis col-1 px-1" style="margin-top: auto;">
              <b-select v-model="field.has_left_parenthesis" :options="[{ 'value': true, 'text': '(' }, { 'value': false, 'text': '' }]" size="sm"></b-select>
            </div>
            <div class="field-name col-2 px-1" style="margin-top: auto;">
              <b-select v-model="field.field_name" :options="columnOptions" size="sm" @change="changeFieldType(field, fieldIndex)">
                <template v-slot:first>
                  <option v-if="field.field_name === null" :value="null" disabled hidden>Please select a field</option>
                  <option v-if="field.field_name === ''" :value="''" disabled hidden>Please select a field</option>
                </template>
              </b-select>
            </div>
            <div class="operator col-2 px-1" style="margin-top: auto;">
              <b-select
                v-model="field.operator_type"
                :options="columnOptions[field.field_name] ? columnOptions[field.field_name].operators : []"
                @change="changeOperatorType(field, fieldIndex)"
                size="sm"
                placeholder="Select a field first">
                <template v-slot:first>
                  <option :value="null" disabled hidden v-if="field.field_name === null || field.field_name === ''">Please select a field first</option>
                  <option :value="null" disabled hidden v-if="field.operator_type === null && field.field_name !== null && field.field_name !== ''">Please select an operator</option>
                </template>
              </b-select>
            </div>
            <div class="input col-4 px-1">
              <div class="row px-0 mx-0">
                <div class="col-1 px-0 mx-0" style="margin: auto 0;">
                  <b-form-checkbox v-if="operatorType(columnOptions[field.field_name], field.operator_type) && operatorType(columnOptions[field.field_name], field.operator_type) !== 'blank'" v-model="checkboxField[fieldIndex]" @change="switchInputFieldType(fieldIndex, field.field_name)" style="margin-left: auto" switch size="sm" title="Use @ or @# Operators" v-b-tooltip:advanced-search.hover></b-form-checkbox>
                </div>
                <div class="col-12 px-0 mx-0">
                  <input-field-form :fieldIndex="fieldIndex"
                                    :operatorType="operatorType(columnOptions[field.field_name], field.operator_type)"
                                    :columnOptions="columnOptions"
                                    :checkboxField="checkboxField"
                                    :field="field"
                                    :columnType="columnOptions[field.field_name] ? columnOptions[field.field_name] : null"
                                    :listOfMonth="listOfMonth"
                                    :listOfDay="listOfDay"
                                    v-model="field.value_1"
                                    @input="counter++"/>
                </div>
              </div>
            </div>
            <div class="right-parenthesis col-1 pl-1 pr-0" style="margin-top: auto;" :class="operatorType(columnOptions[field.field_name], field.operator_type) === 'dual' && ['date', 'datetime'].includes(fieldType(columnOptions[field.field_name].type), field.value_1, '') ? 'ml-4' : 'ml-0'">
              <b-select v-model="field.has_right_parenthesis" :options="[{ 'value': true, 'text': ')' }, { 'value': false, 'text': '' }]" size="sm"></b-select>
            </div>
            <div class="col-7"
                 v-if="operatorType(columnOptions[field.field_name], field.operator_type) === 'single'
                        && columnOptions[field.field_name].type === 'radio'
                        && field.value_1 !== 'Yes' && field.value_1 !== 'No'
                        && field.value_1 !== '@' && field.value_1 !== ''"></div>
            <div class="col-5 ml-0 pl-0"
                 v-if="operatorType(columnOptions[field.field_name], field.operator_type) === 'single'
                        && columnOptions[field.field_name].type === 'radio'
                        && field.value_1 !== 'Yes' && field.value_1 !== 'No'
                        && field.value_1 !== '@' && field.value_1 !== ''">
              <b-form-text text-variant="danger">Invalid value: {{ field.value_1 }}</b-form-text>
            </div>
          </div>
          <div class="add-condition">
            <b-btn class="button-add" size="sm" variant="success" @click="addCondition()">
              Add Condition <la-plus />
            </b-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="search">
      <div class="spacer"></div>
      <b-btn variant="primary" @click="$emit('search')" :disabled="isValid === false">
        {{ buttonName }}
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import Vue from 'vue'
import LaPlus from '@/assets/la-plus.svg'
import LaTrash from '@/assets/la-trash.svg'
import InputFieldForm from './InputFieldForm'
import { map } from 'lodash'

export default {
  name: 'advanced-search',
  components: {
    LaPlus,
    LaTrash,
    InputFieldForm
  },
  props: {
    columns: {
      type: [Object, Array, Boolean],
      default: () => ([])
    },
    value: {
      default: () => ([])
    },
    buttonName: {
      type: String,
      default: 'Search'
    }
  },
  data: () => ({
    types: [
      { value: 'and', text: 'AND' },
      { value: 'or', text: 'OR' }
    ],
    counter: 0,
    saving: false,
    listOfMonth: [
      { value: '1', text: Vue.prototype.$INSIGHT('MISC').MONTH.JANUARY },
      { value: '2', text: Vue.prototype.$INSIGHT('MISC').MONTH.FEBRUARY },
      { value: '3', text: Vue.prototype.$INSIGHT('MISC').MONTH.MARCH },
      { value: '4', text: Vue.prototype.$INSIGHT('MISC').MONTH.APRIL },
      { value: '5', text: Vue.prototype.$INSIGHT('MISC').MONTH.MAY },
      { value: '6', text: Vue.prototype.$INSIGHT('MISC').MONTH.JUNE },
      { value: '7', text: Vue.prototype.$INSIGHT('MISC').MONTH.JULY },
      { value: '8', text: Vue.prototype.$INSIGHT('MISC').MONTH.AUGUST },
      { value: '9', text: Vue.prototype.$INSIGHT('MISC').MONTH.SEPTEMBER },
      { value: '10', text: Vue.prototype.$INSIGHT('MISC').MONTH.OCTOBER },
      { value: '11', text: Vue.prototype.$INSIGHT('MISC').MONTH.NOVEMBER },
      { value: '12', text: Vue.prototype.$INSIGHT('MISC').MONTH.DECEMBER }
    ],
    listOfDay: [
      { value: '1', text: Vue.prototype.$INSIGHT('MISC').DAY.SUNDAY },
      { value: '2', text: Vue.prototype.$INSIGHT('MISC').DAY.MONDAY },
      { value: '3', text: Vue.prototype.$INSIGHT('MISC').DAY.TUESDAY },
      { value: '4', text: Vue.prototype.$INSIGHT('MISC').DAY.WEDNESDAY },
      { value: '5', text: Vue.prototype.$INSIGHT('MISC').DAY.THURSDAY },
      { value: '6', text: Vue.prototype.$INSIGHT('MISC').DAY.FRIDAY },
      { value: '7', text: Vue.prototype.$INSIGHT('MISC').DAY.SATURDAY }
    ],
    checkboxField: []
  }),
  mounted () {
    this.setAllInputFieldsType()
  },
  computed: {
    conditions: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:input', value)
      }
    },
    columnOptions () {
      return this.columns
    },
    summary () {
      if (this.columnOptions === false) return ''
      if (this.conditions.length === 0) return 'Please setup some conditions'
      let conditions = this.counter // just here to force a re-evaluation of the computed property as vue doesn't always tirgger when new arrays are added
      conditions = []
      let index = 0
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const field of this.conditions) {
        const column = this.columnOptions[field.field_name]

        if (column === undefined) break

        const operator = column.operators.find(o => o.value === field.operator_type)

        if (operator === undefined) break

        let value = operator.type === 'dual' ? field.value_1 + ' and ' + field.value_2 : field.value_1

        const isParameterized = this.isParameterized(field)
        const isMonthAndNotParameterized = (operator.type === 'month') && !isParameterized
        const isDayAndNotParameterized = (operator.type === 'day') && !isParameterized

        if (isMonthAndNotParameterized) {
          // In this validation we also set the field.value_1 if not in month (string number) format
          const arrayListOfMonthInString = map(this.listOfMonth, 'value')
          const valueIsNotMonth = !arrayListOfMonthInString.includes(field.value_1)
          field.value_1 = valueIsNotMonth ? arrayListOfMonthInString[0] : field.value_1 // if value is not month (number in string), set value to first month (number in string)
          value = this.listOfMonth[field.value_1 - 1].text
        } else if (isDayAndNotParameterized) {
          // In this validation we also set the field.value_1 if not in day (string number) format
          const arrayListOfDayInString = map(this.listOfDay, 'value')
          const valueIsNotDay = !arrayListOfDayInString.includes(field.value_1)
          field.value_1 = valueIsNotDay ? arrayListOfDayInString[0] : field.value_1 // if value is not day (number in string), set value to first day (number in string)
          value = this.listOfDay[field.value_1 - 1].text
        } else if (operator.type === 'list') {
          if (typeof field.value_1 === 'object') {
            value = value.join(', ')
          } else if (field.value_1 === '@') {
            value = '@'
          } else {
            value = ''
          }
        }
        let isAnd
        if (index === 0) {
          isAnd = ''
        } else {
          isAnd = field.is_and ? 'AND' : 'OR'
        }
        const leftParenthesis = field.has_left_parenthesis ? '(' : ''
        const rightParenthesis = field.has_right_parenthesis ? ')' : ''
        conditions.push(isAnd + ' ' + leftParenthesis + '"' + column.text + '" ' + operator.text + ' "' + value + '"' + rightParenthesis + '')

        index++
      }
      return 'Search for: ' + conditions.join(' ')
    },
    isValid () {
      let i = this.counter // just here to force a re-evaluation of the computed property as vue doesn't always tirgger when new arrays are added
      i = 0
      let leftParenthesisCount = 0
      let rightParenthesisCount = 0

      if (this.columnOptions === false) return false
      if (this.conditions.length === 0) return true
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const number in this.conditions) {
        const field = this.conditions[number]
        const column = this.columnOptions[field.field_name]
        if (column === undefined) return false
        const operator = column.operators.find(o => o.value === field.operator_type)
        if (operator === undefined) return false

        // Validation on parameterize value
        if (this.checkboxField[number]) {
          if (this.isUserFormat(field.value_1) || this.isNowDateOrNowTimeFormat(field.value_1) || field.value_1 === '@') {
            i++
          } else if (column.type !== 'date' && column.type !== 'time' && column.type !== 'datetime') {
            i++
          } else {
            return false
          }
        }

        // Check the Yes/No input
        if (column.type === 'radio') {
          if (field.value_1 !== 'Yes' && field.value_1 !== 'No' && field.value_1 !== '@') {
            return false
          }
        }
        if (operator.type === 'single' || operator.type === 'month') {
          if (field.value_1 !== '') {
            if (field.value_1 !== null) {
              i++
            }
          }
        } else if (operator.type === 'dual' && field.value_1 !== '' && field.value_1 !== null && field.value_2 !== '' && field.value_2 !== null) {
          i++
        } else if (operator.type === 'blank') {
          i++
        } else if (operator.type === 'year' && field.value_1 > 999 && field.value_1 < 10000) {
          i++
        } else if (operator.type === 'list' && field.value_1.length > 0) {
          i++
        } else if (operator.type === 'day' && ((field.value_1 > 0) && (field.value_1 < 8))) {
          i++
        } else {
          return false
        }
        if (field.has_left_parenthesis) leftParenthesisCount++
        if (field.has_right_parenthesis) rightParenthesisCount++
      }
      return i > 0 && leftParenthesisCount === rightParenthesisCount
    }
  },
  methods: {
    getAndOperatorOptions (fieldIndex) {
      return fieldIndex !== 0 ? [{ value: true, text: 'AND' }, { value: false, text: 'OR' }] : []
    },
    operatorType (column, operator) {
      if (column === undefined) return undefined
      return this.columnOptions && this.columnOptions[column.value] && this.columnOptions[column.value].operators.find(o => o.value === operator) ? this.columnOptions[column.value].operators.find(o => o.value === operator).type : false
    },
    addCondition () {
      this.checkboxField[this.conditions.length] = false
      this.conditions.push({
        is_and: true,
        has_left_parenthesis: false,
        field_name: '',
        operator_type: '',
        value_1: '',
        value_2: '',
        has_right_parenthesis: false
      })
    },
    deleteCondition (index) {
      if (this.conditions.length === 1) {
        this.conditions.pop()
        this.checkboxField = []
      } else {
        this.conditions.splice(index, 1)
        this.checkboxField.splice(index, 1)
      }
    },
    switchInputFieldType (index, fieldName, changeType = true, value1) {
      const inputField = document.getElementById('field-condition-' + index)
      const inputFieldTagName = inputField?.tagName
      // Check 'in list' operator
      if (this.conditions[index].operator_type === 20 || this.conditions[index].operator_type === 71) {
        if (!this.checkboxField[index]) {
          this.conditions[index].value_1 = []
        } else if (changeType) {
          this.conditions[index].value_1 = '@'
        }
      } else if (inputField) {
        if (!this.checkboxField[index]) {
          const inputFieldType = this.columnOptions[fieldName].type
          const fieldTypeToInputType = {
            calculated_number: 'number',
            calculated_date: 'date',
            datetime: 'date'
          }

          if (inputField.className === 'dual-input') {
            const inputFields = inputField.getElementsByTagName('input')
            inputFields.forEach((field) => {
              if (fieldTypeToInputType[inputFieldType]) {
                field.type = fieldTypeToInputType[inputFieldType]
              } else {
                field.type = inputFieldType
              }
            })
          }

          const isNotRadioButton = inputFieldType !== 'radio'
          const isNotYearIs = this.conditions[index].operator_type !== 11
          const isNotYearIsnt = this.conditions[index].operator_type !== 62
          const isNotSelectTag = inputFieldTagName !== 'SELECT'

          if (fieldTypeToInputType[inputFieldType] && isNotYearIs && isNotYearIsnt) {
            inputField.type = fieldTypeToInputType[inputFieldType]
          } else {
            if (isNotRadioButton && isNotYearIs && isNotYearIsnt && isNotSelectTag) {
              inputField.type = inputFieldType
            }
          }

          this.conditions[index].value_1 = ''
        } else {
          if (changeType) {
            const inputFieldType = this.columnOptions[fieldName].type
            if (inputField.className === 'dual-input') {
              const inputFields = inputField.getElementsByTagName('input')
              inputFields.forEach((field) => {
                field.type = 'text'
              })
            }
            const isNotRadioButton = inputFieldType !== 'radio'
            const isNotYearIs = this.conditions[index].operator_type !== 11
            const isNotYearIsnt = this.conditions[index].operator_type !== 62
            const isNotSelectTag = inputFieldTagName !== 'SELECT'
            if (isNotRadioButton && isNotYearIs && isNotYearIsnt && isNotSelectTag) {
              inputField.type = 'text'
            }
            this.conditions[index].value_1 = value1 || '@'
          }
        }
      }
    },
    setAllInputFieldsType () {
      this.conditions.forEach((condition, index) => {
        if (condition.value_1.toString() === '@' || condition.value_1.toString().startsWith('@#')) {
          this.checkboxField[index] = true
          this.switchInputFieldType(index, condition.field_name, true, condition.value_1)
        } else {
          this.checkboxField[index] = false
        }
      })
    },
    fieldType (type, value1, value2) {
      if (value1?.toString() === '@' || value1?.toString().startsWith('@#')) return 'text'
      if (value2?.toString() === '@' || value2?.toString().startsWith('@#')) return 'text'
      if (['date', 'time', 'datetime'].includes(type)) return type
      if (type === 'calculated_number') return 'number'
      if (type === 'calculated_date') return 'date'
      return 'text'
    },
    changeFieldType (field, index) {
      this.checkboxField[index] = false
      this.switchInputFieldType(index, field.field_name, false)
      field.value_1 = ''
      field.value_2 = ''
    },
    changeOperatorType (field, index) {
      if (typeof field.value_1 === 'object') {
        field.value_1 = ''
      }
      this.switchInputFieldType(index, field.field_name, false)
      field.value_2 = ''
    },
    isNowDateOrNowTimeFormat (value) {
      const NOW_DATE_FORMAT = '@#NOWDATE'
      const NOW_TIME_FORMAT = '@#NOWTIME'
      if (value === NOW_DATE_FORMAT || value === NOW_TIME_FORMAT) {
        return true
      } else if (value.includes('-') || value.includes('+')) {
        const operator = value.includes('-') ? '-' : '+'
        const splitValue = value.split(operator)
        if (splitValue.length !== 2) {
          return false
        } else {
          if ((splitValue[0] === NOW_DATE_FORMAT || splitValue[0] === NOW_TIME_FORMAT) && !isNaN(splitValue[1]) && splitValue[1] !== '') {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    isUserFormat (value) {
      return value === '@#USER'
    },
    isParameterized (field) {
      return this.isUserFormat(field.value_1) || this.isNowDateOrNowTimeFormat(field.value_1) || field.value_1 === '@'
    }
  }
}
</script>

<style lang="scss" scoped>
.advanced-search {
  text-align: left;

  .summary {
    text-align: left;
    padding: 0 0 15px 0;
  }

  .controls {
    display: flex;
    justify-content: space-between;
  }

  svg {
    width: 1em;
    height: auto;

    path {
      fill: #fff;
    }
  }

  .round {
    border: 1px solid #d3d3d3;
    border-radius: 100%;
    padding: 6px;
    height: auto;
    line-height: 1;
    flex-grow: 0;
    cursor: pointer;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .groups {
    margin-top: 15px;
  }

  .group {
    border: 1px solid #d3d3d3;
    padding: 0;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .controls {
      margin: 10px 15px;
    }
  }

  .add-condition {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;

    .button-add {
      margin: 0px 15px 30px 15px;
      padding: 5px 15px;
    }
  }

  .conditions {
    .field {
      display: flex;
      padding: 10px 15px 20px 15px;
      margin: 5px 0;
      justify-content: space-between;
      align-items: center;
      border-top: 1px dashed #d3d3d3;

      &:last-of-type {
      margin-bottom: 0;

      .button-add {
        margin: 0px 0px 0px 15px;
        padding: 0 15px;
      }
    }

      .column {
        flex-grow: 0;
        /*margin-right: 15px;*/
      }

      .operator {
        flex-grow: 1;
        /*margin-right: 15px;*/
      }

      .value {
        flex-grow: 1;
      }

      .delete {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 15px;
      }
    }
  }

  .search {
    display: flex;
    justify-content: space-between;
    justify-items: flex-end;
    align-items: center;
    margin-top: 15px;

    .spacer {
      flex-grow: 1;
    }
  }
}
</style>
