<template>
  <div>
    <error-handler
      v-if="error !== false && loading === false"
      :error="displayError"
      :showError="hasInitialised && emptyModelData"
      :dismissible="false"
      variant="danger"
    />

    <wizard-container v-if="hasInitialised && !loading">
      <b-modal id="confirmation">
        <template v-slot:modal-title>{{$INSIGHT('LINK_FORM_FIELD').MODAL.DUPLICATE_RECORD.TITLE}}</template>

        <div id="message" class="d-block text-center">
          {{ warning }}. {{$INSIGHT('LINK_FORM_FIELD').MODAL.DUPLICATE_RECORD.TEXT}}
        </div>

        <template v-slot:modal-footer>
          <b-button variant="danger" @click="$bvModal.hide('confirmation')">Cancel</b-button>
          <b-button variant="success" @click="submitLinkDetails(true)">Proceed</b-button>
        </template>
      </b-modal>

      <b-card
        class="va-card text-capitalize"
        ref="top"
      >
        <template v-slot:header>
          <FieldListChoices
            :ref="$FORM('REFS').FIELD_LIST_CHOICES"
            :dynamicFormList="dynamicFormList"
            :initialFormName="initialFormName"
            :canChooseGenericForm="canChooseGenericForm"
            mode="createedit"
            type="links"
            @fetchDynamicForm="fetchDynamicForm"
            @loadGenericForm="fetchGenericForm"
          />
        </template>
        <link-end
          class="link-end"
          :end1="ends.end1.label"
          :end2="ends.end2.label"
          :linkend-data="linkendData"
          :end1Icon="ends.end1.icon"
          :end2Icon="ends.end2.icon"
          @changePosition="changePosition"
        />

        <b-modal
          ref="choose-linkend"
          size="lg"
          ok-only
          :ok-title="$LOCAL('COMMON_WORD').CANCEL"
          ok-variant="danger"
          :title="`${$INSIGHT('LINK_FORM_FIELD').MODAL.SELECT_LINK.TITLE} ${position}`"
        >
          <search-modal
            :modelChoices="entityChoices()"
            :restrictedItems="restrictedItems"
            :linkFormFieldError="displayError"
            @selectedEntity="selectedEntity"
          />
        </b-modal>

        <link-connection-option-form
          class="mb-3"
          :confidenceValue="linkendData[$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE].value"
          :directionValue="linkendData[$INSIGHT('LINKEND').COMPONENTS.DIRECTION].value"
          :confidenceOptions="linkendData[$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE].meta.choices"
          :directionOptions="linkendData[$INSIGHT('LINKEND').COMPONENTS.DIRECTION].meta.choices"
          @setConfidenceValue="setConfidenceValue"
          @setDirectionValue="setDirectionValue"
          @verifyLink="verifyLink"
        />

        <span v-if="formLoading" class="d-flex justify-content-center">
          <b-spinner size="sm" />
        </span>
        <entity-form-field
          v-for="item in linkFields" :key="item.name"
          v-else-if="!dynamicFormData"
          v-model="item.value"
          class="form-field"
          :field="item"
          :error="error"
          :id="item.name"
          :blacklistedFileExt="blacklistedFileExt"
          :maxFileSize="maxFileSize"
        />
        <form-field
          v-else
          class="text-left"
          :mode="$FORM('MODE').EDIT_MODEL"
          :loading="loading"
          :formData="dynamicFormData"
          @toGenericView="fetchGenericForm"
          :blacklistedFileExt="blacklistedFileExt"
          :maxFileSize="maxFileSize"
        />

        <error-handler
          :error="displayError"
          :showError="showError"
          :dismissible="false"
          variant="danger"
        />

        <div class="actions">
          <b-button variant="danger" @click="goBack">
            <b-spinner v-if="loading" small :label="`${$LOCAL('COMMON_WORD').CHECKING}..`"/>
            <span v-else>{{$LOCAL('COMMON_WORD').CANCEL}}</span>
          </b-button>

          <b-button variant="success" @click="submitLinkDetails(false)">
            <b-spinner v-if="loading" small :label="`${$LOCAL('COMMON_WORD').CHECKING}..`"/>
            <span v-else>{{$LOCAL('COMMON_WORD').SUBMIT}}</span>
          </b-button>
        </div>
      </b-card>
    </wizard-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { keyBy, mapValues, isEmpty } from 'lodash'

import WizardContainer from '@/modules/config/components/Container'
import SearchModal from '@/modules/insight/components/search_sort/SearchModal'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import EntityFormField from '@/modules/insight/components/EntityFormField'
import LinkEnd from '@/modules/insight/components/LinkEnd'
import FormField from '@/modules/forms/components/FormField'
import FieldListChoices from '@/modules/insight/components/FieldListChoices'
import LinkConnectionOptionForm from '@/modules/insight/components/LinkConnectionOptionForm'

import ModelService from '@/modules/insight/services/model.service'

// import { hasAnyPermission } from '@/modules/insight/utils'
import ToastMessage from '@/utils/toast_message'

import DynamicFormMixin from '@/modules/insight/mixins/DynamicFormMixin'
import LinkFormFieldMixin from '@/modules/insight/mixins/LinkFormFieldMixin'

export default {
  name: 'link-form-field',
  components: {
    WizardContainer, EntityFormField, LinkEnd, ErrorHandler, SearchModal, FormField, FieldListChoices, LinkConnectionOptionForm
  },
  mixins: [DynamicFormMixin, LinkFormFieldMixin],
  props: {
    typeOfForm: {
      type: String
    },
    presetEntity: [Object, undefined],
    permissions: {
      default: () => { return {} }
    },
    blacklistedFileExt: {
      type: Array,
      default: () => ([])
    },
    maxFileSize: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    modelData: {},
    linkFields: [],
    warning: '',
    initialFormName: '',
    defaultFormId: false,
    formIdOrderIsAscending: true,
    canChooseGenericForm: true
  }),
  computed: {
    ...mapState('insight', {
      items: 'records',
      record: 'record',
      error: 'error',
      fields: 'fields',
      links: 'links',
      listOfAllEntities: 'entities'
    }),
    hasInitialised () {
      for (const index in this.linkendData) {
        return true
      }
      return false
    },
    linkName () {
      return (this.modelData) ? this.modelData.name : this.$route.params.model
    },
    emptyModelData () {
      return Object.keys(this.modelData).length === 0
    },
    isDynamicForm () {
      const isCreateUsingDF = this.$route.name === 'create-link-dynamic-form'
      const isEditUsingDF = this.$route.name === 'edit-link-dynamic-form'
      if (isCreateUsingDF || isEditUsingDF || this.defaultFormId) {
        return true
      } else {
        return false
      }
    },
    modelName () {
      // TODO: this computed property will moved to a mixin later
      const model = this.$route.params.model
      const linksModelName = this.links[model]?.label
      const fieldsModelName = this.fields?.model?.name
      const recordModelName = this.record?.model?.name
      const dynamicFormModelName = this.dynamicFormData?.model_label
      return fieldsModelName || recordModelName || dynamicFormModelName || linksModelName
    }
  },
  async mounted () {
    await this.getPermissions()
    await this.checkGenericFormAndDefaultForm()
  },
  beforeDestroy () {
    this.resetFields()
  },
  methods: {
    ...mapActions('insight', [
      'getUnfilteredEntities',
      'resetRecords',
      'getRecord',
      'getRecordForDynamicForm',
      'getCreateFields',
      'createRecord',
      'updateRecord',
      'deleteRecord',
      'resetFields',
      'clearError'
    ]),
    async fetchFormFields () {
      if (this.loading) return
      this.setLoadingStatus(true)

      // load the right form data depending on typeOfForm
      let formData = []
      let success = false
      const useGenericForm = this.permissions.accessGenericForm
      switch (this.typeOfForm) {
        case 'edit':
          await this.checkDynamicForm()
          if (useGenericForm) {
            success = await this.getRecord(
              { model: this.$route.params.model, id: this.$route.params.id }
            )
          } else {
            success = await this.getRecordForDynamicForm({ modelId: this.$route.params.id, formId: this.defaultFormId })
          }
          formData = this.record
          break
        case 'create':
          await this.checkDynamicForm()
          if (useGenericForm) {
            success = await this.getCreateFields({ model: this.$route.params.model })
            formData = this.fields
          } else {
            formData = this.dynamicFormData
            success = !isEmpty(formData)
          }
      }

      if (!success) { // Display top error handler
        this.setError(this.error?.code, this.error?.message)
        return
      }

      this.modelData = formData.model
      this.linkendData = keyBy(formData.linkend.fields, 'name');
      // Maps choice.name as key
      [
        this.$INSIGHT('LINKEND').COMPONENTS.SOURCE_TYPE,
        this.$INSIGHT('LINKEND').COMPONENTS.TARGET_TYPE
      ].forEach((type) => {
        this.linkendData[type].meta.choices = keyBy(this.linkendData[type].meta.choices, 'name')
      })

      // Filtering fields
      const theFields = formData?.fields || formData?.model_fields
      theFields.forEach((field) => {
        if (
          [
            this.$INSIGHT('INPUT_TYPES').CALCULATED_NUMBER,
            this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE,
            this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE_PART
          ].indexOf(field.type) === -1 && !field.readonly
        ) { this.linkFields.push(field) }
      })

      // Populating presets
      if (this.typeOfForm === 'edit') {
        // Generating Images for Ends in parallel
        // Maybe can do a same type check?
        const responses = []
        await Promise.all(['end1', 'end2'].map(async (end) => {
          const response = await ModelService.getIconImage(
            keyBy(
              formData.linkend.entity_details, 'id'
            )[this.linkendData[this.ends[end].related].value].type
          )
          responses[end] = response
        }));

        ['end1', 'end2'].forEach((end) => {
          this.ends[end].label = keyBy(
            formData.linkend.entity_details, 'id'
          )[this.linkendData[this.ends[end].related].value].label
          if (responses[end].status === 200) {
            this.ends[end].icon = window.URL.createObjectURL(new Blob([responses[end].data]))
          }
        })
      } else { // if is create set default value for direction and confidence
        [
          this.$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE,
          this.$INSIGHT('LINKEND').COMPONENTS.DIRECTION
        ].forEach((field) => {
          this.linkendData[field].value = this.linkendData[field].default_value
        })
      }

      this.setLoadingStatus(false)

      if (this.presetEntity) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.SOURCE_TYPE].meta.choices,
            this.$route.params.entityType
          )
        ) {
          this.position = 'end1'
        } else if (
          Object.prototype.hasOwnProperty.call(
            this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.TARGET_TYPE].meta.choices,
            this.$route.params.entityType
          )
        ) {
          this.position = 'end2'
        } else {
          return
        }

        await this.selectedEntity(
          { unique_id: this.presetEntity.id },
          (this.presetEntity.label) ? this.presetEntity.label : this.presetEntity.id,
          this.$route.params.entityType
        )
        this.position = 'details'
      }
      if (this.isDynamicForm) {
        this.assignDynamicFormData(this.dynamicFormData, true)
      }
      this.setNavigationGuard()
      this.formLoading = false
    },
    setLoadingStatus (isLoading) {
      this.$emit('loading', isLoading)
      this.loading = isLoading
    },
    async submitLinkDetails (accepted) {
      this.resetError()
      if (this.dynamicFormData) {
        this.assignDynamicFormData()
      }

      // validate entities selected
      // removed triggered by submit
      // linkfields check is probably pointless
      if (!this.linkFields || this.loading || !this.checkEntity()) return
      const duplicate = await this.verifyLink(true)
      if (duplicate && !accepted) {
        this.warning = duplicate
        this.$bvModal.show('confirmation')
        this.loading = false // Probably not neccessary
        return
      }

      this.loading = true

      const method = (this.typeOfForm === 'create') ? this.createRecord : this.updateRecord
      const response = await method({
        model: this.$route.params.model,
        fields: this.linkFields,
        id: this.$route.params.id,
        additionalData: { linkend: JSON.stringify(mapValues(this.linkendData, 'value')) }
      })

      this.loading = false

      let uniqueId
      switch (this.typeOfForm) {
        case 'create':
          uniqueId = response.unique_id
          break
        case 'edit':
          uniqueId = this.$route.params.id
      }

      if (response) {
        this.setCanLeavePage(true)
        if (this.typeOfForm === 'create') {
          ToastMessage.showCreatedSuccess({
            vueInstance: this,
            prefixName: this.modelData.name,
            name: uniqueId
          })
        } else {
          ToastMessage.showEditedSuccess({
            vueInstance: this,
            prefixName: this.modelData.name,
            name: uniqueId
          })
        }

        if (this.permissions.read) {
          this.$router.push({
            name: 'link',
            params: {
              model: this.$route.params.model,
              id: uniqueId
            }
          })
        } else {
          this.$router.push({ name: 'links' })
        }
      }
      // else error will be committed
    },
    checkEntity () {
      // removed trigger by because it is only triggered by submit
      // Null check
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const end of [
        { type: this.$INSIGHT('LINKEND').COMPONENTS.SOURCE_TYPE, end: 'end1', label: 'Linkend 1' },
        { type: this.$INSIGHT('LINKEND').COMPONENTS.TARGET_TYPE, end: 'end2', label: 'Linkend 2' }
      ]) {
        if (!this.linkendData[end.type].value) {
          this.setError(-1, `Please specify ${end.label}`)
          this.changePosition(end.end)
          return false
        }
      }

      // Same check
      // Assuming ids are absolutely unique
      if (
        this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.SOURCE].value ===
        this.linkendData[[this.$INSIGHT('LINKEND').COMPONENTS.TARGET]].value
      ) {
        this.setError(-1, this.$INSIGHT('ERROR').LINKEND_CANNOT_SAME)
        return false
      }

      return true
    },
    goBack () {
      this.$emit('goBack')
    },
    setNavigationGuard (value) {
      this.$emit('setNavigationGuard', value)
    },
    setCanLeavePage (value) {
      this.$emit('setCanLeavePage', value)
    },
    setDynamicFormData (value) {
      this.$emit('setDynamicFormData', value)
    },
    setConfidenceValue (value) {
      this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE].value = value
    },
    setDirectionValue (value) {
      this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.DIRECTION].value = value
    },
    async checkGenericFormAndDefaultForm () {
      // TODO: this method will moved to a mixin later
      const model = this.$route.params.model
      const defaultForm = this.links[model]?.default_form_id
      const routeIsCreateGeneric = this.$route.name === 'create-link'
      const routeIsEditGeneric = this.$route.name === 'edit-link'
      const routeIsGeneric = routeIsCreateGeneric || routeIsEditGeneric
      await this.fetchDynamicFormList(model)
      await this.setCanAccessGenericForm()

      const canAccessGenericForm = this.permissions.accessGenericForm
      const noFormAssigned = isEmpty(this.dynamicFormList)

      const hasDefaultForm = defaultForm && routeIsGeneric
      const cannotAccessGenericFormAndNoFormAssigned = !canAccessGenericForm && noFormAssigned
      const cannotAccessGenericFormAndNoDefaultForm = !canAccessGenericForm && !defaultForm && routeIsGeneric

      if (hasDefaultForm) {
        this.defaultFormId = defaultForm
      } else if (cannotAccessGenericFormAndNoFormAssigned) {
        const html = this.$createElement
        const modelName = this.modelName
        const modelNameHTML = `<strong>${modelName}</strong>`
        const modalTitle = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.TITLE
        const rawText = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.TEXT
        const replaceParam = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.REPLACE_PARAM
        const okButtonVariant = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.OKBUTTON_VARIANT
        const textMessage = rawText.replace(replaceParam, modelNameHTML)
        const messageHtml = html('div', {
          domProps: { innerHTML: textMessage }
        })
        this.$root.$bvModal.msgBoxOk([messageHtml], {
          title: modalTitle,
          okVariant: okButtonVariant
        })
        this.goBack()
        return
      } else if (cannotAccessGenericFormAndNoDefaultForm) {
        const ascendingOrder = this.formIdOrderIsAscending
        const formListLength = Object.keys(this.dynamicFormList).length
        const firstFormId = Object.keys(this.dynamicFormList)[0]
        const lastFormId = Object.keys(this.dynamicFormList)[formListLength - 1]
        this.defaultFormId = ascendingOrder ? firstFormId : lastFormId
      }
      this.fetchFormFields()
    },
    async checkDynamicForm () {
      // TODO: this method will moved to a mixin later
      await this.setDynamicFormSettings(false, this.linkFields)
      if (this.isDynamicForm) {
        const formId = this.$route.params.formId || this.defaultFormId
        await this.fetchDynamicForm(formId)
        this.initialFormName = this.dynamicFormList[formId].name
      }
    },
    setCanAccessGenericForm () {
      const canAccessGenericForm = this.permissions.accessGenericForm
      this.canChooseGenericForm = canAccessGenericForm
    },
    async fetchGenericForm () {
      this.defaultFormId = false
      this.formLoading = true
      await this.loadGenericForm()
      this.formLoading = false
    }
  },
  watch: {
    error (err) {
      this.resetError()
      if (err && Object.prototype.hasOwnProperty.call(err, 'code') && err.code !== 999) {
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const field of this.linkFields) {
          if (Object.prototype.hasOwnProperty.call(err.message, field.name)) {
            this.setError(err.code, this.$INSIGHT('ERROR').SUBMISSION_ERROR)
            return
          }
        }
        this.setError(err.code, Array.isArray(err.message) ? err.message.toString() : err.message)
      }
    },
    dynamicFormData: {
      deep: true,
      handler: function (value) {
        this.setDynamicFormData(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#confirmation {
  #message {
    white-space: pre;
  }
}

.form-field {
  text-align: left;
}

.load-more-button {
  margin: 5px 0px 15px 0px;
  text-align: center;
}

.spinner-loading {
  padding:50px;
}

.link-end {
  padding-bottom: 40px;
}

.actions {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}

.va-card {
  width: 85%;
  margin: auto;
}

.card.va-card .card-header {
  text-align: left;
}

.no-results {
  padding: 5px 0px 5px 0px;
}

.link-form-header {
  font-size: 1.2rem;
}
</style>
