<template>
  <div class="root">
    <portal to="top-bar">
      <div class="title">
        <h1>
          <router-link
            :to="{
              name: 'entities-index',
              params: { model: $route.params.model }
            }"
            :aria-label="`${entity} ${$LOCAL('ENTITY')}`"
          >
            {{ entity }}
          </router-link>
          <span role="text" v-if="id"> {{ id }}</span>
        </h1>
      </div>

      <b-button-group v-if="this.$route.meta.action === 'edit'" size="sm">
        <b-btn
          v-if="permissions.read"
          variant="outline-success"
          :to="{
            name: 'entity',
            params: { model: $route.params.model, id: $route.params.id }
          }"
        >
          {{ $LOCAL('COMMON_WORD').VIEW }}
        </b-btn>
        <b-btn
          v-if="permissions.delete"
          variant="outline-danger"
          @click="
            showDeleteModal(
              (fieldName = $route.params.model),
              (action = 'deleteThisRecord')
            )
          "
        >
          {{ $LOCAL('COMMON_WORD').DELETE }}
        </b-btn>
      </b-button-group>
    </portal>

    <div v-if="!hasInitialised" class="loader">
      <b-spinner variant="secondary" large label="Loading..." />
    </div>

    <div v-else class="content">
      <wizard-container v-if="permissions.create || permissions.edit">
        <b-modal id="confirmation">
          <template v-slot:modal-title>Duplicate Records</template>
          <div class="d-block text-center">
            {{ warning }}. Do you still want to proceed?
          </div>
          <template v-slot:modal-footer>
            <b-button variant="danger" @click="$bvModal.hide('confirmation')"
              >Cancel</b-button
            >
            <b-button variant="success" @click="submitData(true)"
              >Proceed</b-button
            >
          </template>
        </b-modal>

        <b-card class="va-card text-capitalize fade-in-fast">
          <template v-slot:header>
            <FieldListChoices
              :ref="$FORM('REFS').FIELD_LIST_CHOICES"
              :dynamicFormList="dynamicFormList"
              :initialFormName="initialFormName"
              :canChooseGenericForm="canChooseGenericForm"
              mode="createedit"
              @fetchDynamicForm="fetchDynamicForm"
              @loadGenericForm="loadGenericForm"
            />
          </template>
          <span v-if="formLoading" class="d-flex justify-content-center">
            <b-spinner size="sm" />
          </span>
          <entity-form-field
            v-for="item in nonNullFields"
            :loading="loading"
            :key="item.name"
            v-else-if="!dynamicFormData"
            v-model="item.value"
            :field="item"
            :error="error"
            @verifyField="verifyDiscriminators"
            :id="item.name"
            :blacklistedFileExt="blacklistedFileExt"
            :maxFileSize="maxFileSize"
          >
          </entity-form-field>
          <form-field
            v-else
            :mode="$FORM('MODE').EDIT_MODEL"
            :loading="loading"
            :formData="dynamicFormData"
            :error="error"
            @toGenericView="loadGenericForm"
            @deleteFormAttachment="deleteFormAttachment"
            @verifyField="verifyDiscriminators"
            :blacklistedFileExt="blacklistedFileExt"
            :maxFileSize="maxFileSize"
          />

          <error-handler
            v-if="error !== false && loading === false"
            :error="displayError"
            :dismissible="false"
            variant="danger"
            :showError="showError"
          />

          <div class="actions">
            <b-button
              variant="danger"
              :disabled="loading"
              @click="goBack"
            >
              Cancel
            </b-button>

            <b-button
              id="submit-data"
              variant="success"
              @click="submitData(false)"
            >
              <span v-if="!loading">Submit</span>
              <b-spinner v-else small label="Checking.." />
            </b-button>
          </div>
        </b-card>
      </wizard-container>
    </div>
    <delete-modal
      ref="deleteModal"
      :item="$route.params.model"
      :itemId="deleteModalMessage"
      :modalShow="isShowDeleteModal"
      @ok="
        deleteAction === 'deleteAttachment'
          ? deleteAttachment()
          : deleteThisRecord()
      "
      @hide="cancelDelete"
    >
    </delete-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EntityFormField from '@/modules/insight/components/EntityFormField'
import WizardContainer from '@/modules/config/components/Container'
import DeleteModal from '@/modules/insight/components/DeleteModal'
import FieldListChoices from '@/modules/insight/components/FieldListChoices'
import FormField from '@/modules/forms/components/FormField'
import ToastMessage from '@/utils/toast_message'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
// import ModelService from '@/modules/insight/services/model.service'
import DynamicFormMixin from '@/modules/insight/mixins/DynamicFormMixin'
import VerifyDiscriminatorsMixin from '@/modules/insight/mixins/VerifyDiscriminatorsMixin'
import NavigationGuardMixin from '@/modules/insight/mixins/NavigationGuardMixin'
import { isEmpty } from 'lodash'

export default {
  name: 'CreateEditEntity',
  components: {
    WizardContainer,
    EntityFormField,
    DeleteModal,
    FieldListChoices,
    FormField,
    ErrorHandler
  },
  mixins: [
    DynamicFormMixin,
    VerifyDiscriminatorsMixin,
    NavigationGuardMixin
  ],
  data: () => ({
    loading: false,
    isShowDeleteModal: false,
    deleteModalMessage: '',
    deleteAction: '',
    fieldNameToDelete: '',
    nonNullFields: [],
    permissions: {
      read: false,
      create: false,
      edit: false,
      delete: false,
      accessGenericForm: false
    },
    // verificationId: 0,
    // discriminators: {},
    warning: '',
    displayError: {
      code: -1,
      message: ''
    },
    showError: false,
    initialFormName: '',
    defaultFormId: false,
    canChooseGenericForm: true,
    formIdOrderIsAscending: true,
    blacklistedFileExt: [],
    maxFileSize: {}
  }),
  computed: {
    ...mapState('insight', [
      'entities',
      'records',
      'record',
      'fields',
      'error'
    ]),
    formHeader () {
      return `${this.$route.meta.action} ${this.entity}`
    },
    entity () {
      if (this.record) {
        return this.record.model?.name
      }
      if (this.fields) {
        return this.fields.model?.name
      }
      return this.$route.params.model
    },
    hasInitialised () {
      const isUsingDF = this.isDynamicForm && !isEmpty(this.dynamicFormData)
      if (this.isCreate) {
        return !isEmpty(this.fields) || isUsingDF
      } else if (this.isEdit) {
        return !isEmpty(this.record)
      } else {
        // Reserved for future new route that dont have $route.meta.action
        return !isEmpty(this.record) || !isEmpty(this.fields) || isUsingDF
      }
    },
    id () {
      return this.record === false ? null : this.record.model.id
    },
    isDynamicForm () {
      const isCreateUsingDF = this.$route.name === 'create-entity-dynamic-form'
      const isEditUsingDF = this.$route.name === 'edit-entity-dynamic-form'

      if (isCreateUsingDF || isEditUsingDF || this.defaultFormId) {
        return true
      } else {
        return false
      }
    },
    isEdit () {
      return this.$route.meta.action === 'edit'
    },
    isCreate () {
      return this.$route.meta.action === 'create'
    },
    modelName () {
      // TODO: this computed property will moved to a mixin later
      const model = this.$route.params.model
      const entitiesModelName = this.entities[model]?.label
      const fieldsModelName = this.fields?.model?.name
      const recordModelName = this.record?.model?.name
      const dynamicFormModelName = this.dynamicFormData?.model_label
      return fieldsModelName || recordModelName || dynamicFormModelName || entitiesModelName
    }
  },
  async mounted () {
    await this.checkPermissions()
    await this.checkGenericFormAndDefaultForm()
    await this.checkDynamicForm()
    this.fetch()
    await this.fetchBlacklistedFileExt()
    await this.fetchMaxFileSize()
  },
  beforeDestroy () {
    this.resetFields()
  },
  methods: {
    ...mapActions('insight', [
      'createRecord',
      'updateRecord',
      'getCreateFields',
      'getRecord',
      'getRecordForDynamicForm',
      'getEntities',
      'resetFields',
      'deleteAttachmentByFieldName',
      'deleteRecord',
      'getBlacklistedFileExt',
      'getMaxFileSize'
    ]),
    resetError () {
      this.showError = false
      this.displayError = {
        code: -1,
        message: ''
      }
    },
    setError (code, message) {
      this.showError = true
      this.displayError = {
        code: code,
        message: message
      }
    },
    async fetch () {
      if (this.$route.meta.action === 'create') {
        // Return if no create permissions
        if (!this.permissions.create) {
          return
        }
        if (this.permissions.accessGenericForm) {
          await this.getCreateFields({ model: this.$route.params.model })
        }
        const theFields = this.fields?.fields || this.dynamicFormData?.model_fields
        if (
          this.hasInitialised &&
            !isEmpty(theFields)
        ) {
          theFields.forEach(f => {
            // f.type === 'calculated_number'    : calculated values
            // f.readonly === true: system fields
            if (
              [
                this.$INSIGHT('INPUT_TYPES').CALCULATED_NUMBER,
                this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE,
                this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE_PART
              ].indexOf(f.type) === -1 &&
                !f.readonly
            ) {
              this.nonNullFields.push(f)
              if (f.discriminator) {
                const value =
                    [undefined, null, ''].indexOf(f.value) === -1
                      ? f.value
                      : f.default_value
                this.discriminators[f.name] = {
                  value: value,
                  field_type: f.type,
                  required: f.validation.required
                }
              }
            }
          })
        }
      } else if (this.$route.meta.action === 'edit') {
        // Return if no read or edit permissions
        if (!this.permissions.read || !this.permissions.edit) {
          return
        }
        this.permissions.accessGenericForm ? await this.getRecord({ model: this.$route.params.model, id: this.$route.params.id })
          : await this.getRecordForDynamicForm({ modelId: this.$route.params.id, formId: this.defaultFormId })
        if (
          this.hasInitialised &&
          Object.prototype.hasOwnProperty.call(this.record, 'fields')
        ) {
          this.record.fields.forEach(f => {
            // f.type === 'calculated_number'    : calculated values
            // f.readonly === true: system fields
            if (
              [
                this.$INSIGHT('INPUT_TYPES').CALCULATED_NUMBER,
                this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE,
                this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE_PART
              ].indexOf(f.type) === -1 &&
                !f.readonly
            ) {
              this.nonNullFields.push(f)
              if (f.discriminator) {
                this.discriminators[f.name] = {
                  value: f.value,
                  field_type: f.type,
                  required: f.validation.required
                }
              }
            }
          })
          if (this.isDynamicForm) {
            this.assignDynamicFormData(this.dynamicFormData, true)
          }
        }
      }
      this.setNavigationGuard()
      this.formLoading = false
    },
    async submitData (accepted = false) {
      // Extract
      if (this.dynamicFormData) {
        this.assignDynamicFormData()
      }
      this.resetError()
      const formAction = this.$route.meta.action
      if (this.loading === true) return
      this.loading = true
      const duplicate =
        Object.keys(this.discriminators).length === 0
          ? false
          : await this.verifyDiscriminators(undefined, null, true)
      if (duplicate && !accepted) {
        this.warning = duplicate
        this.$bvModal.show('confirmation')
        this.loading = false
        return
      }

      if (duplicate && accepted) {
        this.$bvModal.hide('confirmation')
      }

      let response, uniqueId
      if (formAction === 'create') {
        response = await this.createRecord({
          model: this.$route.params.model,
          fields: this.nonNullFields
        })
        uniqueId = response.unique_id
      } else {
        response = await this.updateRecord({
          model: this.$route.params.model,
          id: this.$route.params.id,
          fields: this.nonNullFields
        })
        uniqueId = this.record.model.id
      }

      if (response) {
        this.setCanLeavePage(true)
        if (formAction === 'create') {
          ToastMessage.showCreatedSuccess({
            vueInstance: this,
            prefixName: this.modelName,
            name: uniqueId
          })
        } else {
          ToastMessage.showEditedSuccess({
            vueInstance: this,
            prefixName: this.modelName,
            name: uniqueId
          })
        }

        if (this.permissions.read) {
          this.$router.push({
            name: 'entity',
            params: {
              model: this.$route.params.model,
              id: uniqueId
            }
          })
        } else {
          this.$router.push({ name: 'entities' })
        }
      }
      this.loading = false
    },
    async checkDynamicForm () {
      // TODO: this method will moved to a mixin later
      await this.setDynamicFormSettings(false, this.nonNullFields, this.isCreate)
      if (this.isDynamicForm) {
        const formId = this.defaultFormId || this.$route.params.formId
        await this.fetchDynamicForm(formId)

        this.initialFormName = `Form | ${this.dynamicFormList[formId].name}`
      }
    },
    showDeleteModal (fieldName, action) {
      this.fieldNameToDelete = fieldName
      if (this.permissions.delete) this.isShowDeleteModal = true
      this.deleteModalMessage =
        action === 'deleteAttachment'
          ? this.$route.params.id +
            `'s ${this.$LOCAL('COMMON_WORD').ATTACHMENT}`
          : this.$route.params.id
      this.deleteAction = action
    },
    cancelDelete () {
      this.isShowDeleteModal = false
    },
    async deleteAttachment () {
      const response = await this.deleteAttachmentByFieldName({
        model: this.$route.params.model,
        id: this.$route.params.id,
        fieldName: this.fieldNameToDelete
      })
      this.setFieldValueToNull(this.fieldNameToDelete)
      this.$refs.deleteModal.recordDeleted(response)
    },
    async deleteThisRecord () {
      const response = await this.deleteRecord({
        model: this.$route.params.model,
        id: this.$route.params.id
      })
      this.$refs.deleteModal.recordDeleted(response)
      this.$router.push({ name: 'entities-index' })
    },
    setFieldValueToNull (fieldName) {
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const field of this.record.fields) {
        if (field.name === fieldName) {
          field.value = null
        }
      }
      if (this.dynamicFormData) {
        this.assignDynamicFormData(this.dynamicFormData, true)
      }
    },
    async checkPermissions () {
      const response = await this.getEntities()
      if (
        response === true &&
        this.entities[this.$route.params.model] &&
          this.entities[this.$route.params.model].permissions
      ) {
        const permissions = this.entities[this.$route.params.model].permissions
        this.permissions.read = !permissions.find(permission =>
          permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ)
        )
        this.permissions.create = !permissions.find(permission =>
          permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').CREATE)
        )
        this.permissions.edit = !permissions.find(permission =>
          permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').UPDATE)
        )
        this.permissions.delete = !permissions.find(permission =>
          permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DELETE)
        )
        this.permissions.accessGenericForm = !permissions.find(permission =>
          permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').ACCESS_GENERIC_FORM)
        )
        if (
          ((!this.permissions.read || !this.permissions.edit) &&
            this.$route.meta.action === 'edit') ||
          (!this.permissions.create && this.$route.meta.action === 'create')
        ) {
          this.reRouteWithToastError()
        }
      }
    },
    async checkGenericFormAndDefaultForm () {
      // TODO: this method will moved to a mixin later
      const model = this.$route.params.model
      const defaultForm = this.entities[model]?.default_form_id
      const routeIsCreateGeneric = this.$route.name === 'create-entity'
      const routeIsEditGeneric = this.$route.name === 'edit-entity'
      const routeIsGeneric = routeIsCreateGeneric || routeIsEditGeneric
      await this.fetchDynamicFormList(model)
      await this.setCanAccessGenericForm()

      const canAccessGenericForm = this.permissions.accessGenericForm
      const noFormAssigned = isEmpty(this.dynamicFormList)

      const hasDefaultForm = defaultForm && routeIsGeneric
      const cannotAccessGenericFormAndNoFormAssigned = !canAccessGenericForm && noFormAssigned
      const cannotAccessGenericFormAndNoDefaultForm = !canAccessGenericForm && !defaultForm && routeIsGeneric

      if (hasDefaultForm) {
        this.defaultFormId = defaultForm
      } else if (cannotAccessGenericFormAndNoFormAssigned) {
        const html = this.$createElement
        const modelName = this.modelName
        const modelNameHTML = `<strong>${modelName}</strong>`
        const modalTitle = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.TITLE
        const rawText = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.TEXT
        const replaceParam = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.REPLACE_PARAM
        const okButtonVariant = this.$INSIGHT('CREATE_EDIT').TOAST.GENERIC_FORM_ACCESS_RESTRICTED_AND_NO_FORM_ASSIGNED.OKBUTTON_VARIANT
        const textMessage = rawText.replace(replaceParam, modelNameHTML)
        const messageHtml = html('div', {
          domProps: { innerHTML: textMessage }
        })
        this.$root.$bvModal.msgBoxOk([messageHtml], {
          title: modalTitle,
          okVariant: okButtonVariant
        })
        this.goBack()
      } else if (cannotAccessGenericFormAndNoDefaultForm) {
        const ascendingOrder = this.formIdOrderIsAscending
        const formListLength = Object.keys(this.dynamicFormList).length
        const firstFormId = Object.keys(this.dynamicFormList)[0]
        const lastFormId = Object.keys(this.dynamicFormList)[formListLength - 1]
        this.defaultFormId = ascendingOrder ? firstFormId : lastFormId
      }
    },
    setCanAccessGenericForm () {
      const canAccessGenericForm = this.permissions.accessGenericForm
      this.canChooseGenericForm = canAccessGenericForm
    },
    reRouteWithToastError () {
      const action = this.$route.meta.action.toUpperCase()
      const field = `${action}_PERMISSION_RESTRICTED` // Get the corresponding field in $INSIGHT JSON file
      this.$router.push({ name: 'entities' })
      this.$root.$bvToast.toast(
        `${this.$INSIGHT('CREATE_EDIT').TOAST[field].TEXT} ${this.$LOCAL(
          'Entity'
        )}`,
        {
          title: this.$INSIGHT('CREATE_EDIT').TOAST[field].TITLE,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        }
      )
    },
    deleteFormAttachment (fieldName, action) {
      this.showDeleteModal(fieldName, action)
    },
    setNavigationGuard () {
      // To set data in NavigationGuardMixin
      this.canLeavePage = false
      this.comparedData = {
        record: this.record,
        fields: this.fields,
        dynamicFormData: this.dynamicFormData
      }
      this.initialData = JSON.stringify(this.comparedData)
    },
    async fetchBlacklistedFileExt () {
      const response = await this.getBlacklistedFileExt()
      this.blacklistedFileExt = response.BLACKLISTED_FILE_EXT
    },
    async fetchMaxFileSize () {
      this.maxFileSize = await this.getMaxFileSize()
    }
  },
  watch: {
    error (err) {
      if (err.code === 404) {
        this.$router.push({ name: 'entities-index' })
      }
      this.resetError()
      if (
        err &&
        Object.prototype.hasOwnProperty.call(err, 'code') &&
        err.code !== 999
      ) {
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const field of this.nonNullFields) {
          if (Object.prototype.hasOwnProperty.call(err.message, field.name)) {
            this.setError(err.code, this.$INSIGHT('ERROR').SUBMISSION_ERROR)
            return
          }
        }
        this.setError(
          err.code,
          Array.isArray(err.message) ? err.message.toString() : err.message
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.va-card {
  width: 85%;
  margin: auto;
}

.content {
  text-align: left;
}

.actions {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.form-header {
  font-size: 1rem;
}
</style>
