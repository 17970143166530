import ModelService from '@/modules/insight/services/model.service'

export default {
  data: () => ({
    verificationId: 0,
    discriminators: {}
  }),
  methods: {
    async verifyDiscriminators (fieldName = undefined, fieldValue = null, submit = false, isCompositeForm = false, modelType = undefined, discriminators = undefined) {
      const discriminatorsField = discriminators || this.discriminators
      // JS does not have promise cancellation
      this.verificationId += 1
      const currentId = this.verificationId

      // If called from field, set discriminator value
      if (fieldName !== undefined) {
        discriminatorsField[fieldName].value =
          fieldValue === null
            ? this.nonNullFields.find(field => field.name === fieldName)?.value
            : fieldValue
      }

      // Setting params for advanced search based on discriminator values
      const params = {
        page: 1,
        search: null,
        perPage: 1,
        ordering: '-create_date, unique_id',
        types: 'entities',
        advancedSearch: {
          type: 'and',
          groups: [
            {
              type: 'and',
              conditions: []
            }
          ]
        }
      }
      // Set ID for update
      if (this.id) {
        params.advancedSearch.groups[0].conditions.push({
          field: 'unique_id',
          value: this.id,
          operator_type: 1
        })
      }

      for (const discriminator in discriminatorsField) {
        // If any discriminator is required but trivial, return
        if (discriminatorsField[discriminator].required && [undefined, null, ''].indexOf(discriminatorsField[discriminator].value) !== -1) {
          return false
        }
        const condition = {
          field: discriminator,
          field_type: discriminatorsField[discriminator].field_type,
          operator: 1,
          value: discriminatorsField[discriminator].value,
          operator_type: 0
        }
        // If it is datetime and value is trivial, set as null
        if (
          [
            this.$INSIGHT('INPUT_TYPES').DATE,
            this.$INSIGHT('INPUT_TYPES').TIME,
            this.$INSIGHT('INPUT_TYPES').DATETIME
          ].indexOf(condition.field_type) !== -1 &&
          [undefined, null, ''].indexOf(condition.value) !== -1
        ) {
          condition.value = null
        }
        params.advancedSearch.groups[0].conditions.push(condition)
      }
      let data

      try {
        if (isCompositeForm) {
          data = await ModelService.checkDiscriminators(modelType, params)
        } else {
          data = await ModelService.checkDiscriminators(
            this.$route.params.model,
            params
          )
        }
      } catch (error) {
        return false
      }

      if (this.verificationId === currentId && data.exists) {
        let i = 0
        const max = Object.keys(discriminatorsField).length - 1
        let message = this.$INSIGHT('CREATE_EDIT').TOAST.DUPLICATE_RECORD.TEXT
        for (const [fieldName, field] of Object.entries(discriminatorsField)) {
          if (i === max && max !== 0) {
            message += ' and'
          } else if (i !== 0) {
            message += ','
          }
          message += ` ${
            data.fields
              ? data.fields[fieldName].label
              : this.nonNullFields.find(field => field.name === fieldName).label
          } of ${
            field.value
              ? field.value
              : this.$INSIGHT('CREATE_EDIT').MISC_PHRASE.NO_VALUE
          }`
          i += 1
        }
        if (!submit) {
          // Probably should check if a DR toast already exists. However, no endpoint for current toasts
          this.$root.$bvToast.toast(message, {
            title: this.$INSIGHT('CREATE_EDIT').TOAST.DUPLICATE_RECORD.TITLE,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'warning'
          })
        }
        return message
      }
      return false
    }
  }
}
