<template>
  <div class="query" v-if="hasPermission($INSIGHT('PERMISSIONS').ADD) || hasPermission($INSIGHT('PERMISSIONS').CHNG)">
    <portal to="top-bar">
      <div class="title text-capitalize">
        <span v-if="$route.meta.action === 'create'"><h1>{{$LOCAL('COMMON_WORD').NEW}} {{$LOCAL('Query')}}</h1></span>
        <b-spinner small v-else-if="$route.meta.action === 'edit' && loading"/>
        <span v-else><h1>{{$LOCAL('QUERY')}} <strong>{{query.name}}</strong></h1></span>
      </div>
    </portal>

    <ParentCanvas
      ref="ParentCanvas"
      usage="query"
      :loading="loading"
      :queryMeta="queryMeta"
      :items="items"
      :slidingTab="slidingTab"
      :accessGroupsError="accessGroupsError"
      :disableAccessGroupOption="disableAccessGroupOption"
      :accessGroupOption="accessGroupOption"
      :validAccessGroups="validAccessGroups"
      :titleErrorState="queryTitleErrorState"
      :queryNameError="queryNameError"
      @submitData="submitQuery"
      @changeItemsDirectly="changeItemsDirectly"
      @showFilters="showFilters"
      @emitQueryAccessGroups="setAccessGroups"
    />

    <div class="filters">
      <b-modal id="filter-modal" size="xl" hide-footer scrollable :title="'Filters - ' + itemNameFilter">
        <div v-if="modalLoading" class="text-center">
          <b-spinner :label="`${$LOCAL('COMMON_WORD').LOADING}..`"></b-spinner>
        </div>
        <transition v-else name="slide-down">
          <query-filter-form :columns="columnFilter" v-model="itemConditions" @search="saveConditions" buttonName="Set Filter" />
        </transition>
      </b-modal>
    </div>

    <!-- ERROR MODAL -->
    <b-modal id="error-modal" :title="$INSIGHT('QUERY').MODAL.NOT_VALID.TITLE" ok-only>
      <p>
        {{ errorMessage }}
      </p>
    </b-modal>
    <!-- END OF ERROR MODAL -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { nowISO, ISOtoString } from '@/modules/insight/utils'
import ParentCanvas from '@/modules/insight/components/canvas/ParentCanvas'
import ModelService from '../../services/model.service'
import QueryFilterForm from '../../components/QueryFilterForm'
import ToastMessage from '@/utils/toast_message'
import GraphMixin from '@/modules/insight/mixins/GraphMixin'
import { cloneDeep } from 'lodash'

export default {
  name: 'create-edit-query',
  components: {
    ParentCanvas,
    QueryFilterForm
  },
  mixins: [GraphMixin],
  data: () => ({
    loading: false,
    modalLoading: false,
    items: [],
    name: '',
    description: '',
    queryTitleErrorState: null,
    modelFilter: '',
    itemIdFilter: '',
    itemNameFilter: '',
    modelColumnFilter: {},
    columnFilter: [],
    itemConditions: [],
    slidingTab: {
      title: '',
      toggle: false
    },
    queryMeta: {
      category: [],
      access_group: []
    },
    errorMessage: '',
    accessGroupsError: '',
    disableAccessGroupOption: true,
    accessGroupOption: {
      data: 'O'
    },
    accessGroups: [],
    validAccessGroups: [],
    additionalAccessGroups: [],
    queryNameError: {
      message: ''
    }
  }),
  computed: {
    ...mapState('insight', {
      entities: 'entities',
      links: 'links'
    }),
    ...mapState('insight/queries', {
      query: 'query',
      error: 'error',
      queryCRUDPermissions: 'queryCRUDPermissions'
    }),
    ...mapState('auth', {
      userData: 'userData'
    })
  },
  async mounted () {
    await this.validatePermissions()
    await this.getCreateQueryForm()
    await this.fetchQueryData()
  },
  methods: {
    ...mapActions('insight/queries', ['createQuery', 'getQuery', 'updateQuery', 'clearQuery', 'getQueryCreateForm', 'getQueryCRUDPermissions']),
    async getCreateQueryForm () {
      const response = await this.getQueryCreateForm()
      this.queryMeta = response
    },
    async fetchQueryData () {
      this.validAccessGroups = this.queryMeta.access_group.map(accessGroup => accessGroup.gid)
      if (this.$route.meta.action === 'edit') {
        this.loading = true
        await this.getQuery(this.$route.params.id)
        this.items = this.query.items
        if (this.query.gids === 'O' || this.query.gids === null) {
          this.accessGroupOption.data = 'O'
        } else {
          this.accessGroupOption.data = '-'
          const queryAccessGroups = this.query.gids.split(',')
          const accessGroups = queryAccessGroups.filter(gid => this.validAccessGroups.includes(gid))
          this.accessGroups = accessGroups
          this.$refs.ParentCanvas.setQueryAccessGroups(accessGroups)
          this.additionalAccessGroups = queryAccessGroups.filter(gid => !this.validAccessGroups.includes(gid))
          if (this.accessGroups.length === 1 && this.validAccessGroups.length === 1 &&
              this.additionalAccessGroups.length > 0) {
            this.disableAccessGroupOption = true
          }
        }
        // Parse the 'in list' operator value
        for (const item of this.query.items) {
          for (const condition of item.conditions) {
            if (condition.operator_type === 20 || condition.operator_type === 71) {
              if (condition.value_1 !== '@') {
                condition.value_1 = condition.value_1.split('|')
              }
            }
          }
        }
        this.description = this.query.description
        this.loading = false
      } else {
        this.slidingTab.toggle = true
      }
    },
    /**
      isValidQueryItems: function checks whether items are valid
     */
    isValidQueryItems () {
      // validation to check there are items / entities set as output
      let hasOutput = false
      this.items.forEach(item => {
        if (item.output) {
          hasOutput = true
        }
      })

      const isQueryNameEmpty = this.query.name === '' || this.query.name === undefined
      const isItemExists = this.isPresentQueryItems()
      const isGraphItemValid = this.isGraphValid()

      if (!isItemExists) {
        this.errorMessage = this.$INSIGHT('QUERY').ERROR.NO_ENTITY_NO_LINK
        this.$bvModal.show('error-modal')
        this.slidingTab.toggle = false
        return false
      } else if (!hasOutput) {
        this.errorMessage = this.$INSIGHT('QUERY').ERROR.NEED_OUTPUT
        this.$bvModal.show('error-modal')
        this.slidingTab.toggle = false
        return false
      } else if (!isGraphItemValid) {
        this.errorMessage = 'All entities must be linked'
        this.$bvModal.show('error-modal')
        this.slidingTab.toggle = false
        return false
      } else if (isQueryNameEmpty) {
        this.queryNameError.message = this.$INSIGHT('QUERY').ERROR.FIELD_CANNOT_BE_BLANK
        this.queryTitleErrorState = false
        this.slidingTab.toggle = true
        return false
      } else {
        return true
      }
    },
    isGraphValid () {
      // function to checks whether there are any unconnected entities
      let isValid = true
      const items = cloneDeep(this.items)
      const onlyOneItem = items?.length === 1

      if (!onlyOneItem) {
        // set first entity as the primary
        for (const item of items) {
          if (!item.end1) {
            item.primary = true
            break
          }
        }

        const graphItems = this.createStructuredGraph(items, false)

        graphItems.entities.forEach(entity => {
          // check if is graph broken
          const mainGraph = graphItems.paths?.visited
          const entityId = entity.id.toString() // item.paths.visited always return node name as string
          const isThisEntityPartOfGraph = mainGraph?.has(entityId) // to check is this entity in the main graph
          if (!isThisEntityPartOfGraph) {
            isValid = false
          }
        })
      }
      return isValid
    },
    isPresentQueryItems () {
      // function checks whether there are any entities in the canvas
      let isPresent = false
      if (this.items.length !== 0) {
        isPresent = true
      }
      return isPresent
    },
    // TODO : Refactor submitQuery such that validation and submission is separated
    async submitQuery () {
      this.loading = true
      if (this.isValidQueryItems()) {
        if (this.accessGroupOption.data === 'O' || this.accessGroups.length > 0) {
          const data = this.query
          data.items = this.items
          // Convert 'in list' operator value
          for (const item of data.items) {
            for (const condition of item.conditions) {
              if (condition.operator_type === 20 || condition.operator_type === 71) {
                if (condition.value_1 !== '@') {
                  condition.value_1 = condition.value_1.join('|')
                }
              }
            }
          }
          if (data.category === '' || data.category === undefined) {
            data.category = this.userData.username
          }
          // Set the access group
          if (this.accessGroupOption.data === 'O') {
            data.gids = 'O'
          } else {
            const allAccessGroups = this.accessGroups.concat(this.additionalAccessGroups)
            data.gids = allAccessGroups.join(',')
          }
          let response
          if (this.$route.meta.action === 'create') {
            // record_id will overridden in the backend
            data.record_id = '-1'
            // system fields
            data.create_date = ISOtoString(nowISO(), 'datetime')
            data.create_user = this.userData.username
            data.record_type = 13
            response = await this.createQuery({ data })
          } else {
            // system fields
            data.last_upd_date = ISOtoString(nowISO(), 'datetime')
            data.last_upd_user = this.userData.username
            response = await this.updateQuery({ id: this.$route.params.id, data: data })
          }
          this.loading = false
          if (response) {
            if (this.$route.meta.action === 'create') {
              ToastMessage.showCreatedSuccess({
                vueInstance: this,
                name: this.query.name
              })
              if (this.hasPermission(this.$INSIGHT('PERMISSIONS').VIEW)) {
                await this.$router.push({ name: 'generate-query', params: { id: response.record_id } })
              } else {
                await this.$router.push({ name: 'queries' })
              }
            } else {
              ToastMessage.showEditedSuccess({
                vueInstance: this,
                name: this.query.name
              })
              this.$router.push({ name: 'generate-query', params: { id: data.record_id } })
            }
          } else {
            this.errorMessage = this.error?.message
            if (Array.isArray(this.errorMessage)) {
              this.errorMessage = this.errorMessage.join('. ')
            }
            // For input name error
            if (this.errorMessage?.name) {
              this.queryNameError.message = this.errorMessage?.name.join('. ')
              this.queryTitleErrorState = false
              this.slidingTab.toggle = true
            } else {
              this.$bvModal.show('error-modal')
            }
          }
        } else {
          this.accessGroupsError = true
          this.slidingTab.toggle = true
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    async showFilters (item) {
      this.modalLoading = true
      this.modelFilter = item.model
      this.itemIdFilter = item.id
      this.itemNameFilter = item.name

      // clone the conditions
      this.itemConditions = JSON.parse(JSON.stringify(item.conditions))

      this.$bvModal.show('filter-modal')

      // Get and cached column field for filter
      if (this.modelColumnFilter[this.modelFilter]) {
        this.columnFilter = this.modelColumnFilter[this.modelFilter]
      } else {
        const column = await ModelService.getQueryFilterFields(this.modelFilter)
        this.columnFilter = column
        this.modelColumnFilter[this.modelFilter] = column
      }
      this.modalLoading = false
    },
    saveConditions () {
      this.$bvModal.hide('filter-modal')

      for (const item of this.items) {
        if (item.id === this.itemIdFilter) {
          item.conditions = this.itemConditions
        }
      }
    },
    hasPermission (action) {
      if (this.queryCRUDPermissions.includes(`insight.${action}_ibasedatastore`)) {
        return true
      }
      return false
    },
    changeItemsDirectly (data) {
      this.items = data
    },
    setAccessGroups (value) {
      this.accessGroups = value
    },
    async validatePermissions () {
      await this.getQueryCRUDPermissions()
      const canReadQuery = this.hasPermission(this.$INSIGHT('PERMISSIONS').VIEW)
      const canEditQuery = this.hasPermission(this.$INSIGHT('PERMISSIONS').CHNG)
      const canCreateQuery = this.hasPermission(this.$INSIGHT('PERMISSIONS').ADD)
      // Validation
      if (this.$route.meta.action === 'edit') {
        if (!canReadQuery || !canEditQuery) {
          ToastMessage.showErrorDefault({
            vueInstance: this,
            textMessage: `Query ${this.$INSIGHT('ERROR').CODE_404.toLowerCase()}`
          })
          await this.$router.replace({ name: 'queries' })
        }
      } else {
        if (!canCreateQuery) {
          ToastMessage.showErrorDefault({
            vueInstance: this,
            textMessage: `${this.$INSIGHT('CREATE_EDIT').TOAST.CREATE_PERMISSION_RESTRICTED.TEXT} Query`
          })
          await this.$router.replace({ name: 'queries' })
        }
      }
    }
  },
  watch: {
    query: {
      deep: true,
      handler: function () {
        this.queryTitleErrorState = null
      }
    },
    accessGroupOption: {
      deep: true,
      handler: function () {
        if (this.accessGroupOption.data === 'O') {
          this.disableAccessGroupOption = true
          if (this.accessGroupsError) {
            this.accessGroupsError = false
          }
        } else {
          if (this.accessGroups.length === 1 && this.additionalAccessGroups.length > 0 &&
            this.validAccessGroups.length === 1) {
            this.disableAccessGroupOption = true
          } else {
            this.disableAccessGroupOption = false
          }
        }
      }
    },
    accessGroups: function () {
      if (this.accessGroupsError) {
        if (this.accessGroupOption.data === 'O' || this.accessGroups.length > 0) {
          this.accessGroupsError = false
        }
      }
    },
    error (error) {
      if (error.code === 404) {
        this.$router.push({ name: 'queries' })
      }
    }
  },
  beforeDestroy () {
    this.clearQuery()
  }
}
</script>

<style lang="scss" scoped>
.query {
  margin: -28px;
  display: flex;
  height: calc(100vh - 56px);
  position: relative;
}
</style>
