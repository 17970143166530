<template>
  <div class="root">
    <portal to="top-bar">
      <div class="title">
        <h1>
          {{ graphElements.title }}
        </h1>
      </div>
    </portal>
    <div class="panes fade-in-fast">
      <div class="loader mt-3" v-if="!hasInitialisedCompositeFormStructure || !hasInitialisedPrimaryNodeFormData || isLoadingFormData">
        <b-spinner variant="secondary" large label="Loading..."></b-spinner>
      </div>
      <multipane class="custom-resizer" layout="vertical">
        <div :style="[showpanel ? {'width' : inactivePanel} : {'width' : activePanel}]">
          <composite-form-cytoscape-area v-if="hasInitialisedCompositeFormStructure && hasInitialisedPrimaryNodeFormData"
            :nodes="graphElements.entities"
            :edges="graphElements.links"
            @onClickNodeGetFormData="displayForm"
            @onClickEdgeGetFormData="displayForm"
            ref="cytoscape"/>
          <div class="front-overlay" v-if="hasInitialisedCompositeFormStructure && hasInitialisedPrimaryNodeFormData">
            <div class="p-2 d-flex justify-content-between">
              <b-button variant="danger" @click="cancel">
                {{ $LOCAL('COMMON_WORD').CANCEL }}
              </b-button>
              <b-button variant="success" @click="askSubmitConfirmation" :disabled="submitting || isLoadingFormData">
                <b-spinner small v-if="submitting || isLoadingFormData" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
                <span v-else>{{ $LOCAL('COMMON_WORD').SUBMIT }}</span>
              </b-button>
            </div>
          </div>
        </div>
        <multipane-resizer></multipane-resizer>
        <div v-bind:class="[showpanel ? sidebar : '', sidebarHidden]" :style="[showpanel ? {'width' : activeWidth} : {'width' : inactiveWidth}]" @mousedown.stop>
          <b-card class="va-card overflow-auto">
            <template v-slot:header>
              <div v-if="hasInitialisedPrimaryNodeFormData" class="pr-2 pl-2 d-flex justify-content-between">
                <div class="d-flex align-self-stretch py-2">
                  <span class="align-self-center">{{ formPanelTitle }}</span>
                  <b-badge style="font-size: 0.45em"
                           pill
                           class="align-self-start badge-font"
                           :variant="isUsingExistingRecord ? 'light' : 'success'">
                    {{ isUsingExistingRecord ? $LOCAL('COMMON_WORD').EXISTING : $LOCAL('COMMON_WORD').NEW }}
                  </b-badge>
                </div>
                <b-button-group size="sm">
                  <b-button v-if="showSelectButton"
                            :disabled="isFormFieldLoading"
                            variant="primary"
                            size="sm"
                            @click="editAssignment()">
                    <b-spinner small v-if="isFormFieldLoading" :label="`${$LOCAL('COMMON_WORD').SELECTING}...`"></b-spinner>
                    <span v-else>{{ $LOCAL('COMMON_WORD').SELECT }}</span>
                  </b-button>
                  <b-button v-if="showCancelButtonForSelect" variant="danger" size="sm" @click="eraseUseExistingData">
                    <clear class="cancel-icon" />
                  </b-button>
                </b-button-group>
              </div>
            </template>
            <div class="text-center" v-if="!hasInitialisedPrimaryNodeFormData || isFormFieldLoading">
              <b-spinner variant="secondary" label="Loading..."></b-spinner>
            </div>
            <div v-else>
              <div class="text-center mb-3" v-if="isUsingExistingRecord">
                <h4>{{ currentSelectedFormDetails.modelLabel }}</h4>
              </div>
              <b-alert :class="isCurrentModelIsUsingViewModelMode ? 'mb-3' : 'mb-0'"
                       variant="warning"
                       :show="isCurrentFormDataDuplicateRecordWarning">
                <h6 class="alert-heading">{{ this.$INSIGHT('CREATE_EDIT').TOAST.DUPLICATE_RECORD.TITLE }}</h6>
                <span>{{ this.currentSelectedFormDetails.verifyDiscriminators }}</span>
              </b-alert>
              <link-connection-option-form
                v-if="linkendConfidenceOptions.length !== 0 && linkendDirectionOptions.length !== 0"
                :class="isCurrentModelIsUsingViewModelMode? 'text-center mt-2 mb-4' : 'text-center mt-2 mb-2'"
                :disabled="currentSelectedFormDetails.submitted"
                :confidenceValue="currentFormData.LinkendFields.confidence"
                :directionValue="currentFormData.LinkendFields.direction"
                :confidenceOptions="linkendConfidenceOptions"
                :directionOptions="linkendDirectionOptions"
                @setConfidenceValue="setConfidenceValue"
                @setDirectionValue="setDirectionValue"
              />
              <form-field
                :key="triggerReloadFormPanel"
                :mode="currentSelectedFormDetails.mode"
                :loading="submitting"
                :formData="dynamicFormDataPerNodeAndLink"
                :imagePreview="imagePreview"
                :imageLoading="imageLoading"
                :attachmentError="attachmentError"
                :downloadAttachmentLoading="downloadAttachmentLoading"
                :error="currentError()"
                :showBackToGenericViewButton="false"
                @verifyField="triggerVerifyDiscriminators"
                :blacklistedFileExt="blacklistedFileExt"
                :maxFileSize="maxFileSize"
                />
              <error-handler
                :key="`${triggerReloadFormPanel}-error`"
                v-if="isShowCurrentError()"
                :error="currentErrorSimplification()"
                :dismissible="false"
                :showError="isShowCurrentError()"
                variant="danger"
              />
            </div>
          </b-card>
          <!-- TODO Keeping this chunck as <div :class></div> has been removed, not sure what will be the impact -->
<!--        <div :class="[showpanel ? sidebar : '', sidebarHidden]" :style="[showpanel ? {'width' : activeWidth} : {'width' : inactiveWidth}]" @mousedown.stop>-->
<!--          <div v-if="!isLoadingForm && hasInitialised">-->
<!--            <b-card class="va-card overflow-auto">-->
<!--                <template v-slot:header>{{ formPanelTitle }}</template>-->
<!--                <form-field-->
<!--                  :key="triggerReloadFormPanel"-->
<!--                  :mode="$FORM('MODE').EDIT_MODEL"-->
<!--                  :loading="loading"-->
<!--                  :formData="dynamicFormDataPerNodeAndLink"-->
<!--                  :imagePreview="imagePreview"-->
<!--                  :imageLoading="imageLoading"-->
<!--                  :attachmentError="attachmentError"-->
<!--                  :downloadAttachmentLoading="downloadAttachmentLoading"-->
<!--                  :error="error"-->
<!--                  />-->
<!--            </b-card>-->
<!--         </div>-->
        </div>
      </multipane>
    </div>
    <b-modal
      ref="choose-assignment"
      size="lg"
      ok-only
      :ok-title="$LOCAL('COMMON_WORD').CANCEL"
      ok-variant="danger"
      @hidden="this.resetRecords"
      :title="$INSIGHT('COMPOSITE').SELECT_MODAL_TITLE"
    >
      <search-modal
        :modelChoices="entityChoices"
        :restrictedItems="restrictedItems"
        :linkFormFieldError="displayError"
        @selectedEntity="selectedEntity"
      />
    </b-modal>

    <!-- MODAL FOR ASK SUBMIT CONFIRMATION -->
    <b-modal
      id="modal-submit-confirmation"
      :title="$INSIGHT('COMPOSITE').ASK_SUBMIT_CONFIRMATION.TITLE"
      :ok-title="$LOCAL('COMMON_WORD').YES"
      ok-variant="success"
      @ok="submitAllForms"
      cancel-variant="danger"
    >
      <p>
        {{ $INSIGHT('COMPOSITE').ASK_SUBMIT_CONFIRMATION.MESSAGE }}
      </p>
      <!-- <div v-if="countSubmitConfirmation"> -->
        <div v-if="!checkIsEmpty(countSubmitConfirmation.Entities)">
          <strong>{{$LOCAL('Entities')}}</strong>
          <ul>
            <li v-for="(formInfo, formName) in countSubmitConfirmation.Entities" :key="formName">
              {{ wordCreate }} <strong>{{ formInfo.count }} {{ formName }}</strong> {{ formInfo.modelType }}
            </li>
          </ul>
        </div>

        <div v-if="!checkIsEmpty(countSubmitConfirmation.Links)">
          <strong>{{$LOCAL('Links')}}</strong>
          <ul>
            <li v-for="(formInfo, formName) in countSubmitConfirmation.Links" :key="formName">
              {{ wordCreate }} <strong>{{ formInfo.count }} {{ formName }}</strong> {{ formInfo.modelType }}
            </li>
          </ul>
        </div>
      <!-- </div> -->
    </b-modal>
    <!-- END OF MODAL FOR ASK SUBMIT CONFIRMATION -->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { Multipane, MultipaneResizer } from 'vue-multipane'
import FormField from '@/modules/forms/components/FormField'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'
import SearchModal from '@/modules/insight/components/search_sort/SearchModal'
import Clear from '@/assets/window-close-solid.svg'
import { find, isEmpty, isString, reduce } from 'lodash'
import CompositeFormCytoscapeArea from '@/modules/insight/components/CompositeFormCytoscapeArea'
import { ModelError } from '@/modules/insight/services/model.service'
import LinkConnectionOptionForm from '@/modules/insight/components/LinkConnectionOptionForm'

import DynamicFormMixin from '@/modules/insight/mixins/DynamicFormMixin'
import VerifyDiscriminatorsMixin from '@/modules/insight/mixins/VerifyDiscriminatorsMixin'
import NavigationGuardMixin from '@/modules/insight/mixins/NavigationGuardMixin'
import GraphMixin from '@/modules/insight/mixins/GraphMixin'
import { setValues } from '@/modules/insight/utils'

export default {
  name: 'CreateEdit',
  mixins: [DynamicFormMixin, VerifyDiscriminatorsMixin, NavigationGuardMixin, GraphMixin],
  components: {
    CompositeFormCytoscapeArea, Multipane, MultipaneResizer, FormField, SearchModal, Clear, ErrorHandler, LinkConnectionOptionForm
  },
  data: () => ({
    // Controlling of the HTML elements
    resizingPanel: {
      inactivePanelWidth: '70%',
      activePanelWidth: '99.9%',
      inactiveWidthBar: '0.1%',
      activeWidthBar: '30%'
    },
    hasInitialisedPrimaryNodeFormData: false,
    hasInitialisedCompositeFormStructure: false,
    graphLoaded: true,
    graphElements: {},
    showpanel: true,
    pane: 'pane',
    paneDefault: 'paneDefault',
    sidebarHidden: 'sidebarHidden',
    sidebar: 'sidebar',
    formPanelTitle: '',
    compositeFormTitle: '',
    // Data needed for the FormField component
    loading: false,
    imageLoading: false,
    attachmentError: false,
    downloadAttachmentLoading: false,
    imagePreview: {},
    triggerReloadFormPanel: 0,
    dynamicFormDataList: { EntityForms: [], LinkForms: [] },
    dynamicFormDataPerNodeAndLink: false,
    discriminators: {},
    genericModelFields: [],
    primaryFormId: 0,
    primaryEntityGeneratedId: '',
    primaryEntityType: '',
    displayError: {
      code: -1,
      message: ''
    },
    showError: false,
    linkFields: [],
    submitting: false,
    restrictedItems: [],
    currentSelectedFormDetails: {},
    currentFormData: {},
    isFormFieldLoading: false,
    triggerReloadCytoscape: 0,
    isLoadingFormData: true,
    currentFormViewModelType: '',
    isPrimaryFormSubmitted: false,
    isEntityForCurrentForm: false,
    errorMap: {},
    genericFieldsDataByModel: {},
    formConfirmationList: false,
    listOfSubmittedForm: [],
    wordCreate: Vue.prototype.$LOCAL('COMMON_WORD').CREATE,
    blacklistedFileExt: [],
    maxFileSize: {}
  }),
  computed: {
    ...mapState('insight', [
      'fields',
      'error',
      'record',
      'entities',
      'links'
    ]),
    inactivePanel () {
      return this.resizingPanel.inactivePanelWidth
    },
    activePanel () {
      return this.resizingPanel.activePanelWidth
    },
    activeWidth () {
      return this.resizingPanel.activeWidthBar
    },
    inactiveWidth () {
      return this.resizingPanel.inactiveWidthBar
    },
    entityChoices () {
      if (this.dynamicFormDataPerNodeAndLink) {
        return this.dynamicFormDataPerNodeAndLink.model_choices
      }
      return {}
    },
    linkendConfidenceOptions () {
      let options = []
      if (this.dynamicFormDataPerNodeAndLink?.linkend?.fields) {
        const confidenceField = find(this.dynamicFormDataPerNodeAndLink.linkend.fields, { name: 'confidence' })
        if (confidenceField?.meta?.choices) {
          options = confidenceField.meta.choices
        }
      }
      return options
    },
    linkendDirectionOptions () {
      let options = []
      if (this.dynamicFormDataPerNodeAndLink?.linkend?.fields) {
        const directionField = find(this.dynamicFormDataPerNodeAndLink.linkend.fields, { name: 'direction' })
        if (directionField?.meta?.choices) {
          options = directionField.meta.choices
        }
      }
      return options
    },
    isCurrentModelIsUsingViewModelMode () {
      return this.currentSelectedFormDetails.mode === this.$FORM('MODE').VIEW_MODEL
    },
    isUsingExistingRecord () {
      return this.isCurrentModelIsUsingViewModelMode && !!this.currentSelectedFormDetails.useExisting
    },
    isCurrentFormDataDuplicateRecordWarning () {
      return !!this.currentSelectedFormDetails.verifyDiscriminators
    },
    showSelectButton () {
      if (this.currentSelectedFormDetails.submitted) {
        return false
      } else {
        return this.hasInitialisedPrimaryNodeFormData &&
        this.currentSelectedFormDetails.isEntity &&
        this.currentSelectedFormDetails.mode !== this.$FORM('MODE').VIEW_MODEL
      }
    },
    showCancelButtonForSelect () {
      return (this.currentSelectedFormDetails.mode === this.$FORM('MODE').VIEW_MODEL) &&
        (!this.currentSelectedFormDetails.submitted)
    },
    isAllRequiredEntitiesSubmitted () {
      if (!isEmpty(this.dynamicFormDataList.EntityForms)) {
        const notSubmittedAndNotSkipped = (entity) => {
          const notSubmitted = entity.Submitted === false
          const skippedEntity = entity.skipSubmission
          return notSubmitted && !skippedEntity
        }
        const notSubmittedAndNotSkippedInexistent = !find(this.dynamicFormDataList.EntityForms, notSubmittedAndNotSkipped)
        return notSubmittedAndNotSkippedInexistent
      }
      return false
    },
    isAllRequiredLinksSubmitted () {
      if (!isEmpty(this.dynamicFormDataList.LinkForms)) {
        const notSubmittedAndNotSkipped = (link) => {
          const notSubmitted = link.Submitted === false
          const skippedLink = link.skipSubmission
          return notSubmitted && !skippedLink
        }
        const notSubmittedAndNotSkippedInexistent = !find(this.dynamicFormDataList.LinkForms, notSubmittedAndNotSkipped)
        return notSubmittedAndNotSkippedInexistent
      }
      return false
    },
    countSubmitConfirmation () {
      const countForm = { Entities: {}, Links: {} }
      const formConfirmationList = this.formConfirmationList
      if (formConfirmationList.length) {
        this.formConfirmationList.forEach(form => {
          const modelType = form.FormData.model_type
          const groupData = modelType === this.$LOCAL('Entity') ? this.$LOCAL('Entities') : this.$LOCAL('Links')
          const modelLabel = form.FormData.model_label
          const count = (countForm[groupData][modelLabel]?.count || 0) + 1 // will increment according on how much the same model Label is existed
          countForm[groupData][modelLabel] = { count, modelType, modelLabel }
        })
      }
      return countForm
    }
  },
  async mounted () {
    // retrieves 'this.graphElements'
    await this.fetchCompositeFormStructure(this.$route.params.formId)

    /*
     * Retrieve primary node form data first
     * Consideration is for frontend to display form structure once primary node form data is loaded so that
     * user can fill up primary node form data while the rest of the non primary forms are loading.
     */
    this.getPrimaryNodeFormData(this.graphElements)

    this.getAllRequiredGenericFields(this.graphElements)
    this.getFormDataFromAllNonPrimaryNodesAndLinks(this.graphElements)

    // retrieves file validation configurations
    this.fetchBlacklistedFileExt()
    this.fetchMaxFileSize()
  },
  methods: {
    ...mapActions('forms', [
      'getCompositeFormStructure',
      'getForm'
    ]),
    ...mapActions('insight', [
      'getCreateFields',
      'createRecord',
      'resetRecords',
      'getRecordForDynamicForm',
      'resetFields',
      'getBlacklistedFileExt',
      'getMaxFileSize',
      'getEntities',
      'getLinks'
    ]),
    resetError () {
      this.showError = false
      this.displayError = {
        code: -1,
        message: ''
      }
    },
    setError (code, message) {
      this.showError = true
      this.displayError = {
        code: code,
        message: message
      }
    },
    currentError () {
      return this.errorMap[this.currentSelectedFormDetails.id.toString()]
    },
    currentErrorSimplification () {
      const currentError = this.currentError()
      if (currentError && currentError.code !== 999) {
        let errorMessage = this.$INSIGHT('ERROR').SUBMISSION_ERROR
        if (isString(currentError.message)) {
          errorMessage = currentError.message
        } else if (Array.isArray(currentError.message)) {
          if (currentError.message.length > 0) {
            errorMessage = currentError.message.toString()
          }
        }
        return { code: currentError.code, message: errorMessage }
      }
      return false
    },
    isShowCurrentError () {
      return !!this.currentError()
    },
    /**
     *  Get the structure of the composite form to be rendered in Cytoscape
     */
    async fetchCompositeFormStructure (formId) {
      this.hasInitialisedCompositeFormStructure = false
      this.graphElements = await this.getCompositeFormStructure(formId)
      this.compositeFormTitle = this.graphElements.title
      this.hasInitialisedCompositeFormStructure = true
    },
    setGraphItems () {
      // SET _.graphItems for GraphMixin.js use
      const entities = this.dynamicFormDataList.EntityForms
      const links = this.dynamicFormDataList.LinkForms
      const entitiesAndLinks = entities.concat(links)
      const graphItems = []
      entitiesAndLinks.forEach(item => {
        if (item.SourceNode) {
          const end1 = find(entities, ['Id', item.SourceNode.FormId.toString()])
          const end2 = find(entities, ['Id', item.TargetNode.FormId.toString()])
          item.end1 = end1?.FormData?.title
          item.end2 = end2?.FormData?.title
          item.end1Id = end1?.Id
          item.end2Id = end2?.Id
        }
        item.primary = item.FormData?.primary
        item.name = item.FormData?.title
        graphItems.push(item)
      })
      this.graphItems = graphItems
    },
    /**
     * Calling API to get the form data.
     */
    async fetchFormDataFromBackend (formId) {
      const formData = await this.getForm({ id: formId })
      this.setDynamicFormDataOptionsWithoutSettings(formData)
      return formData
    },
    /**
     * This method will get called when the nodes/edged get clicked in cytoscape and it will retrieve the form data
     * [nodeIdAndisEntityOptions] emit from Cytoscape contains data { id: nodeData.id, isEntity: true }.
     * [isEntity] is Boolean and if == false represent linkForms
    */
    displayForm (nodeIdAndisEntityOptions) {
      this.resetError()
      const formDataList = nodeIdAndisEntityOptions.isEntity
        ? this.dynamicFormDataList.EntityForms
        : this.dynamicFormDataList.LinkForms

      const formData = find(formDataList, function (formData) {
        return formData.Id.toString() === nodeIdAndisEntityOptions.id.toString()
      })

      this.currentFormData = formData
      this.dynamicFormDataPerNodeAndLink = formData.FormData
      this.$set(this.currentSelectedFormDetails, 'id', formData.Id.toString())
      this.$set(this.currentSelectedFormDetails, 'isEntity', nodeIdAndisEntityOptions.isEntity)
      this.$set(this.currentSelectedFormDetails, 'mode', formData.mode)
      this.$set(this.currentSelectedFormDetails, 'modelLabel', formData.modelLabel)
      this.$set(this.currentSelectedFormDetails, 'submitted', formData.Submitted)
      this.$set(this.currentSelectedFormDetails, 'verifyDiscriminators', formData.verifyDiscriminators)

      this.currentFormViewModelType = formData.FormData.model
      this.getDiscriminatorFields(formData.GenericFields)

      // This variable is bind to the :key of FormField component, for triggering the reload of the component.
      this.triggerReloadFormPanel += 1
      this.formPanelTitle = this.dynamicFormDataPerNodeAndLink.title
    },
    setIsLoadingFormDataState () {
      if (this.dynamicFormDataList.EntityForms.length === this.graphElements.entities.length &&
        this.dynamicFormDataList.LinkForms.length === this.graphElements.links.length) {
        // logic when all form data has completed loading
        this.setGraphItems()
        this.isLoadingFormData = false
        this.setNavigationGuard()
      }
    },
    /**
     * Get the primary node form data
     */
    async getPrimaryNodeFormData (elements) {
      const entity = find(elements.entities, function (entity) {
        return entity.data.isPrimary === true
      })
      await Promise.resolve(this.fetchFormDataFromBackend(entity.data.id)).then((formData) => {
        // convert formData id to string value for frontend processing
        formData.id = formData.id.toString()
        this.dynamicFormDataList.EntityForms.push({
          Id: formData.id,
          FormData: formData,
          Submitted: false,
          GeneratedRecordId: '',
          mode: this.$FORM('MODE').EDIT_MODEL,
          useExisting: false,
          GenericFields: setValues(formData.model_fields),
          verifyDiscriminators: false,
          skipSubmission: false
        })
        this.primaryFormId = entity.data.id
        this.primaryEntityType = entity.data.model
        this.displayForm({ isEntity: true, id: this.primaryFormId })
        this.hasInitialisedPrimaryNodeFormData = true
        this.setIsLoadingFormDataState()
      })
    },
    /**
     * Get all other nodes and links form that is non-primary.
     */
    async getFormDataFromAllNonPrimaryNodesAndLinks (elements) {
      const allEntityLinkList = elements.entities.concat(elements.links)
      const allNonPrimaryEntityLinkIdList = reduce(allEntityLinkList, function (result, item) {
        if (!item.data.isPrimary) {
          result.push(item.data.id)
        }
        return result
      }, [])

      const nonPrimaryFormDataPromises = allNonPrimaryEntityLinkIdList.map(this.fetchFormDataFromBackend)
      await Promise.all(nonPrimaryFormDataPromises).then((formDataIterable) => {
        for (const formData of formDataIterable) {
          const isEntity = formData.model_type === this.$LOCAL('Entity')
          // convert formData id to string value for frontend processing
          formData.id = formData.id.toString()
          if (isEntity) {
            this.dynamicFormDataList.EntityForms.push({
              Id: formData.id,
              FormData: formData,
              Submitted: false,
              GeneratedRecordId: '',
              mode: this.$FORM('MODE').EDIT_MODEL,
              useExisting: false,
              GenericFields: setValues(formData.model_fields),
              verifyDiscriminators: false,
              skipSubmission: false
            })
          } else {
            // The [SourceNode and TargetNode] dict are needed to capture the generated record ID return from backend
            // when the their respective form has been submitted.
            const link = find(elements.links, ['data.id', formData.id.toString()])
            this.dynamicFormDataList.LinkForms.push({
              Id: formData.id.toString(),
              FormData: formData,
              Submitted: false,
              SourceNode: { FormId: link.data.source, Type: link.data.source_type, GeneratedRecordId: '' },
              TargetNode: { FormId: link.data.target, Type: link.data.target_type, GeneratedRecordId: '' },
              LinkendFields: { confidence: 0, direction: 0 },
              mode: this.$FORM('MODE').EDIT_MODEL,
              GenericFields: setValues(formData.model_fields),
              skipSubmission: false
            })
          }
        }
      })
      this.setIsLoadingFormDataState()
    },
    /**
     * Cancel action to redirect to the previous page.
     */
    cancel () {
      this.$router.push(this.previousRoute)
    },
    isModelErrorResponse (response) {
      return response instanceof ModelError
    },
    submitSingleModelCallback ({ isEntity, id, response }) {
      if (!this.isModelErrorResponse(response)) {
        this.successSubmitSingleModelCallback({ isEntity, id, response })
      } else {
        this.failedSubmitSingleModelCallback({ isEntity, id, response })
      }
    },
    successSubmitSingleModelCallback ({ isEntity, id, response }) {
      if (isEntity) {
        this.successSubmitSingleEntityCallback(id, response)
      } else {
        this.successSubmitSingleLinkCallback(id)
      }
      this.errorMap[id.toString()] = false
    },
    failedSubmitSingleModelCallback ({ isEntity, id, response }) {
      if (isEntity) {
        this.failedSubmitSingleEntityCallback(id, response)
      } else {
        this.failedSubmitSingleLinkCallback(id, response)
      }
    },
    successSubmitSingleEntityCallback (id, response) {
      const entityFormData = find(this.dynamicFormDataList.EntityForms, { Id: id.toString() })
      this.listOfSubmittedForm.push(entityFormData.Id)
      this.changeNodeBorderToGreen(id)
      this.setDynamicFormDataForViewModeWithoutSettings(entityFormData.FormData, entityFormData.GenericFields)
      entityFormData.mode = this.$FORM('MODE').VIEW_MODEL
      entityFormData.Submitted = true
      entityFormData.GeneratedRecordId = response.unique_id
      this.metaDataForLink(entityFormData, response.unique_id)
      if (entityFormData.useExisting) {
        this.setDynamicFormDataFromExistingData(entityFormData.FormData, entityFormData.useExisting)
      }
      if (this.isAllRequiredEntitiesSubmitted && id.toString() === this.currentSelectedFormDetails.id.toString()) {
        this.displayForm({ id, isEntity: true })
      }
      if (id.toString() === this.primaryFormId.toString()) {
        this.primaryEntityGeneratedId = response.unique_id
      }
    },
    successSubmitSingleLinkCallback (id) {
      const linkFormData = find(this.dynamicFormDataList.LinkForms, { Id: id.toString() })
      this.listOfSubmittedForm.push(linkFormData.Id)
      this.changeEdgeBorderToGreen(id)
      this.setDynamicFormDataForViewModeWithoutSettings(linkFormData.FormData, linkFormData.GenericFields)
      linkFormData.mode = this.$FORM('MODE').VIEW_MODEL
      linkFormData.Submitted = true
    },
    failedSubmitSingleEntityCallback (id, response) {
      const entityFormData = find(this.dynamicFormDataList.EntityForms, { Id: id.toString() })
      this.changeNodeBorderToRed(id)
      entityFormData.Submitted = false
      this.errorMap[id.toString()] = { code: response.errorCode, message: response.message }
      this.displayForm({ id, isEntity: true })
    },
    failedSubmitSingleLinkCallback (id, response) {
      const linkFormData = find(this.dynamicFormDataList.LinkForms, { Id: id.toString() })
      this.changeEdgeBorderToRed(id)
      linkFormData.Submitted = false
      this.errorMap[id.toString()] = { code: response.errorCode, message: response.message }
      this.displayForm({ id, isEntity: false })
    },
    /**
     * This method is to submit the individual form data for entity to create record.
    */
    async submitSingleEntityFormData (dynamicFormDataToSubmit) {
      let fields = []
      await this.getDiscriminatorFields(dynamicFormDataToSubmit.GenericFields)
      fields = await this.getDynamicFormDataWithoutSettings(dynamicFormDataToSubmit.FormData, dynamicFormDataToSubmit.GenericFields)
      const response = await this.createRecord({
        model: dynamicFormDataToSubmit.FormData.model,
        fields: fields,
        isUsingFEValidation: false,
        isReturningError: true
      })
      return { id: dynamicFormDataToSubmit.Id, response }
    },
    /**
     * This method is to submit the individual form data for link to create record.
     */
    async submitSingleLinkFormData (dynamicFormDataToSubmit, sourceEntityId, sourceEntityType,
      targetEntityId, targetEntityType) {
      let fields = []
      fields = await this.getDynamicFormDataWithoutSettings(dynamicFormDataToSubmit.FormData, dynamicFormDataToSubmit.GenericFields)
      const response = await this.createRecord({
        model: dynamicFormDataToSubmit.FormData.model,
        fields: fields,
        additionalData: {
          linkend: JSON.stringify({
            source: sourceEntityId,
            target: targetEntityId,
            source_type: sourceEntityType,
            target_type: targetEntityType,
            confidence: dynamicFormDataToSubmit.LinkendFields.confidence,
            direction: dynamicFormDataToSubmit.LinkendFields.direction
          })
        },
        isUsingFEValidation: false,
        isReturningError: true
      })
      return { id: dynamicFormDataToSubmit.Id, response }
    },
    /**
     * This method is to submit all the form data for both link and entity to create record
    */
    async submitAllForms () {
      // set submitting state
      this.submitting = true

      for (const entityFormData of this.dynamicFormDataList.EntityForms) {
        const submissionSkipped = entityFormData.skipSubmission
        if (!submissionSkipped) {
          if (entityFormData.useExisting) {
            await this.submitSingleModelCallback({
              isEntity: true,
              id: entityFormData.Id,
              response: entityFormData.useExisting
            })
          } else {
            if (!entityFormData.Submitted) {
              const response = await this.submitSingleEntityFormData(entityFormData)
              await this.submitSingleModelCallback({ isEntity: true, ...response })
            }
          }
        }
      }

      // We will only proceed with link form submission only when all the entities forms have been submitted
      if (this.isAllRequiredEntitiesSubmitted) {
        for (const linkFormData of this.dynamicFormDataList.LinkForms) {
          const notSkipped = !linkFormData.skipSubmission
          const notSubmitted = !linkFormData.Submitted
          if (notSkipped && notSubmitted) {
            const response = await this.submitSingleLinkFormData(linkFormData, linkFormData.SourceNode.GeneratedRecordId,
              linkFormData.SourceNode.Type, linkFormData.TargetNode.GeneratedRecordId, linkFormData.TargetNode.Type)
            await this.submitSingleModelCallback({ isEntity: false, ...response })
          }
        }
      }

      // We will redirect after all entities and links submitted.
      if (this.isAllRequiredEntitiesSubmitted && this.isAllRequiredLinksSubmitted) {
        // The setTimeout = 1000 is purely for UX purpose.
        setTimeout(() => {
          this.setCanLeavePage(true)
          this.$router.push({
            name: 'entity',
            params: {
              model: this.primaryEntityType,
              id: this.primaryEntityGeneratedId
            }
          })
        }, 1000)
        return true
      }

      // restore submitting state
      this.submitting = false
    },
    editAssignment () {
      this.resetError()
      this.$refs['choose-assignment'].show()
    },
    async selectedEntity (entity, entityLabel, modelName) {
      this.isFormFieldLoading = true
      this.$refs['choose-assignment'].hide()
      this.nonNullFields = []
      await this.getRecordForDynamicForm({
        modelId: entity.unique_id,
        formId: this.currentSelectedFormDetails.id
      })

      // TODO Extract this forEach with the existing [getGenericFieldsData()]
      this.record.fields.forEach(f => {
        if (
          [
            this.$INSIGHT('INPUT_TYPES').CALCULATED_NUMBER,
            this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE,
            this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE_PART
          ].indexOf(f.type) === -1 &&
          !f.readonly
        ) {
          this.nonNullFields.push(f)
          if (f.discriminator) {
            this.discriminators[f.name] = {
              value: f.value,
              field_type: f.type,
              required: f.validation.required
            }
          }
        }
      })
      this.setDynamicFormSettings(false, this.nonNullFields)
      if (this.currentSelectedFormDetails.isEntity) {
        for (const entityFormData of this.dynamicFormDataList.EntityForms) {
          if (entityFormData.Id.toString() === this.currentSelectedFormDetails.id.toString()) {
            this.assignDynamicFormData(entityFormData.FormData, true, true)
            entityFormData.mode = this.$FORM('MODE').VIEW_MODEL
            entityFormData.modelLabel = entityLabel
            entityFormData.useExisting = entity
            entityFormData.verifyDiscriminators = false
            this.currentSelectedFormDetails.mode = this.$FORM('MODE').VIEW_MODEL
            this.currentSelectedFormDetails.modelLabel = entityLabel
            this.currentSelectedFormDetails.useExisting = entityFormData.useExisting
            this.currentSelectedFormDetails.verifyDiscriminators = false
            this.changeNodeBorderToGreen(entityFormData.Id)
            this.errorMap[entityFormData.Id.toString()] = false
          }
        }
      } else {
        for (const linkFormData of this.dynamicFormDataList.LinkForms) {
          if (linkFormData.Id === this.currentSelectedFormDetails.id) {
            this.assignDynamicFormData(linkFormData.FormData, true, true)
            linkFormData.mode = this.$FORM('MODE').VIEW_MODEL
            linkFormData.modelLabel = entityLabel
            this.currentSelectedFormDetails.mode = this.$FORM('MODE').VIEW_MODEL
            this.currentSelectedFormDetails.modelLabel = entityLabel
          }
        }
      }
      // This variable is bind to the :key of FormField component, for triggering the reload of the component.
      this.triggerReloadFormPanel += 1
      // Change the label in cytoscape
      for (const entity of this.graphElements.entities) {
        if (entity.data.id === this.currentSelectedFormDetails.id) {
          entity.data.recordLabel = entityLabel
        }
      }
      this.$refs.cytoscape.useExistingRecordLabel(this.currentSelectedFormDetails.id)
      this.isFormFieldLoading = false
    },
    eraseUseExistingData () {
      // Trigger the discriminator checking
      const discriminatorKeys = Object.keys(this.discriminators)
      if (discriminatorKeys.length > 0) {
        const field = discriminatorKeys[0]
        const value = this.currentSelectedFormDetails.useExisting[field]
        this.triggerVerifyDiscriminators(field, value, true, true, this.currentFormViewModelType)
      }
      this.$refs.cytoscape.unuseExistingRecordLabel(this.currentSelectedFormDetails.id)
      this.currentSelectedFormDetails.mode = this.$FORM('MODE').EDIT_MODEL
      this.currentSelectedFormDetails.modelLabel = ''
      this.currentSelectedFormDetails.useExisting = false
      for (const entityFormData of this.dynamicFormDataList.EntityForms) {
        if (entityFormData.Id.toString() === this.currentSelectedFormDetails.id.toString()) {
          entityFormData.mode = this.$FORM('MODE').EDIT_MODEL
          entityFormData.modelLabel = ''
          entityFormData.useExisting = false
        }
      }
    },
    changeNodeBorderToGreen (id) {
      this.$refs.cytoscape.nodeSubmissionStatusChangeBorderToGreenOrRed(id, true)
    },
    changeEdgeBorderToGreen (id) {
      this.$refs.cytoscape.edgeSubmissionStatusChangeBorderToGreenOrRed(id, true)
    },
    changeNodeBorderToRed (id) {
      this.$refs.cytoscape.nodeSubmissionStatusChangeBorderToGreenOrRed(id, false)
    },
    changeEdgeBorderToRed (id) {
      this.$refs.cytoscape.edgeSubmissionStatusChangeBorderToGreenOrRed(id, false)
    },
    resetNodeBorder (id) {
      this.$refs.cytoscape.resetNodeBorder(id)
    },
    getAllModelNameList (elements) {
      const modelNames = new Set()
      if (elements.entities) {
        elements.entities.forEach((item) => modelNames.add(item.data.model))
      }
      if (elements.links) {
        elements.links.forEach((item) => modelNames.add(item.data.model))
      }
      return Array.from(modelNames)
    },
    // TODO: Is it still needed to fetch all the generic fields? since the formData itself already have the needed fields
    async getAllRequiredGenericFields (elements) {
      const modelNameList = this.getAllModelNameList(elements)
      const modelNamesPromises = modelNameList.map(this.getGenericFieldsData)
      await Promise.all(modelNamesPromises).then((listOfGenericFields) => {
        for (const index in modelNameList) {
          this.genericFieldsDataByModel[modelNameList[index]] = listOfGenericFields[index]
        }
      })
    },
    getGenericFieldsDataByModel (model) {
      if (this.genericFieldsDataByModel[model]) {
        return Promise.resolve(this.genericFieldsDataByModel[model])
      } else {
        return this.getGenericFieldsData(model)
      }
    },
    /**
     * This method is to get the generic fields of the model and tabulate [this.genericModelFields] for [DynamicFormMixin] as prerequisite.
    */
    async getGenericFieldsData (model) {
      const genericModelFields = []

      // Get the base fields of the entity. The returns is assign to [this.fields] in vuex
      const item = await this.getCreateFields({ model, isReturningData: true })

      // Assigning [this.fields] to [this.genericModelFields]
      Object.prototype.hasOwnProperty.call(item, 'fields')
      item.fields?.forEach(f => {
        if (
          [
            this.$INSIGHT('INPUT_TYPES').CALCULATED_NUMBER,
            this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE,
            this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE_PART
          ].indexOf(f.type) === -1 &&
          !f.readonly
        ) {
          genericModelFields.push(f)
        }
      })
      return genericModelFields
    },
    /**
     * This methods is to iterate through the generic fields of the current form and uncover the discriminator fields.
     * If found, assign it to [this.discriminators] to be used in [VerifyDiscriminatorsMixin]
    */
    getDiscriminatorFields (genericFieldsOfTheCurrentForm) {
      this.discriminators = {}
      genericFieldsOfTheCurrentForm.forEach(f => {
        if (f.discriminator) {
          const value =
                  [undefined, null, ''].indexOf(f.value) === -1
                    ? f.value
                    : f.default_value
          this.discriminators[f.name] = {
            value: value,
            field_type: f.type,
            required: f.validation.required
          }
        }
      }
      )
    },
    isCurrentSelectedFormId (id) {
      return id.toString() === this.currentSelectedFormDetails.id.toString()
    },
    /**
     * Listening to emit signal to trigger for discriminator check from [FormField]
    */
    async triggerVerifyDiscriminators (field, value) {
      const entity = await find(this.dynamicFormDataList.EntityForms,
        { Id: this.currentSelectedFormDetails.id.toString() })
      // No discriminator check for link form
      if (this.currentSelectedFormDetails.isEntity) {
        const result = await this.verifyDiscriminators(field, value, true, true,
          entity.FormData.model, this.discriminators)
        entity.verifyDiscriminators = result
        if (this.isCurrentSelectedFormId(entity.Id)) {
          this.currentSelectedFormDetails.verifyDiscriminators = result
        }
      }
    },
    /**
     * This method is to capture the unique ID generated and tag it to the links for matching source and target nodes
     */
    metaDataForLink (sourceAndTargetNode, uniqueIdGenerated) {
      for (const linkFormData of this.dynamicFormDataList.LinkForms) {
        if (linkFormData.SourceNode.FormId.toString() === sourceAndTargetNode.Id.toString()) {
          linkFormData.SourceNode.GeneratedRecordId = uniqueIdGenerated
        }

        if (linkFormData.TargetNode.FormId.toString() === sourceAndTargetNode.Id.toString()) {
          linkFormData.TargetNode.GeneratedRecordId = uniqueIdGenerated
        }
      }
    },
    setNavigationGuard () {
      // To set data in NavigationGuardMixin
      this.canLeavePage = false
      this.comparedData = { dynamicFormDataList: this.dynamicFormDataList }
      this.initialData = JSON.stringify(this.comparedData)
      this.discardChangesMsg = this.$INSIGHT('NAVIGATION_GUARD').BEFORE_ROUTE_LEAVE.DISCARD_CHANGES.CREATE_RECORD_COMPOSITE_FORM.MESSAGE
    },
    async askSubmitConfirmation () {
      this.formConfirmationList = []
      const entityForms = this.dynamicFormDataList.EntityForms
      const linkForms = this.dynamicFormDataList.LinkForms
      const listOfForms = entityForms.concat(linkForms)
      listOfForms.forEach(async form => {
        const isEntity = form.FormData.model_type === this.$LOCAL('Entity')
        if (isEntity) {
          await this.checkSkipEntitySubmission(form)
        } else {
          await this.checkSkipLinkSubmission(form)
        }
        const notEmpty = !isEmpty(form)
        const formId = form.Id
        const alreadySubmitted = this.listOfSubmittedForm.includes(formId)
        const isSkipped = form.skipSubmission
        const needToBeCounted = notEmpty && !form.useExisting && !alreadySubmitted && !isSkipped
        if (needToBeCounted) {
          this.formConfirmationList.push(form)
        }
      })
      this.$bvModal.show('modal-submit-confirmation')
    },
    async formHasBeenFilled (entity) {
      let hasBeenFilled = false
      const formData = entity?.FormData?.groups
      const formHasBeenSubmitted = entity?.Submitted
      const falsyValue = [undefined, null, '']

      if (formData) {
        formData.forEach(group => {
          group.model_fields.forEach((field) => {
            const fieldValue = field.options?.value
            const fieldHasValue = !falsyValue.includes(fieldValue)
            if (fieldHasValue || formHasBeenSubmitted) {
              hasBeenFilled = true
            }
          })
        })
      }

      return hasBeenFilled
    },
    async checkSkipEntitySubmission (entity) {
      let skipSubmission = false
      let childIsFilled = false
      const formHasBeenFilled = await this.formHasBeenFilled(entity)
      const isMandatory = entity.FormData?.isMandatory
      const childItems = await this.getChildItems(entity)

      await childItems.forEach(async childEntity => {
        const childNotEmpty = await this.formHasBeenFilled(childEntity)
        const childIsSubmitted = childEntity.Submitted === true
        const childHasBeenFilled = childNotEmpty || childIsSubmitted
        if (childHasBeenFilled) {
          childIsFilled = true
        }
      })

      const shouldSkipped = !formHasBeenFilled && !isMandatory && !childIsFilled
      if (shouldSkipped) {
        this.resetNodeBorder(entity.Id)
        entity.skipSubmission = true
        skipSubmission = true
      } else {
        skipSubmission = false
        entity.skipSubmission = false
      }

      return skipSubmission
    },
    async checkSkipLinkSubmission (link) {
      let skipSubmission = true
      const entities = this.dynamicFormDataList.EntityForms

      const end1 = find(entities, ['Id', link.end1Id?.toString()])
      const end2 = find(entities, ['Id', link.end2Id?.toString()])

      const end1HasBeenFilled = await this.formHasBeenFilled(end1)
      const end2HasBeenFilled = await this.formHasBeenFilled(end2)

      const getEnd1Parent = await this.getParentItem(end1)
      const getEnd2Parent = await this.getParentItem(end2)

      const end1IsMandatory = end1.FormData.isMandatory
      const end2IsMandatory = end2.FormData.isMandatory

      const end1HasBeenSubmitted = end1.Submitted
      const end2HasBeenSubmitted = end2.Submitted

      const end1IsParent = getEnd2Parent?.Id?.toString() === end1?.Id?.toString()
      const end2IsParent = getEnd1Parent?.Id?.toString() === end2?.Id?.toString()

      const end1IsSkipped = end1.skipSubmission
      const end2IsSkipped = end2.skipSubmission

      const sourceNodeTargetId = link.SourceNode?.GeneratedRecordId
      const targetNodeTargetId = link.TargetNode?.GeneratedRecordId

      const bothEndIsMandatory = end1IsMandatory && end2IsMandatory
      const bothIsFilled = end1HasBeenFilled && end2HasBeenFilled
      const bothIsSubmitted = end1HasBeenSubmitted && end2HasBeenSubmitted
      const end1ChildHasBeenFilled = end1IsParent && end2HasBeenFilled
      const end2ChildHasBeenFilled = end2IsParent && end1HasBeenFilled
      const end1AndEnd2NotSkipped = !end1IsSkipped && !end2IsSkipped
      const hasSourceAndTargetGenId = sourceNodeTargetId && targetNodeTargetId

      const shouldNotSkip = (bothEndIsMandatory || bothIsSubmitted || bothIsFilled || end1ChildHasBeenFilled || end2ChildHasBeenFilled ||
          end1AndEnd2NotSkipped || hasSourceAndTargetGenId)

      if (shouldNotSkip) {
        skipSubmission = false
        link.skipSubmission = false
      } else {
        skipSubmission = true
        link.skipSubmission = true
      }

      return skipSubmission
    },
    checkIsEmpty (object) {
      return isEmpty(object)
    },
    setConfidenceValue (value) {
      this.currentFormData.LinkendFields.confidence = value
      this.$refs.cytoscape.setEdgeConfidence(this.currentFormData.Id.toString(), value)
    },
    setDirectionValue (value) {
      this.currentFormData.LinkendFields.direction = value
      this.$refs.cytoscape.setEdgeDirection(this.currentFormData.Id.toString(), value)
    },
    async fetchBlacklistedFileExt () {
      const response = await this.getBlacklistedFileExt()
      this.blacklistedFileExt = response.BLACKLISTED_FILE_EXT
    },
    async fetchMaxFileSize () {
      this.maxFileSize = await this.getMaxFileSize()
    }
  },
  watch: {
    error (err) {
      if (err.code === 404) {
        this.$router.push({ name: 'forms-index' })
      }
      this.resetError()
      if (
        err &&
        Object.prototype.hasOwnProperty.call(err, 'code') &&
        err.code !== 999
      ) {
        if (this.genericFields) {
          for (const field of this.genericFields) {
            if (Object.prototype.hasOwnProperty.call(err.message, field.name)) {
              this.setError(err.code, this.$INSIGHT('ERROR').SUBMISSION_ERROR)
              return
            }
          }
        }
        this.setError(
          err.code,
          Array.isArray(err.message) ? err.message.toString() : err.message
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .root {
    position: relative;
    margin: -28px;
  }

  .panes {
    display: flex;
    height: 95vh;

    .sidebar.sidebarHidden {
      text-align: left;
      background: #FFF;
      padding: 0;
      border-left: 1px solid #e0e0e0;
      overflow: auto;
      flex-grow: 1;
      justify-content: center;
      min-width : 0.1%;
      width: 0.1%;

    }
     .sidebarHidden {
      text-align: left;
      background: #FFF;
      padding: 0;
      border-left: 1px solid #e0e0e0;
      overflow: auto;
      flex-grow: 1;
      justify-content: center;
      min-width : 0.1%;
      width: 0.1%;
    }
  }

  .loader {
    position: fixed;
    z-index: 9999;
    left: 50%;
  }

  .custom-resizer {
    width: 100%;
  }

  .custom-resizer > .pane.paneDefault {
    text-align: left;
    overflow: hidden;
    width: 70%;
    max-width: 99.9%;
    min-width : 50%;
  }

  .custom-resizer > .paneDefault {
    text-align: left;
    overflow: hidden;
    min-width: 99.9%;
    width: 99.9%;
    max-width: 99.9%;
  }

  .custom-resizer > .multipane-resizer {
    margin: 0;
    left: 0vw;
    position: relative;
    width : 5px;

    &:before {
      display: block;
      width: 3px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -1.5px;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    &:hover {
      background-color : $orange;
      &:before {
      }
    }

  }

  .va-card.overflow-auto {
    margin-bottom: 100px;
  }

  .submitButtonDiv {
    padding-top: 10px;
  }

  .angleContainer {
    width: 20px;
    height: 20px;
    position: relative;
    right: 2vw;
    top: 50%;
    cursor: pointer;
    border-radius: 50%;
    border-style: solid;
    border-width: thin;
    padding: 2px;

    &:hover {
      background-color : $orange;
      fill: white;
      border-color: white;
    }
  }
  .front-overlay {
    position: sticky;
    bottom: 0;
    z-index: 999999;
  }

  .badge-font {
    font-size: 0.6em;
  }

  .arrow-left {
    fill: grey;
    height: 15px;
    transform: rotate(-90deg);
    margin-top: -2px;
  }

  .cancel-icon {
    padding: 0px 4px 0px 3.5px;
    height: 13px;
    filter: invert(100%) drop-shadow(0px 0px 0px white);
  }
</style>
