<template>
  <div class="container" id="container">
    <div class="row justify-content-center">
      <div role="group" class="button-group">
        <div
          v-b-tooltip:container.hover :title="tooltipTitle('Linkend1')"
          class="col-6"
          @click="endButtonClicked('end1')"
        >
          <b-img
            v-if="end1Icon"
            class="entity-icon cursor-pointer img-thumbnail shadow"
            :src="end1Icon"
            v-bind:alt="end1"
          />
          <b-button v-else v-b-tooltip:container.hover class="circle shadow" id="end1">
            <LaLink v-if="end1" id="end1-default-icon" height="40px" :aria-label="`${$LOCAL('AriaLabelChangeLinkEnd')} 1: ${end1}`"/>
            <LaPlus v-else height="40px" :aria-label="`${$LOCAL('AriaLabelAddLinkEnd')} 1`"/>
          </b-button>
          <span class="linkend-label" :style="fontSize">{{end1}}</span>
        </div>

        <div
          v-b-tooltip:container.hover :title="tooltipTitle('Linkend2')"
          class="col-6"
          @click="endButtonClicked('end2')"
        >
          <b-img
            v-if="end2Icon"
            class="entity-icon cursor-pointer img-thumbnail shadow"
            :src="end2Icon"
            v-bind:alt="end2"
          />
          <b-button v-else class="circle shadow" id="end2">
            <LaLink v-if="end2" height="40px" :aria-label="`${$LOCAL('AriaLabelChangeLinkEnd')} 2: ${end2}`"/>
            <LaPlus v-else height="40px" :aria-label="`${$LOCAL('AriaLabelAddLinkEnd')} 2`"/>
          </b-button>
          <span class="linkend-label" :style="fontSize">{{end2}}</span>
        </div>
      </div>

      <div class="col-6 link-div">
        <svg class="header-link-svg">
          <!-- TODO Should use data- attributes instead -->
          <line v-bind:style="getLineStyle()" class="link-line" x1="0" y1="50%" x2="100%" y2="50%"/>
          <LaTriangle v-bind:style="getArrowStyle()" class="link-direction"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import LaPlus from '@/assets/la-plus.svg'
import LaLink from '@/assets/la-link.svg'
import LaTriangle from '@/assets/la-triangle.svg'

export default {
  name: 'link-end',
  props: {
    end1: {
      type: String
    },
    end2: {
      type: String
    },
    linkendData: {
      type: Object
    },
    end1Icon: {},
    end2Icon: {}
  },
  components: {
    LaPlus, LaLink, LaTriangle
  },
  computed: {
    fontSize () {
      return (this.end1.length >= 10 || this.end2.length >= 10)
        ? 'font-size: 12px;'
        : 'font-size: 16px;'
    }
  },
  methods: {
    async endButtonClicked (goto) {
      this.$emit('changePosition', goto)
    },
    tooltipTitle (hoveredItem) {
      let theText
      if (hoveredItem === 'Linkend1') theText = this.end1 ? 'Change ' : 'Select '
      if (hoveredItem === 'Linkend2') theText = this.end2 ? 'Change ' : 'Select '
      return theText + hoveredItem
    },
    getLineStyle () {
      let dasharray
      switch (this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE].value) {
        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.CONFIRMED:
          dasharray = '0%'
          break

        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.UNCONFIRMED:
          dasharray = '10%'
          break

        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.TENTATIVE:
          dasharray = '3%'
          break

        default:
          dasharray = '0%'
          break
      }
      return { 'stroke-dasharray': dasharray }
    },
    getArrowStyle () {
      let clip
      switch (this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.DIRECTION].value) {
        case this.$INSIGHT('LINKEND').DIRECTION_TYPES.NONE:
          clip = 'inset(-10% 50% -10% 50%)'
          break

        case this.$INSIGHT('LINKEND').DIRECTION_TYPES.RIGHT:
          clip = 'inset(-10% 50% -10% -10%)'
          break

        case this.$INSIGHT('LINKEND').DIRECTION_TYPES.LEFT:
          clip = 'inset(-10% -10% -10% 50%)'
          break

        case this.$INSIGHT('LINKEND').DIRECTION_TYPES.BOTH:
          clip = 'inset(-10% -10% -10% -10%)'
          break

        default:
          clip = 'inset(-10% 50% -10% 50%)'
          break
      }
      return { 'clip-path': clip }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-group {
  width: 100%;
  display: flex;
}

.circle {
  height: 100px;
  width: 100px;
  background-color: #f7f7f7;
  border-color: #e6e6e6;
  border-radius: 50%;
  border-width: 4px;
  display: inline-block;
}

.entity-icon {
  height: 100px;
  width: 100px;
  background-color: #f7f7f7;
  border-radius: 50%;
  border-width: 2px;
  display: inline-block;
}

.link-div {
  height: 10px;
  margin-top: -55px;
  border-style: solid;
  border-width: 0px;
  position: static;
}

.header-link-svg {
  height: 200%;
  width: 100%;
  display: block;
  margin-top: -5px;
}

.link-line {
  stroke:black;
  stroke-width: 3;
  stroke-dasharray: 0%;
}

.link-direction {
  display: block;
  clip-path: inset(0px 50% 0px 50%);
}

.linkend-label {
  text-align: center;
  color: black;
  position: absolute;
  margin-top: 100px;
  margin-left: -125px;
  width: 150px;
  font-weight: 450;
}
</style>
