<template>
  <div class="queries" id="queries">
    <portal to="top-bar">
      <div class="title">
        <h1>{{ $INSIGHT('INDEX_QUERIES').TITLE }}</h1>
      </div>
      <b-button-group size="sm" v-if="hasInitialised">
        <b-btn
          v-if="hasPermission($INSIGHT('PERMISSIONS').ADD)"
          variant="success"
          :to="{ name: 'create-query' }"
        >
          {{ $LOCAL('COMMON_WORD').NEW }}
        </b-btn>
      </b-button-group>
    </portal>

    <search-sort-controls
      v-if="hasPermission($INSIGHT('PERMISSIONS').VIEW) && hasInitialised"
      :loading="loading"
      :showSortAndRefresh="hasInitialised"
      :hasSearchBar="true"
      :hasClearSearchCross="true"
      :hasAdvancedSearch="false"
      :hasModelSelection="false"
      :currentSentSearch="searchTerm"
      :searchCount="items.count ? items.count : 0"
      :searchNoun="$INSIGHT('INDEX_QUERIES').TITLE"
      :sortFields="items.fields ? items.fields : {}"
      :isForEntityOrLink="false"
      sortCustomIdField="record_id"
      @sort="sort"
      @search="doSearch"
      @refresh="fetchQueryList(false)"
    >
      <template v-slot:additional-filter-button-toggle>
        <b-button
          :variant="queryFilterToggleButton ? 'success' : 'secondary'"
          v-model="queryFilterToggleButton"
          @click="queryFilterToggleButton = !queryFilterToggleButton"
          class="ml-4"
          >Filter</b-button
        >
      </template>

      <template v-slot:additional-filter>
        <div class="query-filter-form mt-4">
          <div v-show="queryFilterToggleButton">
            <b-card>
              <div class="groups">
                <div class="group">
                  <div class="controls">
                    <span class="filter-title-category">Categories:</span>
                  </div>
                  <div class="checkbox-option">
                    <b-form-group>
                      <b-form-checkbox
                        v-for="category in meta['category']"
                        v-model="selectedCategories"
                        :key="category"
                        :value="category"
                        name="categories-option"
                        class="mb-2"
                      >
                        {{ category }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>

                <div class="group">
                  <div class="controls">
                    <span class="filter-title-category">Access Groups:</span>
                  </div>
                  <div class="checkbox-option">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="selectedAccessGroups"
                        key="O"
                        value="O"
                        name="access-groups-option-public"
                        class="mb-2"
                      >
                        Public
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-for="detail in meta['access_group']"
                        v-model="selectedAccessGroups"
                        :key="detail.gid"
                        :value="detail.gid"
                        name="access-groups-option"
                        class="mb-2"
                      >
                        {{ detail.name }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="search">
                <div class="spacer"></div>
                <b-btn class="mr-2" variant="danger" @click="resetFilter">
                  Cancel
                </b-btn>
                <b-btn
                  variant="primary"
                  @click="fetchQueryList(false)"
                  :disabled="isFilterValid === false"
                >
                  <b-spinner v-if="loading" class="search-icon" small />
                  <span v-else>Search</span>
                </b-btn>
              </div>
            </b-card>
          </div>
        </div>
      </template>
    </search-sort-controls>

    <div class="loader" v-if="hasInitialised === false">
      <b-spinner variant="secondary" large label="Loading..."></b-spinner>
    </div>

    <error-handler
      v-if="error !== false && loading === false"
      :error="displayError"
      :showError="showError"
      variant="danger"
      @dismiss-error="showError = false"
    />

    <div class="results-area" v-if="hasInitialised === true">
      <index-cards
        :items="items.results"
        :searchQuery="searchTerm"
        :sortField="sortBy"
        hasStar="true"
      >
        <template v-slot:name="{ item }">
          <router-link
            :to="{ name: 'generate-query', params: { id: item.record_id } }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:fields="{ item }">
          <div class="field col-sm-3 col-md-2 col-lg-3">
            <div>
              <strong>Model</strong>
            </div>
            <div>
              {{ item.model }}
            </div>
          </div>
          <div class="field col-sm-3 col-md-2 col-lg-3">
            <div>
              <strong>Description</strong>
            </div>
            <div>
              {{
                item.description !== null && item.description !== ''
                  ? item.description
                  : 'No description'
              }}
            </div>
          </div>
        </template>
        <template v-slot:view="{ item }">
          <b-btn
            variant="primary"
            v-b-tooltip:queries.hover
            :title="$LOCAL('ToolTipLabelResults')"
            :to="{ name: 'generate-query', params: { id: item.record_id } }"
          >
            <LaList height="20px" />
          </b-btn>
        </template>
        <template v-slot:edit="{ item }">
          <b-btn
            variant="success"
            v-if="hasPermission($INSIGHT('PERMISSIONS').CHNG)"
            v-b-tooltip:queries.hover
            :title="$LOCAL('ToolTipLabelEdit')"
            :to="{ name: 'edit-query', params: { id: item.record_id } }"
          >
            <LaEdit height="20px" />
          </b-btn>
        </template>
        <template v-slot:delete="{ item }">
          <b-btn
            v-b-tooltip:queries.hover
            :title="$LOCAL('ToolTipLabelDelete')"
            v-if="hasPermission($INSIGHT('PERMISSIONS').DELETE)"
            @click="deleteThisRecord(item)"
            variant="danger"
          >
            <LaTrash height="20px" />
          </b-btn>
        </template>
        <template v-slot:star="{ item }">
          <star
            :id="item.record_id + '-star'"
            height="20px"
            v-b-tooltip:queries.hover
            :title="$LOCAL('ToolTipLabelStar')"
            class="star-icon cursor-pointer"
            @click="toggleFavourite(item)"
          />
        </template>
      </index-cards>
    </div>

    <b-button
      v-if="items.next"
      class="load-more-button mt-2"
      variant="outline"
      @click="fetchQueryList(true)"
    >
      <b-badge pill variant="secondary" v-if="loading === false">{{
        $LOCAL('LoadMore')
      }}</b-badge>
      <b-spinner small v-if="loading === true" label="Loading" />
    </b-button>

    <query-delete-modal
      :queryName="queryToDelete + ' Query'"
      :linkedCharts="getLinkedChartsData()"
      :modalShow="showDeleteModal"
      @ok="processDelete"
      @hide="cancelDelete"
      ref="queryDeleteModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ErrorHandler from '../../components/ErrorHandler'
import IndexCards from '../../components/IndexCards'
import LaList from '@/assets/la-list.svg'
import LaEdit from '@/assets/la-edit.svg'
import LaTrash from '@/assets/la-trash.svg'
import Star from '@/assets/star-solid.svg'
import QueryDeleteModal from '@/modules/insight/components/QueryDeleteModal'
import ToastMessage from '@/utils/toast_message'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'

export default {
  name: 'queries',
  components: {
    SearchSortControls,
    ErrorHandler,
    LaEdit,
    LaTrash,
    IndexCards,
    LaList,
    Star,
    QueryDeleteModal
  },
  data: () => ({
    meta: {},
    loading: true,
    idToDelete: '',
    queryToDelete: '',
    linkedCharts: null,
    showDeleteModal: false,
    page: 1,
    sortBy: ['-create_date', 'record_id'],
    searchTerm: '',
    queryFilterToggleButton: false,
    selectedCategories: [],
    selectedAccessGroups: [],
    showError: false,
    displayError: {
      code: -1,
      message: ''
    }
  }),
  computed: {
    ...mapState('insight/queries', {
      items: 'queries',
      error: 'error',
      queryCRUDPermissions: 'queryCRUDPermissions'
    }),
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('insight', {
      userFavouriteList: 'userFavouriteList'
    }),
    hasInitialised () {
      if (this.hasPermission('view')) {
        return typeof this.items.results === 'object'
      } else {
        return true
      }
      // return typeof this.items.results === 'object'
    },
    isFilterValid () {
      return (
        this.selectedCategories.length > 0 ||
        this.selectedAccessGroups.length > 0
      )
    }
  },
  async beforeMount () {
    await this.getCreateQueryForm()
  },
  async mounted () {
    this.loading = true // Set loading on mount to handle slow data fetching
    await this.retrieveUserData()
    await this.getQueryCRUDPermissions()
    await this.fetchQueryList()
  },
  watch: {
    error: {
      handler: function (newError) {
        if (newError !== false) {
          this.showError = true
          this.displayError = {
            code: newError.code,
            message: newError.message.detail
              ? newError.message.detail
              : newError.message
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('insight/queries', [
      'getQueries',
      'deleteQuery',
      'getLinkedCharts',
      'getQueryCreateForm',
      'getQueryCRUDPermissions'
    ]),
    ...mapActions('insight', [
      'getFavouriteList',
      'makeAsFavourite',
      'removeFromFavourite'
    ]),
    ...mapActions('auth', ['retrieveUserData']),
    async getCreateQueryForm () {
      this.meta = await this.getQueryCreateForm()
    },
    getLinkedChartsData () {
      return this.linkedCharts ? this.linkedCharts : []
    },
    async fetchQueryList (isAppend = false) {
      this.loading = true
      if (isAppend) {
        this.page++
      } else {
        this.page = 1
      }

      const params = {
        page: this.page,
        search: this.searchTerm,
        ordering: this.sortBy.join(','),
        perPage: 10
      }

      if (this.isFilterValid) {
        params.filter = {
          categories: this.selectedCategories.join(','),
          access_groups: this.selectedAccessGroups.join(',')
        }
      }
      await this.getQueries({ params, isAppend })
      await this.checkFavourited()
      this.loading = false
    },
    doSearch (searchTerm) {
      this.searchTerm = searchTerm
      this.fetchQueryList(false)
    },
    cancelDelete () {
      this.showDeleteModal = false
    },
    async deleteThisRecord (item) {
      if (item.record_id) {
        this.idToDelete = item.record_id
        this.queryToDelete = item.name
        this.linkedCharts = await this.getLinkedCharts(this.idToDelete)
        this.showDeleteModal = true
      }
    },
    async processDelete (cascadeOptionSelected = 'cascadeFalse') {
      this.loading = true
      const cascadeDelete = cascadeOptionSelected === 'cascadeTrue'
      const checkDelete = await this.deleteQuery({
        id: this.idToDelete,
        cascadeDelete: cascadeDelete
      })

      let toastText
      const toastMeta = {
        variant: '',
        title: ''
      }
      const h = this.$createElement

      if (checkDelete === true) {
        this.removeFromFavourite({
          username: this.userData.username,
          data: {
            item_type: 'query',
            item_name: this.idToDelete
          }
        })

        if (cascadeDelete === true) {
          ToastMessage.showDeletedSuccessWithPostfixText({
            vueInstance: this,
            name: this.queryToDelete,
            postfixText: this.$INSIGHT('INDEX_QUERIES').TOAST
              .DELETE_WITH_LINKEDCHART.TEXT
          })
        } else {
          ToastMessage.showDeletedSuccess({
            vueInstance: this,
            name: this.queryToDelete
          })
        }
      } else {
        toastText = h('p', { class: ['mb-0'] }, [
          `${this.$INSIGHT('INDEX_QUERIES').ERROR.DELETING_QUERY} `,
          h('strong', {}, this.queryToDelete),
          `. ${this.getErrorMessage()}`
        ])
        toastMeta.variant = 'danger'
        toastMeta.title = 'Error'
        this.$root.$bvToast.toast(toastText, {
          variant: toastMeta.variant,
          title: toastMeta.title,
          autoHideDelay: 5000,
          appendToast: true
        })
      }

      this.fetchQueryList(false)
    },
    getErrorMessage () {
      return this.error.message ? this.error.message : ''
    },
    /**
     * @public
     * @async
     * Toggle the favourite function to set the record to "favourite" if it is unfavourite and vice verse
     * @param {itemData object} itemData
     * @returns {Promise<void>}
     */
    async toggleFavourite (itemData) {
      const data = {
        item_type: this.$INSIGHT('QUERY').FAVOURITE.TYPE,
        item_name: itemData.record_id
      }

      const element = document.getElementById(`${itemData.record_id}-star`)

      if (
        element.dataset.favourite ===
        this.$INSIGHT('QUERY').FAVOURITE.UNFAVOURITE
      ) {
        await this.makeAsFavourite({
          username: this.userData.username,
          data: data
        })
        element.dataset.favourite = this.$INSIGHT('QUERY').FAVOURITE.FAVOURITE
      } else {
        await this.removeFromFavourite({
          username: this.userData.username,
          data: data
        })
        element.dataset.favourite = this.$INSIGHT('QUERY').FAVOURITE.UNFAVOURITE
      }
    },
    /**
     * @public
     * @async
     * Retrieve the mandatory and user favourite queries and set the favourite icon accordingly
     * @returns {Promise<void>}
     */
    async checkFavourited () {
      await this.getFavouriteList({
        username: this.userData.username,
        itemType: this.$INSIGHT('QUERY').FAVOURITE.TYPE
      })
      const userFavouriteItemResult = []
      const mandatoryFavouriteItemResult = []

      for (const userFavouriteItem of this.userFavouriteList.results) {
        // If the favourite item belongs to no user, it is a mandatory favourite
        if (userFavouriteItem.user === null) {
          mandatoryFavouriteItemResult.push(userFavouriteItem.item_name)
        } else {
          userFavouriteItemResult.push(userFavouriteItem.item_name)
        }
      }
      const items = this.items.results ? this.items.results : []
      for (const item of items) {
        if (mandatoryFavouriteItemResult.includes(item.record_id.toString())) {
          document.getElementById(
            `${item.record_id}-star`
          ).dataset.favourite = this.$INSIGHT('QUERY').FAVOURITE.MANDATORY
        } else if (
          userFavouriteItemResult.includes(item.record_id.toString())
        ) {
          document.getElementById(
            `${item.record_id}-star`
          ).dataset.favourite = this.$INSIGHT('QUERY').FAVOURITE.FAVOURITE
        } else {
          document.getElementById(
            `${item.record_id}-star`
          ).dataset.favourite = this.$INSIGHT('QUERY').FAVOURITE.UNFAVOURITE
        }
      }
    },
    resetFilter () {
      this.selectedCategories = []
      this.selectedAccessGroups = []
      this.queryFilterToggleButton = false
      this.fetchQueryList()
    },
    hasPermission (action) {
      return this.queryCRUDPermissions.includes(
        `insight.${action}_ibasedatastore`
      )
    },
    sort (sortBy) {
      this.sortBy = sortBy
      this.fetchQueryList(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.queries {
  position: relative;
}

.search-bar {
  // Self
  padding-left: 0;

  // Children
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  text-align: left;
  padding: 0 15px 0 15px;
  align-self: center;
  line-height: 1;
  font-size: 20px;
  font-weight: 400;
}

.search-info {
  padding-left: 2px;
  font-size: 1.2em;
}

.search-and-filter {
  display: flex;
}

.refresh-and-sort {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.query-filter-form {
  text-align: left;
  margin: 0 0 20px 0;

  .sub-title {
    padding: 0 0 15px 0;
  }

  .filter-title-category {
    font-weight: bold;
    font-size: 1.1em;
  }
}

/deep/ .results {
  margin: -14px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.groups {
  display: flex;
  border: 1px solid #d3d3d3;
  /*padding: 15px;*/
}

.group {
  border: 1px solid #d3d3d3;
  padding: 0;
  /*margin-bottom: 15px;*/
  margin: 15px;
  width: 50%;

  &:last-of-type {
    margin-left: 0;
  }

  .controls {
    margin: 10px 15px;
  }
}

.checkbox-option {
  padding-left: 15px;
  max-height: 250px;
  overflow: auto;
}

.form-group {
  margin-bottom: 0;
}

.search {
  display: flex;
  justify-content: space-between;
  justify-items: flex-end;
  align-items: center;
  margin-top: 1rem;

  .spacer {
    flex-grow: 1;
  }
}

.star-icon[data-favourite='favourite'] {
  fill: $favourited;
}

.star-icon[data-favourite='mandatory'] {
  display: none;
}
</style>
