<template>
  <div class="root">
    <portal to="top-bar">
      <div class="title">
        <h1 id="query-detail-name" :aria-label="queryDetail.name">{{ truncateQueryDetailName }}</h1>
        <b-tooltip v-if="showQueryTooltip" target="query-detail-name">{{ queryDetail.name }}</b-tooltip>
      </div>
      <div id="top-action-button">
        <b-button-group size="sm" v-if="!loading">
          <b-dropdown class="export-dataset-dropdown" variant="outline-primary" size="sm" v-if="!error && !queryError">
            <template v-slot:button-content>
              <b-spinner v-if="loadingExport" small/>
              <span v-else>{{$LOCAL('COMMON_WORD').EXPORT}}</span>
            </template>
            <b-dropdown-item
              v-if="Object.keys(models).length === 0"
              disabled
            >{{$INSIGHT('ERROR').NO_AVAILABLE_ITEMS}}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="Object.keys(models).length !== 0"
              @click="exportThisRecord"
            >{{$LOCAL('COMMON_WORD').ALL}}
            </b-dropdown-item>
            <b-dropdown-item
              v-for="item of models"
              :key="item.label"
              :value="item"
              @click="exportThisItem(item)"
            >{{ item.label }}
            </b-dropdown-item>
          </b-dropdown>

          <b-btn v-if="!error && !queryError && queryHasPermission($INSIGHT('PERMISSIONS').CHNG)" variant="outline-success"
                 :to="{ name: 'edit-query', params: { id: this.$route.params.id } }">
            {{$LOCAL('COMMON_WORD').EDIT}}
          </b-btn>
          <b-btn v-if="!error && !queryError && queryHasPermission($INSIGHT('PERMISSIONS').DELETE)" @click="deleteThisQuery(queryDetail)" variant="outline-danger">
            {{$LOCAL('COMMON_WORD').DELETE}}
          </b-btn>
          <b-btn v-if="error || queryError" class="ml-2" variant="outline-primary" @click="$router.go(-1)">
            {{$LOCAL('COMMON_WORD').BACK}}
          </b-btn>
        </b-button-group>
      </div>
    </portal>

    <error-handler
      v-if="(queryError !== false) || customError500"
      :error="queryError || customError500"
      :showError="(queryError !== false) || customError500.show"
      variant="danger"
    />

    <div class="search-tools" :class="{ 'fade-in-fast': !items }" id="queries-result"
       v-if="!loading && !error && !queryError">
      <b-form v-on:submit.prevent="fetchByModel()" inline>
        <b-input-group>
          <b-input
            v-model="searchForm"
            ref="searchTextInput"
          />
          <span class="delete-button" @click="searchForm = '', $refs.searchTextInput.focus()"
            v-bind:style="{ display : searchForm ? 'unset' : 'none' }"><LaTimes
            height="20px"/></span>
          <template v-slot:append>
            <b-button id="submit-search-button" class="mr-3" type="submit" variant="primary">
              <b-spinner v-if="loading && loadingGenerate" small/>
                <span v-else><SearchIcon class="search-icon"/></span>
            </b-button>
          </template>
        </b-input-group>
        <b-button
            v-if="inputParameterStatus"
            :variant="inputParameterToggleButton ? 'success' : 'secondary'"
            v-model="inputParameterToggleButton"
            @click="inputParameterToggleButton = !inputParameterToggleButton"
          >Input Parameters
        </b-button>
        <div class="filter-switch mb-3">
          <div v-b-tooltip:queries-result.hover title="Remove results where the entities are linked to itself.">Remove Self Linked <span class="info-symbol">&#9432;</span></div>
          <div>
            <b-form-checkbox style="margin-left: auto" v-model="isUsingAdditionalFilter" name="check-button" switch size="lg"></b-form-checkbox>
          </div>
        </div>
      </b-form>

      <div class="input-parameters-form" v-if="inputParameterStatus">
        <div v-show="inputParameterToggleButton">
          <b-card class="input-parameters-form">
            <div class="summary">
              Input Parameters:
            </div>
            <div class="groups pb-4 mb-3">
              <b-card class="group" v-for="(item, itemIndex) of inputParametersItem.items" :key="'group-' + itemIndex">
                <h5 v-b-toggle="'model-' + itemIndex">{{ item.name }}</h5>
                <b-collapse visible :id="'model-' + itemIndex" class="conditions">
                  <div class="row field" v-for="(field, fieldIndex) of item.parameters"
                       :key="'field-' + itemIndex + '-' + fieldIndex">
                    <div class="col-7">
                      <div class="row">
                        <div class="col-6">
                          {{ field.field_label }}
                        </div>
                        <div class="col-6">
                          {{ field.operator_label }}
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <input-field-form :fieldIndex="fieldIndex"
                                        page="generatePage"
                                        :operatorType="field.operator_type"
                                        :fieldChoices="field.field_meta ? field.field_meta.choices : []"
                                        :field="field"
                                        :columnType="field.field_type"
                                        :listOfMonth="listOfMonth"
                                        :listOfDay="listOfDay"
                                        v-model="field.value" />
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </div>
            <div class="groups-from-nested-query pt-2 pb-3" v-for="(group, groupIndex) of inputParametersItem.nested_input_parameters" :key="`nested-groups-${groupIndex}`">
              <h6>Source: {{ group.name }}</h6>
              <b-card class="group" v-for="(item, itemIndex) of group.items" :key="`nested-group-${groupIndex}-${itemIndex}`">
                <h5 v-b-toggle="`nested-model-${groupIndex}-${itemIndex}`">{{ item.name }}</h5>
                <b-collapse visible :id="`nested-model-${groupIndex}-${itemIndex}`" class="conditions">
                  <div class="row field" v-for="(field, fieldIndex) of item.parameters"
                       :key="`nested-field-${groupIndex}-${itemIndex}-${fieldIndex}`">
                    <div class="col-7">
                      <div class="row">
                        <div class="col-6">
                          {{ field.field_label }}
                        </div>
                        <div class="col-6">
                          {{ field.operator_label }}
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <input-field-form :fieldIndex="fieldIndex"
                                        page="generatePage"
                                        :operatorType="field.operator_type"
                                        :fieldChoices="field.field_meta ? field.field_meta.choices : []"
                                        :field="field"
                                        :columnType="field.field_type"
                                        v-model="field.value" />
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </div>
            <div class="submit">
              <div class="spacer"></div>
              <b-btn variant="primary" @click="submit" :disabled="isValid === false">
                Submit
              </b-btn>
            </div>
          </b-card>
        </div>
      </div>

      <div v-for="item in this.listOfQueryResultFilterButtons" :key='item.output_model_key' class="text-left">
        <h2><LaTag height="35px"/>Output Model: {{item.output_model_key}}</h2>
        <div class="entity-count-button">
          <span v-for="button of item.button_list" :key="button.label" :value="button">
              <b-btn size="sm" pill class="entity-count-button-spacing" :pressed="button.pressed" :disabled="loading" v-on:click="changeModel(button)" variant="outline-dark">{{ button.label }}
                <b-badge pill variant="secondary">
                  <div v-if="loadingGenerate && button.pressed"><b-spinner small/></div>
                  <div v-else-if="models[button.index_position] && (models[button.index_position].item_count!== button.item_count)"><LaFilter height="18px"/>{{models[button.index_position].item_count}}/{{button.item_count}}</div>
                  <div v-else-if="models[button.index_position] && (models[button.index_position].item_count=== button.item_count)">{{button.item_count}}</div>
                </b-badge>
              </b-btn>
          </span>
        </div>
      </div>
    </div>

    <div class="loader" v-if="loadingGenerate">
      <b-spinner variant="secondary" large label="Loading..."/>
    </div>
    <div class="results-area" v-else-if="!error && !queryError">
      <div class="header" :class="{ 'fade-in-fast': !items }">
        <div class="display-result-count-badge">
            <span v-if="Object.keys(models).length === 0">
              <span v-if="inputParameterStatus">
                <h3><b-badge variant="secondary">Input the parameters to generate the dataset</b-badge></h3>
              </span>
              <span v-else>
                <h3><b-badge variant="secondary">No available output models</b-badge></h3>
              </span>
            </span>
          <span v-else>
              <h3>
                <b-badge variant="secondary"> Showing {{ outputItem.count }}/{{ totalDatasetNote }} {{ searchNoteMap[this.selectedModel.id] }}</b-badge>
              </h3>
            </span>
        </div>
      </div>

      <index-cards :items="outputItem.results" :searchQuery="searchHighlighTextMap[this.selectedModel.id]"
                   :sortField="sortBy" :loading="loading" :fields="outputItem.fields">
        <template v-slot:name="{ item }">
          <router-link :to="getShowModelRoute(item, outputItem.model, outputItem.is_entity)">
            {{headerField(item)}}
          </router-link>
        </template>
        <template v-slot:view="{ item }" v-if="currentModelPermissions.read">
          <b-btn variant="primary" :to="getShowModelRoute(item, outputItem.model, outputItem.is_entity)"
                 v-b-tooltip:queries-result.hover :title="$LOCAL('ToolTipLabelView')"
                 :aria-label="`${$LOCAL('AriaLabelView')} ${headerField(item)}`">
            <LaEye height="20px"/>
          </b-btn>
        </template>
        <template v-slot:edit="{ item }" v-if="currentModelPermissions.update">
          <b-btn variant="success" :to="getEditModelRoute(item, outputItem.model, outputItem.is_entity)"
                 v-b-tooltip:queries-result.hover :title="$LOCAL('ToolTipLabelEdit')"
                 :aria-label="`${$LOCAL('AriaLabelEdit')} ${headerField(item)}`">
            <LaEdit height="20px"/>
          </b-btn>
        </template>
        <template v-slot:delete="{ item }" v-if="currentModelPermissions.delete">
          <b-btn @click="deleteThisRecord(item.unique_id, outputItem.model)" variant="danger"
                 v-b-tooltip:queries-result.hover :title="$LOCAL('ToolTipLabelDelete')"
                 :aria-label="`${$LOCAL('AriaLabelDelete')} ${headerField(item)}`">
            <LaTrash height="20px"/>
          </b-btn>
        </template>
      </index-cards>
      <div class="load-more" v-if="outputItem.results.length < outputItem.count">
        <b-button class="load-more-button" variant="outline" @click="next()">
          <span v-if="loadingLoadMore === false"><b-badge pill variant="secondary">{{$LOCAL('LoadMore')}}</b-badge></span>
          <b-spinner small v-if="loadingLoadMore === true" label="Loading"/>
        </b-button>
      </div>
    </div>

    <delete-modal
      :item="modelToDelete"
      :itemId="idToDelete"
      :modalShow="showDeleteModal"
      @ok="processDelete"
      @hide="cancelDelete"
      ref="deleteModal"
    />

    <query-delete-modal
      :queryName="modelToDelete + ' Query'"
      :linkedCharts="getLinkedChartsData()"
      :modalShow="showQueryDeleteModal"
      @ok="processDelete"
      @hide="cancelDelete"
      ref="queryDeleteModal"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import DeleteModal from '../../components/DeleteModal'
import IndexCards from '../../components/IndexCards'
import LaEdit from '@/assets/la-edit.svg'
import LaEye from '@/assets/la-eye.svg'
import LaFilter from '@/assets/filter-solid-white.svg'
import LaTrash from '@/assets/la-trash.svg'
import LaTimes from '@/assets/la-times.svg'
import LaTag from '@/assets/tag-solid.svg'
import SearchIcon from '@/assets/search-icon.svg'
import QueryService from '../../services/query.service'
import QueryDeleteModal from '@/modules/insight/components/QueryDeleteModal'
import ToastMessage from '@/utils/toast_message'
import ErrorHandler from '../../components/ErrorHandler'
import InputFieldForm from '../../components/InputFieldForm'
import HeaderLabelFormatterMixin from '@/modules/insight/mixins/HeaderLabelFormatterMixin'

export default {
  mixins: [HeaderLabelFormatterMixin],
  name: 'generate-data',
  components: {
    IndexCards,
    SearchIcon,
    LaEye,
    LaEdit,
    LaTrash,
    LaTimes,
    DeleteModal,
    LaTag,
    LaFilter,
    QueryDeleteModal,
    ErrorHandler,
    InputFieldForm
  },
  data: () => ({
    loading: true,
    loadingExport: false,
    loadingGenerate: true,
    loadingLoadMore: false,
    pageByIndex: {},
    perPage: 25,
    sortBy: ['-create_date', 'unique_id'],
    idToDelete: '',
    modelToDelete: '',
    queryRecordIdToDelete: '',
    modelOrQueryToDelete: '',
    showDeleteModal: false,
    showQueryDeleteModal: false,
    linkedCharts: null,
    searchForm: '',
    searchFormMap: {},
    searchNoteMap: {},
    searchHighlighTextMap: {},
    queryDetail: {},
    totalDatasetNote: '',
    selectedModel: { id: '', name: '', label: '' },
    inputParameterToggleButton: true,
    inputParameterStatus: false,
    inputParametersItem: { items: [], nested_input_parameters: [] },
    listOfQueryResultFilterButtons: [],
    listOfMonth: [
      { value: 1, text: Vue.prototype.$INSIGHT('MISC').MONTH.JANUARY },
      { value: 2, text: Vue.prototype.$INSIGHT('MISC').MONTH.FEBRUARY },
      { value: 3, text: Vue.prototype.$INSIGHT('MISC').MONTH.MARCH },
      { value: 4, text: Vue.prototype.$INSIGHT('MISC').MONTH.APRIL },
      { value: 5, text: Vue.prototype.$INSIGHT('MISC').MONTH.MAY },
      { value: 6, text: Vue.prototype.$INSIGHT('MISC').MONTH.JUNE },
      { value: 7, text: Vue.prototype.$INSIGHT('MISC').MONTH.JULY },
      { value: 8, text: Vue.prototype.$INSIGHT('MISC').MONTH.AUGUST },
      { value: 9, text: Vue.prototype.$INSIGHT('MISC').MONTH.SEPTEMBER },
      { value: 10, text: Vue.prototype.$INSIGHT('MISC').MONTH.OCTOBER },
      { value: 11, text: Vue.prototype.$INSIGHT('MISC').MONTH.NOVEMBER },
      { value: 12, text: Vue.prototype.$INSIGHT('MISC').MONTH.DECEMBER }
    ],
    listOfDay: [
      { value: '1', text: Vue.prototype.$INSIGHT('MISC').DAY.SUNDAY },
      { value: '2', text: Vue.prototype.$INSIGHT('MISC').DAY.MONDAY },
      { value: '3', text: Vue.prototype.$INSIGHT('MISC').DAY.TUESDAY },
      { value: '4', text: Vue.prototype.$INSIGHT('MISC').DAY.WEDNESDAY },
      { value: '5', text: Vue.prototype.$INSIGHT('MISC').DAY.THURSDAY },
      { value: '6', text: Vue.prototype.$INSIGHT('MISC').DAY.FRIDAY },
      { value: '7', text: Vue.prototype.$INSIGHT('MISC').DAY.SATURDAY }
    ],
    counter: 0,
    buttonPressed: false,
    isUsingAdditionalFilter: false,
    currentModelPermissions: {
      read: true,
      update: true,
      delete: true
    },
    componentCustomError: false
  }),
  computed: {
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('insight/queries', {
      query: 'queries',
      queryError: 'error',
      queryCRUDPermissions: 'queryCRUDPermissions'
    }),
    ...mapState('insight', {
      items: 'records',
      error: 'error',
      entities: 'entities',
      links: 'links'
    }),
    models () {
      if (Object.prototype.hasOwnProperty.call(this.query, 'count')) return []
      if (!this.query) return []
      const listOfItem = []
      for (const name in this.query) {
        const item = this.query[name]
        listOfItem.push({ id: item.item_name, model: item.model, label: item.model_name, item_count: item.count })
      }
      return listOfItem
    },
    showQueryTooltip () {
      if (this.queryDetail.name) return this.queryDetail.name.length > this.$INSIGHT('QUERY').DISPLAY_LIMIT
      return false
    },
    truncateQueryDetailName () {
      if (this.queryDetail.name) {
        const queryNameLength = this.queryDetail.name.length
        const maxLength = this.$INSIGHT('QUERY').DISPLAY_LIMIT
        if (queryNameLength > maxLength) {
          const truncatedQueryName = this.queryDetail.name.substring(0, maxLength)
          return `${truncatedQueryName}...`
        } else {
          return this.queryDetail.name
        }
      }
      return ''
    },
    outputItem () {
      if (!this.query[this.selectedModel.id]) return { count: 0, results: [], meta: {} }
      return this.query[this.selectedModel.id]
    },
    isValid () {
      let i = this.counter // just here to force a re-evaluation of the computed property as vue doesn't always trigger when new arrays are added
      i = 0
      for (const item of this.inputParametersItem.items) {
        for (const field of item.parameters) {
          if (typeof field.value === 'object') {
            if (field.value[0] === '' || field.value[1] === '') {
              return false
            } else if (field.value.length === 0) {
              return false
            }
          } else {
            if (field.value === '') return false
          }
        }
      }
      for (const querySource of this.inputParametersItem.nested_input_parameters) {
        for (const item of querySource.items) {
          for (const field of item.parameters) {
            if (typeof field.value === 'object') {
              if (field.value[0] === '' || field.value[1] === '') {
                return false
              } else if (field.value.length === 0) {
                return false
              }
            } else {
              if (field.value === '') return false
            }
          }
        }
      }
      return i === 0 // just here to fix linting error
    },
    customError500 () {
      const customMessage = this.componentCustomError.toString()
      const serverErrorMsg = this.$INSIGHT('ERROR').CODE_500.IDENTIFIER
      if (customMessage.includes(serverErrorMsg)) {
        return { code: 500, message: customMessage, show: true }
      }
      return { show: false }
    }
  },
  async mounted () {
    await this.checkQueryPermissions()
    await this.checkQuery()
    this.fetchModelList()
  },
  methods: {
    ...mapActions('insight/queries', [
      'generateQuery',
      'generateQueryByModel',
      'exportGeneratedDataQuery',
      'deleteQuery',
      'getLinkedCharts',
      'getInputParametersQuery',
      'getQueryCRUDPermissions'
    ]),
    ...mapActions('insight', [
      'deleteRecord',
      'removeFromFavourite',
      'clearError',
      'getEntities',
      'getLinks'
    ]),
    parseOptions (field) {
      if (field.field_meta?.choices) {
        return field.field_meta?.choices
      } else {
        return []
      }
    },
    getRadioFieldOptions (metaOptions) {
      return metaOptions.map((choice) => ({ text: choice.text, value: choice.text }))
    },
    checkQueryPermissions () {
      // for easier testing
      this.getQueryCRUDPermissions()
    },
    fetchModelList () {
      this.getEntities(false)
      this.getLinks(false)
    },
    async checkCurrentModelPermissions (model) {
      const modelInfo = this.entities[model] || this.links[model]
      const permissions = modelInfo?.permissions || []
      this.currentModelPermissions.read = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ))
      this.currentModelPermissions.update = !permissions.find(permission => {
        return permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').UPDATE) ||
               permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').READ)
      })
      this.currentModelPermissions.delete = !permissions.find(permission => permission.startsWith(this.$INSIGHT('RESTRICTED_PERMISSIONS').DELETE))
    },
    async checkQuery () {
      // Check is there any @ condition or not
      this.clearError()
      const checkResult = await this.getInputParametersQuery({ id: this.$route.params.id })
      this.loading = false
      if (checkResult) {
        if (checkResult.items.length === 0 && checkResult.nested_input_parameters.length === 0) {
          this.fetch()
        } else {
          this.loadingGenerate = false
          this.inputParametersItem = checkResult
          this.inputParameterStatus = true
          this.queryDetail = { record_id: this.$route.params.id, name: checkResult.name, items: [] }
        }
      } else {
        this.loadingGenerate = false
      }
    },
    async fetch () {
      try {
        this.loadingGenerate = true
        const response = await QueryService.getQuery(this.$route.params.id)

        const searchButton = document.getElementById('submit-search-button')
        if (searchButton) {
          searchButton.disabled = true
        }

        this.queryDetail = response
        const dataset = await this.generateQuery({
          id: this.$route.params.id,
          params: {
            search: '',
            searchGeneratedData: this.searchForm,
            inputParameters: this.inputParametersRequest,
            isUsingAdditionalFilter: this.isUsingAdditionalFilter
          }
        })

        this.totalDatasetNote = `${dataset.total} ${this.$INSIGHT('MISC').COMMON_PHRASE.TOTAL_RESULTS}`
        if (this.models.length > 0) {
          this.selectedModel = this.models[0]
        }
        if (this.searchForm === '') {
          this.searchNoteMap[this.selectedModel.id] = ''
        } else {
          this.searchHighlighTextMap[this.selectedModel.id] = this.searchForm
          this.searchNoteMap[this.selectedModel.id] = `${this.$LOCAL('COMMON_WORD').FOR} : ${this.searchForm}`
          this.searchFormMap[this.selectedModel.id] = this.searchForm
        }

        if (searchButton) {
          searchButton.disabled = false
        }

        this.loadingGenerate = false
        this.listOfQueryResultFilterButtons = []
        this.getEntityCountButtonList(dataset)
        this.updateQueryResultFilterButton(this.selectedModel.id)
      } catch (error) {
        this.loadingGenerate = false
        this.componentCustomError = error
      }
    },
    async fetchByModel () {
      this.loadingGenerate = true
      const searchButton = document.getElementById('submit-search-button')
      if (searchButton) {
        searchButton.disabled = true
      }

      await this.generateQueryByModel({
        id: this.$route.params.id,
        params: {
          id: this.selectedModel.id,
          search: '',
          searchGeneratedData: this.searchForm,
          inputParameters: this.inputParametersRequest,
          isUsingAdditionalFilter: this.isUsingAdditionalFilter
        },
        itemId: this.selectedModel.id
      })

      this.searchHighlighTextMap[this.selectedModel.id] = this.searchForm
      this.searchNoteMap[this.selectedModel.id] = this.searchForm ? `${this.$LOCAL('COMMON_WORD').FOR} : ${this.searchForm}` : ''
      this.searchFormMap[this.selectedModel.id] = this.searchForm
      this.pageByIndex[this.selectedModel.id] = 2
      if (searchButton) {
        searchButton.disabled = false
      }
      this.loadingGenerate = false
    },
    getShowModelRoute (item, model, isEntity) {
      if (!this.currentModelPermissions.read) {
        return {}
      } else if (isEntity) {
        return { name: 'entity', params: { id: item.unique_id, model: model } }
      } else {
        return { name: 'link', params: { id: item.unique_id, model: model } }
      }
    },
    getEditModelRoute (item, model, isEntity) {
      if (isEntity) {
        return { name: 'edit-entity', params: { id: item.unique_id, model: model } }
      } else {
        return { name: 'edit-link', params: { id: item.unique_id, model: model } }
      }
    },
    deleteThisRecord (uniqueId, model) {
      this.idToDelete = uniqueId
      this.modelToDelete = model
      this.showDeleteModal = true
      this.modelOrQueryToDelete = 'model'
    },
    getLinkedChartsData () {
      return this.linkedCharts ? this.linkedCharts : []
    },
    async deleteThisQuery (query) {
      if (query.record_id) {
        this.queryRecordIdToDelete = query.record_id
        this.modelToDelete = query.name
        this.linkedCharts = await this.getLinkedCharts(this.queryRecordIdToDelete)
        this.showQueryDeleteModal = true
        this.modelOrQueryToDelete = 'query'
      }
    },
    async deleteModel () {
      const response = await this.deleteRecord({ model: this.modelToDelete, id: this.idToDelete })
      this.fetchByModel()
      this.$refs.deleteModal.recordDeleted(response)
    },
    async deleteCurrentQuery (cascadeOptionSelected) {
      let cascadeDelete = false
      if (cascadeOptionSelected === 'cascadeTrue') {
        cascadeDelete = true
      } else {
        cascadeDelete = false
      }

      const checkDelete = await this.deleteQuery({ id: this.queryRecordIdToDelete, cascadeDelete: cascadeDelete })

      let toastText
      const toastMeta = {
        variant: '',
        title: ''
      }
      const h = this.$createElement

      if (checkDelete === true) {
        await this.removeFromFavourite({
          username: this.userData.username,
          data: {
            item_type: 'query',
            item_name: this.queryRecordIdToDelete
          }
        })

        if (cascadeDelete === true) {
          ToastMessage.showDeletedSuccessWithPostfixText({
            vueInstance: this,
            name: this.modelToDelete,
            postfixText: this.$INSIGHT('INDEX_QUERIES').TOAST.DELETE_WITH_LINKEDCHART.TEXT
          })
        } else {
          ToastMessage.showDeletedSuccess({
            vueInstance: this,
            name: this.modelToDelete
          })
        }
      } else {
        toastText = h('p', { class: ['mb-0'] },
          [
            `${this.$INSIGHT('INDEX_QUERIES').ERROR.DELETING_QUERY} `,
            h('strong', {}, this.modelToDelete),
            `. ${this.getQueryErrorMessage()}`
          ]
        )
        toastMeta.variant = 'danger'
        toastMeta.title = 'Error'
        this.$root.$bvToast.toast(toastText, {
          variant: toastMeta.variant,
          title: toastMeta.title,
          autoHideDelay: 5000,
          appendToast: true
        })
      }

      await this.$router.push({
        name: 'queries'
      })
    },
    getQueryErrorMessage () {
      return this.queryError.message ? this.queryError.message : ''
    },
    async processDelete (cascadeOptionSelected = 'cascadeFalse') {
      if (this.modelOrQueryToDelete === 'query') {
        await this.deleteCurrentQuery(cascadeOptionSelected)
      } else {
        await this.deleteModel()
      }
    },
    cancelDelete () {
      this.showQueryDeleteModal = false
      this.showDeleteModal = false
    },
    async next () {
      this.loadingLoadMore = true
      const page = this.pageByIndex[this.selectedModel.id] ? this.pageByIndex[this.selectedModel.id] : 2
      await this.generateQuery({
        id: this.$route.params.id,
        params: {
          id: this.selectedModel.id,
          page: page,
          search: '',
          searchGeneratedData: this.searchForm,
          inputParameters: this.inputParametersRequest,
          isUsingAdditionalFilter: this.isUsingAdditionalFilter
        },
        isAppend: true,
        itemId: this.selectedModel.id
      })
      this.pageByIndex[this.selectedModel.id] = page + 1
      this.loadingLoadMore = false
    },
    changeModel (itemToUpdate) {
      this.selectedModel = itemToUpdate
      if (!this.loadingGenerate) {
        if (this.searchFormMap[this.selectedModel.id]) {
          this.searchForm = this.searchFormMap[this.selectedModel.id]
        } else {
          this.searchForm = ''
        }
      }
      this.updateQueryResultFilterButton(this.selectedModel.id)
    },
    headerField (item) {
      let labelString = this.outputItem.meta.header_field
      labelString = this.labelFormatterOutput(item, labelString)
      return labelString
    },
    async exportThisRecord () {
      this.loadingExport = true
      const childNodes = document.getElementsByClassName('export-dataset-dropdown')[0].childNodes
      let dropdownButton = false
      for (const child of childNodes) {
        if (child.tagName === 'BUTTON') {
          dropdownButton = child
        }
      }
      if (dropdownButton) dropdownButton.disabled = true

      const response = await this.exportGeneratedDataQuery({
        id: this.$route.params.id,
        params: {
          download: true,
          inputParameters: this.inputParametersRequest,
          isUsingAdditionalFilter: this.isUsingAdditionalFilter
        }
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const filename = response.headers['content-disposition'].match(/filename="(.*?)"/)
      link.setAttribute('download', filename[1])
      link.click()
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url)
      }, 100)
      link.remove()

      if (dropdownButton) dropdownButton.disabled = false
      this.loadingExport = false
    },
    async exportThisItem (item) {
      this.loadingExport = true
      const childNodes = document.getElementsByClassName('export-dataset-dropdown')[0].childNodes
      let dropdownButton = false
      for (const child of childNodes) {
        if (child.tagName === 'BUTTON') {
          dropdownButton = child
        }
      }
      if (dropdownButton) dropdownButton.disabled = true

      const response = await this.exportGeneratedDataQuery({
        id: this.$route.params.id,
        params: {
          id: item.id,
          download: true,
          inputParameters: this.inputParametersRequest,
          isUsingAdditionalFilter: this.isUsingAdditionalFilter
        }
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const filename = response.headers['content-disposition'].match(/filename="(.*?)"/)
      link.setAttribute('download', filename[1])
      link.click()
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url)
      }, 100)
      link.remove()

      if (dropdownButton) dropdownButton.disabled = false
      this.loadingExport = false
    },
    async submit () {
      this.loadingGenerate = true
      this.searchForm = ''
      this.searchHighlighTextMap = {}
      const inputParameters = {}
      const inputParametersThisQuery = {}
      for (const item of this.inputParametersItem.items) {
        inputParametersThisQuery[item.name] = {}
        for (const field of item.parameters) {
          let value1, value2
          if (typeof field.value === 'object') {
            if (field.operator_type === 'list') {
              value1 = field.value.join('|')
              value2 = null
            } else {
              value1 = field.value[0]
              value2 = field.value[1]
            }
          } else {
            value1 = field.value
            value2 = null
          }
          inputParametersThisQuery[item.name][field.field_name] = {
            value1, value2
          }
        }
      }
      for (const querySource of this.inputParametersItem.nested_input_parameters) {
        const inputParametersQuerySoure = {}
        for (const item of querySource.items) {
          inputParametersQuerySoure[item.name] = {}
          for (const field of item.parameters) {
            let value1, value2
            if (typeof field.value === 'object') {
              if (field.operator_type === 'list') {
                value1 = field.value.join('|')
                value2 = null
              } else {
                value1 = field.value[0]
                value2 = field.value[1]
              }
            } else {
              value1 = field.value
              value2 = null
            }
            inputParametersQuerySoure[item.name][field.field_name] = {
              value1, value2
            }
          }
        }
        inputParameters[querySource.record_id] = inputParametersQuerySoure
      }

      inputParameters[this.$route.params.id] = inputParametersThisQuery
      this.inputParametersRequest = inputParameters
      await this.fetch()
      this.inputParameterToggleButton = false
      this.loadingGenerate = false
    },
    getEntityCountButtonList (dataset) {
      let outputModelList = []
      let buttonList = []
      let datasetItemOutputModel
      let positionIndex = 0
      // This is to iterate through the dataset to retrieve the output model from .item_name.
      dataset.items.forEach((item) => {
        if (item.item_name.includes('-')) {
          outputModelList.push(item.item_name.split('-')[0].trim())
        } else {
          outputModelList.push(item.item_name)
        }
      })
      // Remove duplicate output model, keeping only the unique ones
      outputModelList = [...new Set(outputModelList)]

      // Sorting the button into same group of output model
      // Iterate through the output model list
      outputModelList.forEach((outputModel) => {
        // Iterate through the entire entity/link dataset returns from the query
        dataset.items.forEach((item) => {
          if (item.item_name.includes('-')) {
            datasetItemOutputModel = item.item_name.split('-')[0].trim()
          } else {
            datasetItemOutputModel = item.item_name
          }
          // Evaluate if the output model between the outputmodel list and dataset are the same, if true, add them in
          if (outputModel === datasetItemOutputModel) {
            buttonList.push({
              id: item.item_name,
              model: item.model,
              label: item.model_name,
              item_count: item.count,
              pressed: false,
              // As the button list is split according to the output model into different buttonList, index_position determines the index in models as the whole.
              index_position: positionIndex++
            })
          }
        })
        // Finally, piecing up the filter button list
        this.listOfQueryResultFilterButtons.push({
          output_model_key: outputModel,
          button_list: buttonList
        })
        buttonList = []
      })
    },
    updateQueryResultFilterButton (selectedModeId) {
      // Update the 'pressed' variable in listOfQueryResultButtons so that it stay pressed when selected
      this.listOfQueryResultFilterButtons.forEach((buttonList) => {
        buttonList.button_list.forEach((button) => {
          button.pressed = button.id === selectedModeId
        })
      })
    },
    resetSearchForm () {
      this.listOfQueryResultFilterButtons = []
      this.searchForm = ''
      this.searchFormMap = {}
      this.searchNoteMap = {}
      this.searchHighlighTextMap = {}
    },
    queryHasPermission (action) {
      return this.queryCRUDPermissions.includes(
        `insight.${action}_ibasedatastore`
      )
    }
  },
  watch: {
    isUsingAdditionalFilter () {
      if (this.listOfQueryResultFilterButtons.length > 0) {
        this.resetSearchForm()
        this.fetch()
      }
    },
    queryError (error) {
      if (error.code === 404) {
        this.$router.push({ name: 'queries' })
      }
    },
    selectedModel: {
      deep: true,
      immediate: true,
      handler: function (modelInfo) {
        const modelName = modelInfo.model
        if (modelName) {
          this.checkCurrentModelPermissions(modelName)
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  h2, h3 {
    font-size: 1.5rem;
  }

  .root {
    position: relative;
  }

  .refresh {
    &.loading {
      /deep/ svg {
        animation: spinner 1.2s linear infinite;
      }
    }

    /deep/ svg {
      position: absolute;
      top: 2px;
      right: -21px;
      cursor: pointer;
      fill: #9E9E9E;
      transition: all 150ms ease-in-out;

      &:hover {
        fill: #000;
      }
    }
  }

  .title {
    text-align: left;
    padding: 0 15px 0 15px;
    align-self: center;
    line-height: 1;
    font-size: 20px;
    font-weight: 400;
  }

  .header {
    display: flex;
    margin: 0 -15px 0px -15px;
    justify-content: space-between;

    > div {
      padding: 0 15px;
    }

    .entity {
      margin-bottom: 14px;
      text-align: left;
      align-self: center;
      line-height: 1;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .search-bar {
    margin-bottom: 28px;
  }

  .filter-switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    color: #6c757d;

    .info-symbol {
      font-size: 0.9em;
    }
  }

  .search-icon {
    width: 21px;
    height: 23px;
    fill: #ffffff;
  }

  /deep/ .results {
    margin: -14px;
  }

  .load-more {
    margin-top: 7px;
  }

  .load-more-button {
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, .5);
    }
  }

  .delete-button {
    position: relative;
    margin: 4px 5px 0px -29px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  .search-note {
    font-style: italic;
    margin: 0px 0px 0px 3px;
  }

  .input-group {
    .input-group-prepend {
      .custom-select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input-parameters-toggle {
    display: flex;
    align-items: center;
  }

  .input-parameters-form {
    text-align: left;
    margin: 0 0 20px 0;

    .summary {
      padding: 0 0 15px 0;
    }

    .conditions {
      .field {
        padding: 10px 15px;
        margin: 5px 0;
        align-items: center;
        border-top: 1px dashed #d3d3d3;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .submit {
      display: flex;
      justify-content: space-between;
      justify-items: flex-end;
      align-items: center;
      margin-top: 15px;

      .spacer {
        flex-grow: 1;
      }
    }
  }

  .entity-count-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;

    .entity-count-button-spacing {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  .display-result-count-badge {
    margin-top: 15px
  }

  .query-name {
    font-size: 1.5rem;
  }
</style>
