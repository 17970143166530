<template>
  <b-modal v-model="showReportGenerationModal" @hide="handleHide" scrollable :title="modalTitle">
    <table class="fields" aria-describedby="generate-report-modal">
      <tr v-for="field in fieldsForReportGeneration" :key="field.name" :id="field.name" class="field">
        <th scope="col">
          <b-form-checkbox :value="field" v-model="selectedReportFields" switch size="lg" class="label"></b-form-checkbox>
        </th>
        <td class="value">{{field.label}}</td>
      </tr>
    </table>
     <template v-slot:modal-footer class="float-left">
         <b-button size="sm" variant="primary" @click="downloadPdf(true)">PDF - Visualisation</b-button>
         <b-button size="sm" variant="primary" @click="downloadPdf(false)">PDF - No visualisation</b-button>
         <b-button size="sm" variant="primary" @click="downloadCsv()">CSV</b-button>
     </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import PDFIcon from '@/assets/file-pdf.svg'
import CSVIcon from '@/assets/file-csv-solid.svg'
import DownloadReportMixin from '@/modules/insight/mixins/DownloadReportMixin'

export default {
  name: 'GenerateReportModal',
  mixins: [DownloadReportMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PDFIcon, CSVIcon
  },
  props: {
    fields: {
      type: Array
    },
    modelShowReportGeneration: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    modalTitle: Vue.prototype.$INSIGHT('MISC').COMMON_PHRASE.EXPORT_REPORT,
    showReportGenerationModal: false,
    selectedReportFields: [],
    json_data: [],
    downloadCSV: false,
    csvFileName: ''
  }),
  computed: {
    fieldsForReportGeneration () {
      return this.fields
    }
  },
  methods: {
    handleHide (bvModalEvt) {
      // Trigger submit handler
      this.$emit('hide', true)
    },
    downloadPdf (withChart = true) {
      this.$emit('clickedPDF', withChart, this.selectedReportFields)
    },
    downloadCsv () {
      this.$emit('clickedCSV', this.selectedReportFields)
    }
  },
  watch: {
    modelShowReportGeneration (value) {
      this.showReportGenerationModal = value
      // To reset push item into this.selectedReportFields or reset base on value if true or not
      if (value) {
        this.fieldsForReportGeneration.forEach(item => this.selectedReportFields.push(item))
      } else {
        this.selectedReportFields = []
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.fields {
  margin: -0 0;
  width: 100%;

  .field {
    display: flex;
    border-bottom: 1px solid #ececec;
    padding: 5px 15px;

    &:hover {
      background: #ececec;
    }

    .label {
      font-weight: 400;
      flex-basis: 40%;
      padding-right: 8px;
    }

    .value {
      padding-left: 8px;
      font-weight: 300;
      flex-basis: 60%;

      /deep/ a {
        color: #0056b3;
      }
    }
  }
}

.image-preview {
  cursor: pointer;
  height: 128px;
}
</style>
