<template>
  <div :class="{ 'cursor-pointer' : dfList }">
    <span class="d-flex flex-row justify-content-center">
      <b-spinner :style="spinnerStyle" type="grow" v-if="formLoading" />
    </span>
    <b-link v-if="!formLoading" class="d-flex justify-content-between custom-link"  @click="toggleShow">
      <span id="entity-table">{{viewName}}</span>
      <AngleUp v-if="dfList && !disableFieldListChoices" class="la-arrow" :class="arrowAnimationSettings"/>
    </b-link>

    <transition name="slide-fade">
      <div v-if="show">
        <div class="mt-3 mb-2 selectViewTextWrapper">
          <span>{{selectViewText}}</span>
        </div>
        <b-list-group class="custom-list">
          <b-list-group-item
            @click="loadForm(formId, item)"
            href="#"
            v-for="(item, formId) of dfList"
            :key="formId"
            variant="light"
          >
            {{item.name}}
          </b-list-group-item>
          <b-list-group-item v-if="canChooseGenericForm" variant="light" @click="loadGenericForm()">
            {{genericViewOption.genericView.name}}
          </b-list-group-item>
        </b-list-group>
      </div>
    </transition>

  </div>
</template>

<script>
import Vue from 'vue'
import AngleUp from '@/assets/angle-up-solid.svg'

export default {
  name: 'field-list-choices',
  components: {
    AngleUp
  },
  props: {
    initialFormName: {
      default: false
    },
    dynamicFormList: {
      default: false
    },
    mode: {
      default: 'viewModel'
    },
    type: {
      default: 'entity'
    },
    showSegregatedEntitiesHeader: {
      default: false
    },
    childNodeFieldsHeader: {
      default: false
    },
    childNodeDFList: {
      default: false
    },
    canChooseGenericForm: {
      default: true
    },
    formLoading: {
      default: false
    },
    disableFieldListChoices: {
      default: false
    }
  },
  data: () => ({
    currentView: false,
    show: false,
    showHeaderForSegregatedEntities: false,
    compoundEntitiesHeader: '',
    genericViewOption: { genericView: { name: Vue.prototype.$FORM('TEXT').CREATE_EDIT_MODEL.GENERIC_VIEW_NAME } },
    spinnerSize: Vue.prototype.$FORM('TEXT').CREATE_EDIT_MODEL.SPINNER_LOADING_ICON_SIZE
  }),
  computed: {
    viewName () {
      const type = this.mode === this.$FORM('MODE').VIEW_MODEL ? 'VIEW_MODEL' : 'EDIT_MODEL'

      if (this.childNodeFieldsHeader) {
        return this.childNodeFieldsHeader
      }
      if (this.initialFormName && !this.currentView) {
        return this.initialFormName
      }
      if ((!this.currentView && !this.showSegregatedEntitiesHeader) || (this.currentView === `${this.$FORM('TEXT')[type].GENERIC_VIEW}`)) {
        return `${this.$FORM('TEXT')[type].GENERIC_VIEW}`
      }
      if (this.showSegregatedEntitiesHeader) {
        return `${this.$INSIGHT('COMPOUND_ENTITIES').RIGHT_PANEL_HEADER}`
      }
      return `Form | ${this.currentView}`
    },
    arrowAnimationSettings () {
      const arrowAnimationSettings = { 'arrow-up': this.show, 'arrow-down': !this.show }
      return arrowAnimationSettings
    },
    selectViewText () {
      if (this.mode === this.$FORM('MODE').VIEW_MODEL) {
        return this.$FORM('TEXT').VIEW_MODEL.SELECT_VIEW
      } else {
        return this.$FORM('TEXT').EDIT_MODEL.SELECT_VIEW
      }
    },
    spinnerStyle () {
      const spinnerSize = this.spinnerSize
      const sizeStyle = `width: ${spinnerSize}; height: ${spinnerSize};`
      const spinnerStyle = `${sizeStyle}` // Just add more style if needed
      return spinnerStyle
    },
    /*
      So the dynamic form list now will contain the "Main list" and "child node list"
      This computed will change the data/content if childNodeDFList is not null/false
      to list the child's node record dynamic form list
    */
    dfList () {
      if (this.childNodeDFList) {
        return this.childNodeDFList
      } else {
        return this.dynamicFormList
      }
    }
  },
  methods: {
    loadForm (formId, form) {
      if (form.name === this.currentView) {
        this.setCurrentView(form.name)
      } else if (formId === 'genericView') {
        this.$emit('loadGenericForm', true)
      } else {
        this.$emit('fetchDynamicForm', formId)
      }
      this.setCurrentView(form.name)
      this.$emit('switchOffSegregatedEntitiesView', false)
      this.toggleShow()
    },
    loadGenericForm () {
      const genericFormId = Object.keys(this.genericViewOption)[0]
      this.loadForm(genericFormId, this.genericViewOption.genericView)
    },
    toggleShow () {
      if (this.dfList && !this.disableFieldListChoices) {
        this.show = !this.show
        return true
      } else {
        return false
      }
    },
    setCurrentView (name) {
      if (!this.childNodeFieldsHeader) {
        this.currentView = name
      }
    }
  }
}
</script>
<style lang="scss">

.la-arrow {
  height: 20px;
  cursor: pointer;
  fill: white;
  margin-top: -2px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.arrow-up {
  transition: all .3s ease;
  transform: rotate(0deg);
}

.arrow-down {
  transition: all .3s ease;
  transform: rotate(-180deg);
}

.custom-list {
  font-size: 15px;
  max-height: 120px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // tablet scroll behaviour - content continues to scroll for a while after finishing the scroll gesture
}

.custom-link {
  color: white;
}

.custom-link:hover > .la-arrow {
  fill: #F08521;
}

.selectViewTextWrapper {
  cursor:default;
  font-size: 15px;
}
</style>
