<template>
  <b-modal
    :aria-label="modalTitle"
    v-model="showModal"
    :title="modalTitle"
    @ok="handleOk"
    @hide="handleHide"
    okVariant="success"
    okTitle="Yes"
    cancelTitle="No"
  >
    <div class="alert alert-warning alert-container" v-if="hasLinkedCharts">
      <strong>{{$INSIGHT('QUERY').MODAL.DELETE.QUERY_USED_BY_CHART}}</strong>
      <ul>
        <li v-for="chart in linkedCharts" :key="chart.chart_id">
          {{ chart.title }}
        </li>
      </ul>
      <b-form-group class="form-group" :label="$INSIGHT('QUERY').MODAL.DELETE.ASK_DELETE_LINKEDCHART">
        <b-form-radio v-model="cascadeOptionSelected" name="cascadeOptionRadios" class="cascadeOptionRadios" value="cascadeTrue">{{$LOCAL('COMMON_WORD').YES}}</b-form-radio>
        <b-form-radio v-model="cascadeOptionSelected" name="cascadeOptionRadios" class="cascadeOptionRadios" value="cascadeFalse">{{$LOCAL('COMMON_WORD').NO}}</b-form-radio>
      </b-form-group>
    </div>
    <p class="my-4" :style="paddingStyle" v-encode-styled-html="confirmingText"/>
  </b-modal>
</template>

<script>
// This component is used specifically for the deletion of QUERIES. For a more generic
// delete modal, please use the DeleteModal.vue component that can be found in the same directory
// as this file instead.
export default {
  name: 'QueryDeleteModal',
  props: {
    queryName: {
      type: String,
      required: true
    },
    linkedCharts: {
      // TODO: Find what caused "Generate.spec.js" test will return getLinkedChartsData a function instead of Array
      // type: Array,
      required: true
    },
    modalShow: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    cascadeOptionSelected: 'cascadeFalse'
  }),
  computed: {
    hasLinkedCharts () {
      return this.linkedCharts.length > 0
    },
    paddingStyle () {
      return this.hasLinkedCharts ? { padding: '16px 1.25rem 0px' } : {}
    },
    confirmingText () {
      if (this.hasLinkedCharts) {
        return this.cascadeOptionSelected === 'cascadeTrue'
          ? `<strong>${this.$INSIGHT('QUERY').MODAL.DELETE.ASK_DELETE_QUERY_AND_LINKEDCHART}</strong>`
          : `<strong>${this.$INSIGHT('QUERY').MODAL.DELETE.ASK_DELETE_QUERY_ONLY}</strong>`
      } else {
        return this.$INSIGHT('QUERY').MODAL.DELETE.ASK_DELETE_QUERY
      }
    },
    modalTitle () {
      return `${this.$LOCAL('COMMON_WORD').DELETE} ${this.queryName}`
    }
  },
  methods: {
    reset () {
      this.showModal = false
      this.cascadeOptionSelected = 'cascadeFalse'
    },
    handleOk (bvModalEvt) {
      // Trigger submit handler
      this.$emit('ok', this.cascadeOptionSelected)
      this.reset()
    },
    handleHide (bvModalEvt) {
      // Trigger submit handler
      this.$emit('hide')
      this.reset()
    }
  },
  watch: {
    modalShow (value) {
      this.showModal = value
    }
  }
}
</script>

<style scoped>
.alert-container {
  margin-bottom: 0px;
}
.cascadeOptionRadios {
  padding-left: 40px;
  font-weight: normal;
}
.form-group {
  font-weight: bold;
}
</style>
