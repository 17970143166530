<template>
  <b-modal centered hide-footer :title="getTitle" v-model="showModalToggle" @hide="handleHide">
      <b-button v-for="menuItem in contextMenuItems" :key="menuItem.id"  @click="emitMenuItemOnClick(menuItem)"
                class="mt-3" variant="outline-info" block>
        {{ menuItem.content }}
      </b-button>
  </b-modal>
</template>

<script>

export default {
  /*
    The cytoscape-context-menu-modal is used as a backup in event the browser/device of user
    does not support cytoscape-context-menus library.
    Known browsers/devices that will need to use this backup: iOS iPad Safari/Chrome, iOS Macbook Safari
  */
  name: 'CytoscapeContextMenuModal',
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    contextMenuItems: {
      // List of menu items
      type: Array
    },
    recordLabel: {
      type: String
    }
  },
  computed: {
    getTitle () {
      return this.$INSIGHT('VISUALISATION').MODAL.TITLE + String(this.recordLabel)
    }
  },
  data: () => ({
    showModalToggle: false
  }),
  methods: {
    handleHide () {
      this.$emit('hide')
    },
    emitMenuItemOnClick (menuItem) {
      this.$emit('menu-item-on-click', menuItem)
    }
  },
  watch: {
    showModal (value) {
      this.showModalToggle = value
    }
  }
}
</script>
