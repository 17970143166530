<template>
  <b-modal v-model="displayLinkListModal" @hide="handleHide" scrollable hide-footer>
    <div class="container fields">
      <div class="row">
        <div class="col field">
            <span class="label-for-aggregate">
              <entity-icon :model="sourceEntityType" :title="entityTypeLabel" :key="sourceEntityType"></entity-icon>
              {{sourceTypeDisplayLabel}}
            </span>
            <svg class="header-link-svg">
                <text x="50%" y="33%" text-anchor="middle" font-size="large" font-weight="bold">{{display_link_label_plural}}</text>
                <line class="link-line" x1="0" y1="50%" x2="100%" y2="50%"/>
            </svg>
            <span class="label-for-aggregate">
              <entity-icon :model="target_entity_type" :title="target_entity_type" :key="target_entity_id"></entity-icon>
              {{targetDisplayLabel}}
            </span>
        </div>
      </div>
      <div class="row field" v-for="link in linkListOnDisplay" :key="link.id" :id="link.id">
        <div class="offset-1 col-9 text-center">
          <span>
            <LaTriangle v-bind:style="toggleLeftTriangle(link)" class="la triangle-left" />
            <svg class="list-link-svg">
              <line v-bind:style="getLineStyle(link)" class="link-line" x1="0" y1="0" x2="100%" y2="0"/>
            </svg>
            <span class="value-link-label">{{link.label}}</span>
            <svg class="list-link-svg">
              <line v-bind:style="getLineStyle(link)" class="link-line" x1="0" y1="0" x2="100%" y2="0"/>
            </svg>
            <LaTriangle v-bind:style="toggleRightTriangle(link)" class="la triangle-right" />
          </span>
        </div>
        <div class="col-2">
          <b-button v-if="hasReadPermission" variant="primary" size="sm"
            :to="{name:'link', params: {id: link.id, model: link.type}}" class="view-entity-button-col">
              <LaEye class="view-link-icon"/>
          </b-button>
          <b-button v-else disabled size="sm">
              <span :id="`${link.id}-model-span`" tabindex="0"><LaEye class="la"/></span>
              <b-tooltip v-if="!hasReadPermission" :target="`${link.id}-model-span`" :title="$INSIGHT('ERROR').PERMISSION_WARNING.CANNOT_VIEW"/>
          </b-button>
        </div>
      </div>
      <div class="col-12 text-center" v-if="displayLoadMore && !loading">
          <b-button  variant="outline" @click="triggerLoadMoreLinks()">
              <b-badge pill variant="secondary">{{$LOCAL('LoadMore')}}</b-badge>
          </b-button>
      </div>
      <div class="col-12 text-center" v-if="loading">
        <b-spinner small  label="Loading"/>
      </div>
    </div>
  </b-modal>
</template>

<script>
import LaEye from '@/assets/la-eye.svg'
import LaTriangle from '@/assets/la-triangle-left-solid.svg'
import EntityIcon from '@/modules/insight/components/EntityIcon'

export default {
  name: 'LinkListModal',
  components: {
    LaTriangle, LaEye, EntityIcon
  },
  props: {
    linkList: {
      type: Array
    },
    showLinkListModal: {
      type: Boolean,
      required: true
    },
    hasMoreLinkList: {
      type: String,
      required: true
    },
    linkTypeDescription: {
      type: String
    },
    linkCount: {
      type: Number
    },
    hasReadLinkPermission: {
      type: Boolean
    },
    sourceEntityType: {
      type: [Boolean, String],
      default: function () {
        return false
      }
    },
    entityTypeLabel: {
      type: String
    },
    sourceTypeDisplayLabel: {
      type: String,
      required: true
    },
    targetEntityType: {
      type: String
    },
    targetEntityId: {
      type: String
    },
    targetDisplayLabel: {
      type: String
    }
  },
  data: () => ({
    linkListOnDisplay: [],
    modalTitle: '',
    displayLinkListModal: false,
    pageNo: 1,
    loading: false
  }),
  computed: {
    displayLoadMore () {
      if (this.hasMoreLinkList !== 'null') {
        return true
      } else {
        return false
      }
    },
    hasReadPermission () {
      return this.hasReadLinkPermission
    },
    display_link_label_plural () {
      return this.linkCount + ' ' + this.linkTypeDescription + 's'
    },
    target_entity_type () {
      return this.targetEntityType
    },
    target_entity_id () {
      return this.targetEntityId
    }
  },
  mounted () {
  },
  methods: {
    handleHide () {
      this.pageNo = 1
      this.linkListOnDisplay = []
      this.$emit('hideLinkListModal', true)
    },
    triggerLoadMoreLinks () {
      this.pageNo += 1
      this.$emit('triggerLoadMoreLinks', this.pageNo)
      // this.displayLoadMore = false
      this.loading = true
    },
    getLineStyle (linkendData) {
      let dasharray
      switch (linkendData[this.$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE]) {
        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.CONFIRMED:
          dasharray = '0%'
          break

        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.UNCONFIRMED:
          dasharray = '10%'
          break

        case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.TENTATIVE:
          dasharray = '3%'
          break

        default:
          dasharray = '0%'
          break
      }
      return { 'stroke-dasharray': dasharray, stroke: linkendData.colour }
    },
    toggleLeftTriangle (linkEndData) {
      if (linkEndData.direction === 2 || linkEndData.direction === 3) {
        return { fill: 'black', 'fill-opacity': 1 }
      }
    },
    toggleRightTriangle (linkEndData) {
      if (linkEndData.direction === 1 || linkEndData.direction === 3) {
        return { fill: 'black', 'fill-opacity': 1 }
      }
    }
  },
  watch: {
    showLinkListModal (value) {
      this.displayLinkListModal = value
    },
    linkList (value) {
      this.linkListOnDisplay = [...this.linkListOnDisplay, ...value]
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.fields {
  margin: -0 0;
  width: 100%;

  .field {
    display: flex;
    border-bottom: 1px solid #ececec;
    padding: 5px 15px;

    &:hover {
      background: #ececec;
    }

    .label {
      font-weight: 400;
      flex-basis: 40%;
      padding-right: 8px;
    }

    .value {
      padding-left: 8px;
      font-weight: 300;
      flex-basis: 60%;

      /deep/ a {
        color: #0056b3;
      }
    }

    .view-entity-button-col {
      float:right
    }
  }

  .center-element {
    display: flex;
    justify-content: center;
    padding-top:7px
  }
}

.line-confidence {
  height: 35px;
  float: right;
  margin-bottom: -5px;
  padding-right: 5em;
}

.view-link-icon {
  width: 20px;
  height: 20px;
}

.modal-header-icon {
  width: 30px;
  height: 30px;
}

.modal-backdrop {
  z-index: -1;
}

.tooltip { top: 0; }

.list-link-svg {
  height: 10px;
  width: 40px;
  vertical-align: baseline;
  padding-top: 5px
}

.link-line {
  stroke:black;
  stroke-width: 3;
  stroke-dasharray: 0%;
}

.triangle-right {
  transform: rotate(180deg);
  fill:white;
  fill-opacity: 0;
  padding-bottom:2px
}

.triangle-left {
  fill:white;
  fill-opacity: 0;
  padding-top:2px
}

.label-for-aggregate {
  font-size: small;
  font-weight: 400;
  flex-basis: 40%;
  display: inline-block;
  text-align: center;
}

.header-link-svg {
  height: 60px;
  width: 100%;
  display: block;
}

.value-link-label {
  padding-top: 9px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 300;
  flex-basis: 60%;

  /deep/ a {
    color: #0056b3;
  }
}
</style>
